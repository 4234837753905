import { ArrowBack } from '@mui/icons-material';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from '../assets/utils/colors';

const InnerPageTopbar = ({ title }) => {
    const navigate = useNavigate();
    return (
        <>
            <Grid container sx={{ position: 'sticky', top: 56, zIndex: 999 }}>
                <Grid item xs={12} sm={12}>
                    <Box
                        sx={{
                            height: 100,
                            display: 'flex',
                            alignItems: { md: 'flex-start', xs: 'flex-start' },
                            justifyContent: 'space-between',
                            background: {
                                md: 'none',
                                xs: colors.bgColor2,
                                // xs: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                            },
                        }}
                    >
                        <Typography
                            className="head-font section-head"
                            sx={{
                                'fontSize': 16,
                                'fontWeight': 'bold',
                                'color': colors.primary,
                                'position': 'relative',
                                '&::before': {
                                    content: "''",
                                    height: 3,
                                    width: 100,
                                    background: colors.bgColor1,
                                    position: 'absolute',
                                    bottom: -8,
                                    borderRadius: 5,
                                },
                            }}
                        >
                            {title}
                        </Typography>
                        <Tooltip title="Go back">
                            <IconButton
                                sx={{
                                    p: 0.8,
                                    bgcolor: colors.bgColor1,
                                    color: colors.primary,
                                    ml: 1,
                                }}
                                size="small"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <ArrowBack
                                    sx={{
                                        fontSize: 16,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default InnerPageTopbar;
