import React from 'react';
import { CircularProgress, IconButton, Switch, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import {
    useActivateBrandMutation,
    useDeactivateBrandMutation,
} from '../../api/brands/brandAPI';
import { setBrand } from '../../features/brandSlice';

const BrandActivateDeactivate = ({ brand, refetchBrandDetails }) => {
    // ############### CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK ###############
    const [
        activateBrand,
        {
            isLoading: activateLoading,
            isSuccess: activateSuccess,
            isError: activateIsError,
            error: activateError,
            data: activateData,
        },
    ] = useActivateBrandMutation();
    const [
        deactivateBrand,
        {
            isLoading: deactivateLoading,
            isSuccess: deactivateSuccess,
            isError: deactivateIsError,
            error: deactivateError,
            data: deactivateData,
        },
    ] = useDeactivateBrandMutation();
    // ############## Resp ###############
    const rtkActions = (data) => {
        refetchBrandDetails();
        dispatch(setBrand(data));
    };
    RTK.useRTKResponse(
        activateSuccess,
        activateIsError,
        activateError,
        activateData,
        rtkActions,
    );
    RTK.useRTKResponse(
        deactivateSuccess,
        deactivateIsError,
        deactivateError,
        deactivateData,
        rtkActions,
    );
    return (
        <>
            <Tooltip title={brand.brand_status ? 'Deactivate' : 'Activate'}>
                <IconButton
                    size="small"
                    onClick={() => {
                        brand.brand_status === 1
                            ? deactivateBrand(brand.id)
                            : activateBrand(brand.id);
                    }}
                >
                    {activateLoading || deactivateLoading ? (
                        <CircularProgress size={21} sx={{ mx: 1 }} />
                    ) : (
                        <Switch
                            size="small"
                            checked={brand.brand_status === 1 ? true : false}
                        />
                    )}
                </IconButton>
            </Tooltip>
        </>
    );
};

export default BrandActivateDeactivate;
