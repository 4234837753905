import React from 'react';
import { Add } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import CustomCard2 from '../cards/CustomCard2';
import CustomDialogy from '../dialogys/CustomDialogy';
import CreatePromotion from '../../pages/promotions/CreatePromotion';
import { useSelector } from 'react-redux';
import { filter, size } from 'lodash';

// !############### MAIN FUNC ####################
const PromotionsSidebar = ({ promotionsRefetch }) => {
    const [openDialogy, setOpenDialogy] = React.useState(false);
    const promotions = useSelector((state) => state.promotions.promotions);

    const handleOpenDialogy = () => {
        setOpenDialogy(true);
    };
    const handleCloseDialogy = () => {
        setOpenDialogy(false);
    };

    return (
        <>
            {/* ############### Dialogy ################# */}
            <CustomDialogy
                title={'Add new promotion'}
                open={openDialogy}
                handleCloseDialogy={handleCloseDialogy}
            >
                <CreatePromotion
                    closeDialogy={handleCloseDialogy}
                    promotionsRefetch={promotionsRefetch}
                />
            </CustomDialogy>

            {/* ################ Main Content ################ */}
            <CustomCard2 title={'Promotions actions'} height={640}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item sm={12}>
                        <Box
                            sx={{
                                height: 80,
                                px: 1.5,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: `2px 2px 4px 4px ${colors.bgColor3}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                            }}
                        >
                            <Button
                                className="app-btn"
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{
                                    boxShadow: 0,
                                    height: 55,
                                    width: 250,
                                    fontWeight: 'bold',
                                }}
                                startIcon={<Add />}
                                onClick={() => handleOpenDialogy()}
                            >
                                Add new promotion
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
                >
                    <Grid container justifyContent={'center'}>
                        <Grid item sm={12}>
                            <Box
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    mt: 5,
                                    p: 2,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                    width: '100%',
                                }}
                            >
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography
                                        sx={{
                                            fontSize: 22,
                                            color: colors.primary,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {size(promotions)}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            color: colors.primary,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Total promotions
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mt: 4,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            borderLeft: '4px solid green',
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                            p: 1,
                                            borderRadius: 2,
                                            mr: 1,
                                            width: '100%',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 18,
                                                color: colors.primary,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {size(
                                                filter(
                                                    promotions,
                                                    ({ status }) =>
                                                        status === 1,
                                                ),
                                            )}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                color: colors.primary,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Active promotions
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            borderLeft: '4px solid red',
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                            p: 1,
                                            borderRadius: 2,
                                            ml: 1,
                                            width: '100%',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 18,
                                                color: colors.primary,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {size(
                                                filter(
                                                    promotions,
                                                    ({ status }) =>
                                                        status === 0,
                                                ),
                                            )}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                color: colors.primary,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Inactive promotions
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </CustomCard2>
        </>
    );
};

export default PromotionsSidebar;
