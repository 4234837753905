import React from 'react';
import { LocationOn, Male, Message, Phone } from '@mui/icons-material';
import { Avatar, Box, Chip, Grid, Switch, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import CustomCard2 from '../cards/CustomCard2';
import user from '../../assets/media/images/user3.png';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { usersSelector } from '../../features/selectors';

// !############### MAIN FUNC ####################
const CustomerDetailsSidebar = () => {
    const { customer } = useSelector(usersSelector);
    return (
        <>
            {/* ################ Main Content ################ */}
            <CustomCard2
                title={'Customers details'}
                height={640}
                // action={
                //     <Box>
                //         {customer?.isActive ? (
                //             <Chip
                //                 label={'Active'}
                //                 size="small"
                //                 color="success"
                //             />
                //         ) : (
                //             <Chip
                //                 label={'Inactive'}
                //                 size="small"
                //                 color="error"
                //             />
                //         )}
                //         {customer?.isActive ? (
                //             <Switch defaultChecked size="small" />
                //         ) : (
                //             <Switch defaultChecked={false} size="small" />
                //         )}
                //     </Box>
                // }
            >
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                        p: 2,
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Avatar
                        src={user}
                        variant="square"
                        sx={{
                            height: 250,
                            width: '90%',
                            borderRadius: 5,
                            img: {
                                objectFit: 'contain',
                            },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                        textAlign: 'center',
                        py: 1,
                        zIndex: 12,
                        position: 'relative',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 15,
                            fontWeight: 'bold',
                            color: colors.primary,
                        }}
                    >
                        {`${customer?.first_name} ${customer?.last_name}`}
                    </Typography>
                </Box>
                <Box>
                    <Grid container padding={2} spacing={2}>
                        <Grid item sm={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Box sx={{ p: 1 }}>
                                    <Male
                                        sx={{
                                            color: colors.primary,
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Gender
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                        }}
                                    >
                                        {customer?.gender}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Box sx={{ p: 1 }}>
                                    <LocationOn
                                        sx={{
                                            color: colors.primary,
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Location
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                        }}
                                    >
                                        {customer?.location
                                            ? customer?.location
                                            : 'unavailable'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Box sx={{ p: 1 }}>
                                    <Phone
                                        sx={{
                                            color: colors.primary,
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Phone
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                        }}
                                    >
                                        +255
                                        {customer?.phone_number
                                            ? customer?.phone_number.substring(
                                                  1,
                                              )
                                            : customer?.phone_number}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Box sx={{ p: 1 }}>
                                    <Message
                                        sx={{
                                            color: colors.primary,
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Email
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                        }}
                                    >
                                        {customer?.email
                                            ? customer?.email
                                            : 'unavailable'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: colors.primary,
                            }}
                        >
                            Member since
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                color: colors.primary,
                            }}
                        >
                            {moment(customer?.created_at).format('ll')}
                        </Typography>
                    </Box>
                </Box>
            </CustomCard2>
        </>
    );
};

export default CustomerDetailsSidebar;
