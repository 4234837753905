import React from 'react';
import { Box, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ErrorMessage, useField } from 'formik';
import { colors } from '../../assets/utils/colors';

const CustomInput = styled(TextField)({});

const CustomTextField = ({ label, type, value, color, shrink, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            <Box sx={{ marginTop: 2 }}>
                <CustomInput
                    error={meta.touched && meta.error ? true : false}
                    label={label}
                    variant="outlined"
                    fullWidth
                    type={type}
                    placeholder={label}
                    {...field}
                    {...props}
                    size="small"
                    color={color ? color : 'secondary'}
                    InputLabelProps={{
                        shrink: shrink && shrink,
                    }}
                    sx={{
                        '& input': {
                            color: color ? color : colors.white,
                        },
                        '& label': {
                            color: color ? color : colors.white,
                        },
                    }}
                />
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomTextField;
