import { Grid } from '@mui/material';
import { isEmpty, map, size } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomCard2 from '../../components/cards/CustomCard2';
import BrandsSidebar from '../../components/sidebars/BrandsSidebar';
import BrandWidget2 from '../../components/widgets/BrandWidget2';
import PageLayout from '../layouts/PageLayout';
import CountBadge from '../../components/CountBadge';
import NoDataFeedback from '../../components/feedbacks/NoDataFeedback';
import DataFetchingFeedback from '../../components/feedbacks/DataFetchingFeedback';
import { useGetBrandsQuery } from '../../api/dashboard/dashboardAPI';
import { setBrands } from '../../features/brandSlice';
import { getRTKResponseData } from '../../helpers/RTKHelpers';

// !############### MAIN FUNC ####################
const Brands = () => {
    // ################# CONST ##################
    const dispatch = useDispatch();

    // ############## Redux ###################
    const brands = useSelector((state) => state.brands.brands);

    // ############## RTK ####################
    const {
        isLoading: brandsLoading,
        isSuccess: brandSuccess,
        data: brandsData,
        refetch: brandRefetch,
    } = useGetBrandsQuery();

    // ############## useEffect ###################
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            brandRefetch();
            if (brandSuccess) {
                dispatch(setBrands(getRTKResponseData(brandsData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [brandSuccess, brandsData, brandRefetch, dispatch]);

    return (
        <>
            <PageLayout
                sidebar={
                    <BrandsSidebar
                        brands={brands}
                        brandRefetch={brandRefetch}
                    />
                }
                head="Brands managements"
            >
                <CustomCard2
                    title={`CAICA Brands`}
                    heightClear={640}
                    action={<CountBadge total={size(brands)} item={'Brands'} />}
                >
                    {brandsLoading ? (
                        <>
                            <DataFetchingFeedback />
                        </>
                    ) : isEmpty(brands) ? (
                        <>
                            <NoDataFeedback message={'No brand added'} />
                        </>
                    ) : (
                        <>
                            <Grid container padding={2} spacing={3}>
                                {map(brands, (brand) => (
                                    <Grid item sm={4} key={brand.id}>
                                        <BrandWidget2 brand={brand} />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default Brands;
