import React from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import { ScreenLockPortrait } from '@mui/icons-material';
import logo from '../../assets/media/images/logo.png';

const MobDetect = () => {
    return (
        <>
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                }}
            >
                <Grid container justifyContent={'center'}>
                    <Grid item sm={8} xs={10}>
                        <Box
                            sx={{
                                height: 300,
                                // background: `linear-gradient(180deg, ${colors.secondary}, ${colors.bgColor2})`,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                borderRadius: 5,
                                py: 5,
                                boxShadow: `1px 1px 20px 0px ${colors.secondary}`,
                            }}
                        >
                            <Box>
                                <ScreenLockPortrait
                                    color="error"
                                    sx={{
                                        fontSize: 70,
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                    }}
                                />
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        color: colors.white,
                                        textAlign: 'center',
                                    }}
                                >
                                    Opps! Mobile device not allowed!
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        color: colors.warning,
                                        fontSize: 15,
                                        textAlign: 'center',
                                        p: 3,
                                    }}
                                >
                                    Sorry! It seems like your using mobile phone
                                    to try to access dashboard. We advice you to
                                    use Computer or Laptop for convinient
                                    management.
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    'width': '100%',
                                    'display': 'flex',
                                    'justifyContent': 'center',
                                    '& .logo': {
                                        width: 160,
                                        height: 66,
                                    },
                                }}
                            >
                                <Avatar
                                    src={logo}
                                    alt="Agiza logo"
                                    className="logo"
                                    sx={{
                                        img: {
                                            height: 150,
                                            width: 150,
                                            objectFit: 'contain',
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default MobDetect;
