import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import CustomTextField from '../../components/forms/CustomTextField';
import { colors } from '../../assets/utils/colors';

// RTK query and redux imports
import { useAddProductOnSellMutation } from '../../api/products/productAPI';
import { errorNotify, successNotify } from '../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';
import CustomSubmitButton from '../../components/forms/CustomSubmitButton';

// ################### validation schema ###############
const addProductOnSellSchema = Yup.object({
    discounted_percent: Yup.number('Discount percent must be a number')
        .max(99, 'Discounted percent must be at most 2 characters')
        .min(0),
});

const AddProductOnSell = ({ product_id, refetch, handleCloseDialogy }) => {
    // ################# RTK #################
    const [
        addOnSell,
        { isSuccess, isError, error, isLoading, data },
    ] = useAddProductOnSellMutation();

    // ################# useEffect #################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                refetch();
                handleCloseDialogy();
                successNotify(getRTKSuccessMessage(data));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
    }, [isError, isSuccess, error, data]);

    return (
        <>
            <Formik
                initialValues={{
                    discounted_percent: '',
                }}
                validationSchema={addProductOnSellSchema}
                onSubmit={async (values) => {
                    const payload = {
                        ...values,
                        product_id,
                    };
                    await addOnSell(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item sm={12}>
                                <Typography
                                    sx={{ fontSize: 13, color: colors.warning }}
                                >
                                    Note : Discount percent is optional
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <CustomTextField
                                    type={'number'}
                                    name="discounted_percent"
                                    label={'Discounted percent %'}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        btnColor="warning"
                                        onClick={formik.handleSubmit}
                                    >
                                        Add product on-sell
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddProductOnSell;
