import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomTextField from '../../components/forms/CustomTextField';

// RTK query and redux imports
import { useAddProductSizeMutation } from '../../api/products/productAPI';
import { errorNotify, successNotify } from '../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';
import CustomSubmitButton from '../../components/forms/CustomSubmitButton';

// ############## VALIDATION ##################
const addSizeValidationSchema = Yup.object({
    size_name: Yup.string().required('Size name is required').max(20),
    size_price: Yup.number('Size price must be a number type'),
});

const AddProductSize = ({ product_id, refetch, handleCloseDialogy }) => {
    // ############## RTK ##################
    const [
        addProductSize,
        { isLoading, isError, isSuccess, error, data },
    ] = useAddProductSizeMutation();

    // ############# useEffect ###############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                refetch();
                handleCloseDialogy();
                successNotify(getRTKSuccessMessage(data));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
    }, [isError, isSuccess, error]);

    return (
        <>
            <Formik
                initialValues={{
                    size_name: '',
                    size_price: '',
                }}
                validationSchema={addSizeValidationSchema}
                onSubmit={(values) => {
                    const payload = {
                        ...values,
                        product_id,
                    };
                    addProductSize(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item sm={12}>
                                <CustomTextField
                                    name="size_name"
                                    label={'Size name'}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomTextField
                                    name="size_price"
                                    label={'Size price'}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        onClick={formik.handleSubmit}
                                    >
                                        Add size
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddProductSize;
