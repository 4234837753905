import { RemoveRedEye } from '@mui/icons-material';
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/colors';
import { size } from 'lodash';

const PromotionWidget3 = ({ promotion }) => {
    return (
        <>
            <Box
                sx={{
                    borderRadius: 3,
                    overflow: 'hidden',
                    border: `1px solid ${colors.bgColor1}`,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                }}
            >
                <Box>
                    <img
                        src={promotion.promotion_image}
                        alt=""
                        style={{
                            width: '100%',
                            height: 95,
                            objectFit: 'contain',
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                        p: 1,
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            color: colors.primary,
                            fontSize: 13,
                        }}
                    >
                        {promotion.promotion_name}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 0.5,
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                            }}
                        >
                            Products
                        </Typography>
                        <Typography sx={{ fontSize: 13 }}>
                            {size(promotion.promotion_products)}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            borderLeft: '1px solid green',
                            borderRight: '1px solid green',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {promotion.status ? (
                            <Chip size="small" color="success" label="active" />
                        ) : (
                            <Chip size="small" color="error" label="inactive" />
                        )}
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        <Tooltip title="Promotion details">
                            <Link to={`/promotions/details/${promotion.id}`}>
                                <IconButton color="info">
                                    <RemoveRedEye />
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default PromotionWidget3;
