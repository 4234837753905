import {
    Box,
    Button,
    CircularProgress,
    Tooltip,
    Typography,
} from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';
import { Add, Remove } from '@mui/icons-material';
import { errorNotify, successNotify } from '../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';
import { useRemoveProductPromotionMutation } from '../../api/products/productAPI';
import { useEffect } from 'react';
import { isEmpty } from 'lodash';

const ProductPromotionWidget = ({
    promotion,
    product,
    handleOpenDialogy,
    handleCloseDialogy,
    refetch,
}) => {
    // ############# RTK ######################
    const [
        removeProducPromotion,
        {
            isLoading: removeLoading,
            isSuccess: removeSuccess,
            isError: removeIsError,
            error: removeError,
            data: removePromotionData,
        },
    ] = useRemoveProductPromotionMutation();

    // ################ useEffect ################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (removeSuccess) {
                refetch();
                handleCloseDialogy();
                successNotify(getRTKSuccessMessage(removePromotionData));
            }
            if (removeIsError) {
                errorNotify(getRTKErrorMessage(removeError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [removeSuccess, removeIsError, removeError, removePromotionData]);

    return (
        <>
            <Box
                sx={{
                    border: `1px solid ${colors.bgColor3}`,
                    height: 160,
                    borderRadius: 5,
                    backgroundImage: !isEmpty(promotion)
                        ? `url(${promotion.promotion_image})`
                        : 'none',
                    backgroundPosition: 'center',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                {!isEmpty(promotion) ? (
                    <>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: `rgba(10, 54, 92, 0.2)`,
                            }}
                        >
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    fontSize: 13,
                                    my: 2,
                                    background: `rgba(10, 54, 92, 0.5)`,
                                    color: colors.white,
                                    p: 1,
                                }}
                            >
                                {promotion.promotion_name}
                            </Typography>
                            <Tooltip title="remove promotion" arrow>
                                <Button
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    className="app-btn"
                                    sx={{
                                        fontSize: 12,
                                    }}
                                    disabled={removeLoading ? true : false}
                                    onClick={() => {
                                        let payload = {
                                            product_id:
                                                product.product_details
                                                    .product_id,
                                            promotion_id: promotion.id,
                                        };
                                        removeProducPromotion(payload);
                                    }}
                                >
                                    {removeLoading ? (
                                        <CircularProgress
                                            size={20}
                                            sx={{ my: 0.2 }}
                                        />
                                    ) : (
                                        <Remove />
                                    )}
                                </Button>
                            </Tooltip>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: `rgba(10, 54, 92, 0.2)`,
                            }}
                        >
                            <Button
                                startIcon={<Add />}
                                variant="contained"
                                color="secondary"
                                size="small"
                                className="app-btn"
                                sx={{
                                    fontSize: 12,
                                }}
                                onClick={() => {
                                    let title = 'Add product to promotion';
                                    let content = 'addOnPromotion';
                                    handleOpenDialogy(title, content);
                                }}
                            >
                                Add promotion
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};

export default ProductPromotionWidget;
