import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../assets/utils/colors';

const ErrorPage404 = () => {
    const navigate = useNavigate();
    return (
        <>
            <Box
                sx={{
                    height: '91.2vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Grid container justifyContent={'center'}>
                    <Grid item sm={5}>
                        <Box
                            sx={{
                                height: 300,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                borderRadius: 5,
                                py: 5,
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: 55,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    404
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        color: colors.red,
                                    }}
                                >
                                    Opps! Page not found
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                        fontSize: 14,
                                    }}
                                >
                                    Sorry the page your try to access does not
                                    exist.
                                </Typography>
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{
                                        my: 2,
                                        mx: 2,
                                        boxShadow: 'none',
                                        textTransform: 'capitalize',
                                    }}
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                >
                                    Go back
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{
                                        my: 2,
                                        mx: 2,
                                        boxShadow: 'none',
                                        textTransform: 'capitalize',
                                    }}
                                    onClick={() => {
                                        navigate('/dashboard');
                                    }}
                                >
                                    dashboard
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ErrorPage404;
