import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    GET_ALL_CUSTOMERS,
    GET_SINGLE_CUSTOMER,
    GET_ALL_MANAGERS,
    GET_SINGLE_ORDER,
    DEACTIVATE_PROMOTION,
    GET_USER_PROFILE,
} from '../endPoints';
import { headers } from '../baseUrl/setHeaders';
import { baseURL } from '../baseUrl/baseURL';

export const userAPI = createApi({
    reducerPath: 'userAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        getUserProfile: builder.query({
            query: () => GET_USER_PROFILE,
        }),
        getAllCustomers: builder.query({
            query: () => `${GET_ALL_CUSTOMERS}`,
        }),
        getAllManagers: builder.query({
            query: () => `${GET_ALL_MANAGERS}`,
        }),
        getSingleCustomer: builder.query({
            query: (id) => `${GET_SINGLE_CUSTOMER}${id}`,
        }),
        getSingleManager: builder.query({
            query: (id) => `${GET_SINGLE_CUSTOMER}${id}`,
        }),
        getCustomerOrders: builder.query({
            query: (id) => `${GET_SINGLE_ORDER}${id}/customers`,
        }),
        activateCustomer: builder.mutation({
            query: (id) => ({
                url: `${DEACTIVATE_PROMOTION}${id}`,
                method: 'POST',
                body: [],
            }),
        }),
        deActivateCustomer: builder.mutation({
            query: (id) => ({
                url: `${DEACTIVATE_PROMOTION}${id}`,
                method: 'POST',
                body: [],
            }),
        }),
    }),
});

export const {
    useGetAllCustomersQuery,
    useActivateCustomerMutation,
    useGetCustomerOrdersQuery,
    useGetSingleCustomerQuery,
    useGetAllManagersQuery,
    useGetUserProfileQuery,
} = userAPI;
