import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { filter, isEmpty, reverse, size } from "lodash";
import { colors } from "../../assets/utils/colors";
import CustomCard2 from "../cards/CustomCard2";
import OrderWidget from "../widgets/OrderWidget";
import CustomCard1 from "../cards/CustomCard1";
import NoDataFeedback from "../feedbacks/NoDataFeedback";

const OrdersSideBar = ({ orders }) => {
    return (
        <>
            <Box>
                <CustomCard1
                    title={"Today orders"}
                    height={305}
                    borderBottom={true}
                    url={"/orders"}
                >
                    <Grid container>
                        {isEmpty(orders) ? (
                            <>
                                <NoDataFeedback message={"No order placed"} />
                            </>
                        ) : (
                            <>
                                <Grid container>
                                    {orders?.map((order, index) => (
                                        <Grid item sm={12} key={index}>
                                            <OrderWidget order={order} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </CustomCard1>
            </Box>
            <Box sx={{ mt: 2 }}>
                <CustomCard2 title={"Orders stats"} height={250}>
                    <Grid container padding={2} spacing={3}>
                        <Grid item sm={12}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Box sx={{ textAlign: "center" }}>
                                    <Typography
                                        sx={{
                                            fontSize: 22,
                                            color: colors.primary,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {size(orders)}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            color: colors.primary,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Total orders
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    color: colors.primary,
                                    fontWeight: "bold",
                                    borderBottom: "4px solid orange",
                                    pl: 1,
                                }}
                            >
                                {size(
                                    filter(orders, { order_status: "placed" })
                                )}
                                <sup style={{ fontSize: 10 }}>
                                    Placed orders
                                </sup>
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    color: colors.primary,
                                    fontWeight: "bold",
                                    borderBottom: "4px solid gray",
                                    pl: 1,
                                }}
                            >
                                {size(
                                    filter(orders, {
                                        order_status: "not_paid",
                                    })
                                )}
                                <sup style={{ fontSize: 10 }}>
                                    Unpaid orders
                                </sup>
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    color: colors.primary,
                                    fontWeight: "bold",
                                    borderBottom: "4px solid lightblue",
                                    pl: 1,
                                }}
                            >
                                {size(
                                    filter(orders, { order_status: "shipped" })
                                )}
                                <sup style={{ fontSize: 10 }}>
                                    Shipped orders
                                </sup>
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    color: colors.primary,
                                    fontWeight: "bold",
                                    borderBottom: "4px solid green",
                                    pl: 1,
                                }}
                            >
                                {size(
                                    filter(orders, {
                                        order_status: "delivered",
                                    })
                                )}
                                <sup style={{ fontSize: 10 }}>
                                    Delivered orders
                                </sup>
                            </Typography>
                        </Grid>
                    </Grid>
                </CustomCard2>
            </Box>
        </>
    );
};

export default OrdersSideBar;
