import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import { Link } from 'react-router-dom';

const BrandWidget = ({ brand }) => {
    return (
        <>
            <Link to={'/brands/details'}>
                <Box
                    sx={{
                        'borderRadius': 2,
                        'p': 0.5,
                        'transition': '.2s all ease-in-out',
                        'border': `1px solid ${colors.bgColor1}`,
                        'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                        ':hover': {
                            transform: 'scale(1.01)',
                            transition: '.2s all ease-in-out',
                            boxShadow: `2px 2px 5px 0px ${colors.bgColor3}`,
                        },
                    }}
                >
                    <Avatar
                        variant="square"
                        src={brand.brand_image}
                        sx={{
                            height: 45,
                            width: '100%',
                            p: 0.5,
                            img: {
                                objectFit: 'contain !important',
                            },
                        }}
                    />
                    <Box
                        sx={{
                            borderBottomRightRadius: 5,
                            borderBottomLeftRadius: 5,
                            borderLeft: `1px solid ${colors.bgColor3}`,
                            borderRight: `1px solid ${colors.bgColor3}`,
                            borderBottom: `1px solid ${colors.bgColor3}`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: 12,
                                textAlign: 'center',
                                p: 0.5,
                                color: colors.primary,
                                textTransform: 'capitalize',
                            }}
                        >
                            {brand.brand_name}
                        </Typography>
                    </Box>
                </Box>
            </Link>
        </>
    );
};

export default BrandWidget;
