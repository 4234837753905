import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, MenuItem } from '@mui/material';
import { Form, Formik } from 'formik';
import { colors } from '../../assets/utils/colors';
import CustomCard2 from '../../components/cards/CustomCard2';
import CustomeFileForm from '../../components/forms/CustomeFileForm';
import CustomeMultlineForm from '../../components/forms/CustomeMultlineForm';
import CustomTextField from '../../components/forms/CustomTextField';
import ProductsSidebar from '../../components/sidebars/ProductsSidebar';
import PageLayout from '../layouts/PageLayout';
import * as Yup from 'yup';
import { Image } from '@mui/icons-material';
import CustomSelectForm from '../../components/forms/CustomSelectForm';
import { useSelector } from 'react-redux';
import { filter, map, size } from 'lodash';
import { useAddNewProductMutation } from '../../api/products/productAPI';
import { brandsSelector, categoriesSelector } from '../../features/selectors';
import CustomSubmitButton from '../../components/forms/CustomSubmitButton';
import { errorNotify, successNotify } from '../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';

// ################### validation schema ###############
const createProductSchema = Yup.object({
    brand_id: Yup.string().required('Brand is required'),
    category_id: Yup.string().required('Category is required'),
    sub_category_id: Yup.string().required('Sub category is required'),
    product_name: Yup.string().required('Name is required'),
    product_description: Yup.string().required('Description is required'),
    file: Yup.mixed().required('Product image is required'),
    product_weight: Yup.string().required('Weight is required'),
    product_sku: Yup.string().required('SKU is required'),
    product_price: Yup.string().required('Price is required'),
    isOnSale: Yup.boolean().required('Identfy if product is onsell'),
    discounted_percent: Yup.string().max(
        2,
        'Discounted percent must be at most 2 characters',
    ),
});

// !################# MAIN FUNC ##################
const CreateProduct = () => {
    // ############# Comp state ###############
    const [filePreview, setFilePreview] = useState('');

    // ############# CONST ###############
    const navigate = useNavigate();

    // ############ Redux state ################
    const { brands } = useSelector(brandsSelector);
    const { categories, sub_categories } = useSelector(categoriesSelector);

    // ############# RTK #########################
    const [
        addProduct,
        { isSuccess, isLoading, isError, error, data: product },
    ] = useAddNewProductMutation();

    // ################# FUNC ###################
    // => Get sub-categories
    const getSubCategories = (id) => {
        return filter(sub_categories, ({ category_id }) => category_id === id);
    };

    // ############### UseEffect ##################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                setTimeout(() => {
                    successNotify(getRTKSuccessMessage(product));
                }, 1000);
                navigate(
                    `/products/details/${product?.data?.product_details?.product_id}`,
                );
            }
        }
        if (isError) {
            errorNotify(getRTKErrorMessage(error));
        }
    }, [isSuccess, isError, error, product, navigate]);

    return (
        <>
            <PageLayout sidebar={<ProductsSidebar />} head="Create new product">
                <CustomCard2 title={'Product details'} heightClear={640}>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            brand_id: '',
                            category_id: '',
                            sub_category_id: '',
                            product_name: '',
                            product_description: '',
                            file: '',
                            product_weight: '',
                            product_sku: '',
                            expire_date: '',
                            product_price: '',
                            isOnSale: 0,
                            discounted_percent: 0,
                        }}
                        validationSchema={createProductSchema}
                        onSubmit={(values) => {
                            const formData = new FormData();
                            formData.append('product_banner', values.file);
                            formData.append('brand_id', values.brand_id);
                            formData.append('category_id', values.category_id);
                            formData.append(
                                'sub_category_id',
                                values.sub_category_id,
                            );
                            formData.append(
                                'product_name',
                                values.product_name,
                            );
                            formData.append(
                                'product_description',
                                values.product_description,
                            );
                            formData.append(
                                'product_weight',
                                values.product_weight,
                            );
                            formData.append('product_sku', values.product_sku);
                            formData.append('expire_date', values.expire_date);
                            formData.append(
                                'product_price',
                                values.product_price,
                            );
                            formData.append('isOnSale', values.isOnSale);
                            formData.append(
                                'discounted_percent',
                                values.discounted_percent,
                            );
                            addProduct(formData);
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Grid container padding={2} spacing={2}>
                                    <Grid item sm={8}>
                                        <Grid container spacing={2}>
                                            <Grid item sm={6}>
                                                <CustomTextField
                                                    name="product_name"
                                                    label={'Product name'}
                                                    type="text"
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item sm={6}>
                                                <CustomTextField
                                                    name="product_sku"
                                                    label={'Product SKU'}
                                                    type="text"
                                                    color="primary"
                                                />
                                            </Grid>
                                            {/* ############ | brand | category | subcategory| ############# */}
                                            <Grid item sm={4}>
                                                <CustomSelectForm
                                                    name="brand_id"
                                                    formikName="brand_id"
                                                    label="Brand"
                                                    formik={formik}
                                                >
                                                    {size(brands) > 0 ? (
                                                        map(
                                                            brands,
                                                            ({
                                                                brand_name,
                                                                id,
                                                            }) => (
                                                                <MenuItem
                                                                    key={id}
                                                                    value={id}
                                                                >
                                                                    {brand_name}{' '}
                                                                </MenuItem>
                                                            ),
                                                        )
                                                    ) : (
                                                        <MenuItem value={null}>
                                                            No category
                                                        </MenuItem>
                                                    )}
                                                </CustomSelectForm>
                                            </Grid>
                                            <Grid item sm={4}>
                                                <CustomSelectForm
                                                    name="category_id"
                                                    formikName="category_id"
                                                    label="Category"
                                                    formik={formik}
                                                >
                                                    {size(categories) > 0 ? (
                                                        map(
                                                            categories,
                                                            ({
                                                                category_name,
                                                                id,
                                                            }) => (
                                                                <MenuItem
                                                                    key={id}
                                                                    value={id}
                                                                >
                                                                    {
                                                                        category_name
                                                                    }
                                                                </MenuItem>
                                                            ),
                                                        )
                                                    ) : (
                                                        <MenuItem value={null}>
                                                            No category
                                                        </MenuItem>
                                                    )}
                                                </CustomSelectForm>
                                            </Grid>
                                            <Grid item sm={4}>
                                                {/* {getSubCategories(formik.values.category_id)} */}
                                                <CustomSelectForm
                                                    name="sub_category_id"
                                                    formikName="sub_category_id"
                                                    label="Sub category"
                                                    formik={formik}
                                                >
                                                    {size(
                                                        getSubCategories(
                                                            formik.values
                                                                .category_id,
                                                        ),
                                                    ) > 0 ? (
                                                        map(
                                                            getSubCategories(
                                                                formik.values
                                                                    .category_id,
                                                            ),
                                                            ({
                                                                id,
                                                                sub_category_name,
                                                            }) => (
                                                                <MenuItem
                                                                    value={id}
                                                                >
                                                                    {
                                                                        sub_category_name
                                                                    }
                                                                </MenuItem>
                                                            ),
                                                        )
                                                    ) : (
                                                        <MenuItem value={null}>
                                                            No category
                                                        </MenuItem>
                                                    )}
                                                </CustomSelectForm>
                                            </Grid>
                                            {/* ############ |weight | price | discount | ############# */}
                                            <Grid item sm={6}>
                                                <CustomTextField
                                                    name="product_weight"
                                                    label={'Product weight'}
                                                    type="text"
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item sm={6}>
                                                <CustomTextField
                                                    name="product_price"
                                                    label={'Product price'}
                                                    type="number"
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid item sm={6}>
                                                <CustomTextField
                                                    name="discounted_percent"
                                                    label={'Discount %'}
                                                    type="number"
                                                    color="primary"
                                                />
                                            </Grid>
                                            {/* ############ |quantity | Expire date | ############# */}
                                            <Grid item sm={6}>
                                                <CustomTextField
                                                    name={'expire_date'}
                                                    label={'Expire date'}
                                                    type="date"
                                                    color="primary"
                                                    shrink
                                                />
                                                <small
                                                    style={{
                                                        fontSize: 10,
                                                        color: colors.danger,
                                                    }}
                                                >
                                                    **optional**
                                                </small>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Grid container>
                                            <Grid item sm={12} sx={{ mt: 2 }}>
                                                <Box
                                                    sx={{
                                                        height: 225,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        borderRadius: 5,
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                                    }}
                                                >
                                                    {filePreview ? (
                                                        <>
                                                            <img
                                                                src={
                                                                    filePreview
                                                                }
                                                                alt="img"
                                                                style={{
                                                                    width:
                                                                        '100%',
                                                                    height:
                                                                        '10em',
                                                                    objectFit:
                                                                        'contain',
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Image
                                                                sx={{
                                                                    width:
                                                                        '100%',
                                                                    height:
                                                                        '7em',
                                                                    objectFit:
                                                                        'contain',
                                                                    opacity: 0.7,
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </Box>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <CustomeFileForm
                                                    name="product_banner"
                                                    label={'Product image'}
                                                    type="file"
                                                    color="primary"
                                                    setFilePreview={
                                                        setFilePreview
                                                    }
                                                    formik={formik}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <CustomeMultlineForm
                                            name="product_description"
                                            label={'Product description'}
                                            type="text"
                                            color="primary"
                                            rows={6}
                                        />
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Box
                                            sx={{
                                                height: 150,
                                                mt: 2,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <CustomSubmitButton
                                                loading={isLoading}
                                                onClick={formik.handleSubmit}
                                            >
                                                Create product
                                            </CustomSubmitButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default CreateProduct;
