import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRTKResponseData } from "../../helpers/RTKHelpers";
import NoDataFeedback from "../feedbacks/NoDataFeedback";
import { Grid } from "@mui/material";
import { isEmpty } from "lodash";
import { ordersSelector } from "../../features/selectors";
import { useGetOrdersQuery } from "../../api/dashboard/dashboardAPI";
import DataFetchingFeedback from "../feedbacks/DataFetchingFeedback";
import { setOrders } from "../../features/orderSlice";
import OrderWidget from "../widgets/OrderWidget";

const DashTodayOders = () => {
    // ################# CONST ##################
    const dispatch = useDispatch();

    // ############## Redux ###################
    const { orders } = useSelector(ordersSelector);

    // ############## RTK ####################
    const {
        isLoading: ordersLoading,
        isSuccess: orderSuccess,
        data: ordersData,
    } = useGetOrdersQuery();

    // ############## useEffect ###################
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (orderSuccess) {
                dispatch(setOrders(getRTKResponseData(ordersData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [orderSuccess, ordersData, dispatch]);
    return (
        <>
            {ordersLoading ? (
                <>
                    <DataFetchingFeedback />
                </>
            ) : isEmpty(orders) ? (
                <>
                    <NoDataFeedback message={"No order placed"} />
                </>
            ) : (
                <>
                    <Grid container>
                        {orders?.map((order, index) => (
                            <Grid item sm={12} key={index}>
                                <OrderWidget order={order} />
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </>
    );
};

export default DashTodayOders;
