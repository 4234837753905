import { Grid } from '@mui/material';
import { isEmpty, size } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomCard2 from '../../components/cards/CustomCard2';
import PromotionsSidebar from '../../components/sidebars/PromotionsSidebar';
import PromotionWidget from '../../components/widgets/PromotionWidget';
import PageLayout from '../layouts/PageLayout';
import NoDataFeedback from '../../components/feedbacks/NoDataFeedback';
import CountBadge from '../../components/CountBadge';
import { promotionsSelector } from '../../features/selectors';
import { useGetPromotionsQuery } from '../../api/dashboard/dashboardAPI';
import DataFetchingFeedback from '../../components/feedbacks/DataFetchingFeedback';
import { setPromotions } from '../../features/promotionSlice';
import { getRTKResponseData } from '../../helpers/RTKHelpers';

// !############### MAIN FUNC ####################
const Promotions = () => {
    // ################# CONST ##################
    const dispatch = useDispatch();

    // ############## Redux ###################
    const { promotions } = useSelector(promotionsSelector);

    // ############## RTK ####################
    const {
        isLoading: promotionsLoading,
        isSuccess: promotionSuccess,
        data: promotionsData,
        refetch: promotionsRefetch,
    } = useGetPromotionsQuery();

    // ############## useEffect ###################
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            promotionsRefetch();
            if (promotionSuccess) {
                dispatch(setPromotions(getRTKResponseData(promotionsData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [promotionSuccess, promotionsData, promotionsRefetch, dispatch]);
    return (
        <>
            <PageLayout
                sidebar={
                    <PromotionsSidebar promotionsRefetch={promotionsRefetch} />
                }
                head="Promotions managements"
            >
                <CustomCard2
                    title={`CAICA Promotions`}
                    heightClear={640}
                    action={
                        <CountBadge
                            total={size(promotions)}
                            item={'Promotions'}
                        />
                    }
                >
                    {promotionsLoading ? (
                        <>
                            <DataFetchingFeedback />
                        </>
                    ) : isEmpty(promotions) ? (
                        <NoDataFeedback message={'No promotion added'} />
                    ) : (
                        <Grid container padding={2} spacing={4}>
                            {promotions.map((promotion, index) => (
                                <Grid item sm={4} key={index}>
                                    <PromotionWidget promotion={promotion} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default Promotions;
