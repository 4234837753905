import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { colors } from '../../assets/utils/colors';
import ProductBranchWidget from '../widgets/ProductBranchWidget';
import { isEmpty } from 'lodash';

const columns = [
    {
        id: 'branch',
        label: 'branch',
    },
];

const createData = (branch) => {
    return { branch };
};

// !################# MAIN FUNC ##################
const ProductBranchesTable = ({ stocks }) => {
    let rows = [];
    if (!isEmpty(stocks)) {
        rows = stocks?.map((branch) =>
            createData(<ProductBranchWidget branch={branch} />),
        );
    }
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ height: 362, m: 0 }}>
                {isEmpty(rows) ? (
                    <></>
                ) : (
                    <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                            '& th': {
                                bgcolor: `${colors.bgColor1} !important`,
                            },
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                {columns.map((column, index) => (
                                    <TableCell
                                        key={index}
                                        sx={{
                                            fontWeight: 'bold',
                                            color: colors.primary,
                                            textAlign: column.align,
                                            width: column.width,
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                    >
                                        {columns.map((column, index) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={index}
                                                    align={column.align}
                                                    sx={{
                                                        fontWeight:
                                                            column.fontWeight,
                                                        p: 1.5,
                                                    }}
                                                >
                                                    {column.format &&
                                                    typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
        </Paper>
    );
};

export default ProductBranchesTable;
