import getEnv from '../../helpers/getEnv';

// => Dev base url
const devBaseUrl = getEnv().REACT_APP_DEV_URL;

// => Pro base url
const proBaseUrl = getEnv().REACT_APP_PRODUCTION_URL;

const getBaseURL = () => {
    let env = process.env.REACT_APP_ENV;
    if (env === 'pro') {
        return proBaseUrl;
    }
    return devBaseUrl;
};

export const baseURL = getBaseURL();
