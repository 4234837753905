import { Image } from '@mui/icons-material';
import { Box, Button, Grid, MenuItem } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { colors } from '../../assets/utils/colors';
import CustomeFileForm from '../../components/forms/CustomeFileForm';
import CustomTextField from '../../components/forms/CustomTextField';
import * as Yup from 'yup';
import CustomSelectForm from '../../components/forms/CustomSelectForm';

// ################### validation schema ###############
const createManagerSchema = Yup.object({
    file: Yup.mixed().required('Promotion image is required'),
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    gender: Yup.string().required('Gender is required'),
    location: Yup.string().required('Location is required'),
    email: Yup.string().required('Email is required'),
    phone_number: Yup.string().required('Phone is required'),
    branch_id: Yup.string().required('Manager branch is required'),
});

const CreateAdmin = () => {
    // -> handle file preview
    const [filePreview, setFilePreview] = useState('');
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    file: '',
                    first_name: '',
                    last_name: '',
                    gender: '',
                    location: '',
                    email: '',
                    phone_number: '',
                    branch_id: '',
                }}
                validationSchema={createManagerSchema}
            >
                {(formik) => (
                    <Form>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item sm={7} sx={{ mt: 2 }}>
                                <Box
                                    sx={{
                                        height: 225,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: 5,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                    }}
                                >
                                    {filePreview ? (
                                        <>
                                            <img
                                                src={filePreview}
                                                alt="img"
                                                style={{
                                                    width: '100%',
                                                    height: '10em',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Image
                                                sx={{
                                                    width: '100%',
                                                    height: '7em',
                                                    objectFit: 'contain',
                                                    opacity: 0.7,
                                                }}
                                            />
                                        </>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item sm={5}>
                                <CustomeFileForm
                                    name="passport"
                                    label={'Manager passport'}
                                    type="file"
                                    color="primary"
                                    setFilePreview={setFilePreview}
                                    formik={formik}
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <CustomTextField
                                    name="first_name"
                                    label={'First name'}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <CustomTextField
                                    name="last_name"
                                    label={'Last name'}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <CustomSelectForm
                                    name="gender"
                                    formikName="gender"
                                    label="Select gender"
                                    formik={formik}
                                >
                                    <MenuItem value={'Male'}>
                                        Male
                                    </MenuItem>
                                    <MenuItem value={'Female'}>
                                        Female
                                    </MenuItem>
                                </CustomSelectForm>
                            </Grid>
                            <Grid item sm={4}>
                                <CustomTextField
                                    name="location"
                                    label={'Location'}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <CustomTextField
                                    name="email"
                                    label={'Email'}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <CustomTextField
                                    name="phone_number"
                                    label={'Phone'}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomSelectForm
                                    name="branch_id"
                                    formikName="branch_id"
                                    label="Select branch"
                                    formik={formik}
                                >
                                    <MenuItem value={1}>
                                        Kariako branch
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        Mbezi branch
                                    </MenuItem>
                                </CustomSelectForm>
                            </Grid>
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        className="app-btn"
                                        onClick={formik.handleSubmit}
                                    >
                                        Create manager
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CreateAdmin;
