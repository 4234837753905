import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import CustomTextField from '../../components/forms/CustomTextField';
import { colors } from '../../assets/utils/colors';
import CustomSelectForm from '../../components/forms/CustomSelectForm';
import { map, size } from 'lodash';
import { useUpdateProductStockMutation } from '../../api/products/productAPI';
import { errorNotify, successNotify } from '../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';
import CustomSubmitButton from '../../components/forms/CustomSubmitButton';
import { Inventory } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { branchesSelector } from '../../features/selectors';

// ################### validation schema ###############
const addBranchStockSchema = Yup.object({
    branch_id: Yup.string().required('Branch is required'),
    product_quantity: Yup.number().required('Stock quantity is required'),
});

const AddBranchStock = ({ product, refetch, handleCloseDialogy }) => {
    // ################# Comp state ################
    const { product_details } = product;
    const { branches } = useSelector(branchesSelector);

    // ################# FUNC ################
    // => Get in-stock
    const getInStock = () => {
        const inStockCount =
            product_details?.product_quantity - product_details?.product_sold;
        return inStockCount;
    };
    // => Get in-stock
    const getSold = () => {
        const soldCount = product_details?.product_sold;
        return soldCount;
    };
    // => Get in-stock
    const getTotalSell = () => {
        const totalSells = getSold() * product_details?.product_price;
        return totalSells;
    };

    // ############### RTK ########################
    const [
        updateProductStock,
        { isLoading, isSuccess, isError, error, data: stockData },
    ] = useUpdateProductStockMutation();

    // ############### useEffect ########################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                refetch();
                handleCloseDialogy();
                successNotify(getRTKSuccessMessage(stockData));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }

        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, error, stockData]);

    return (
        <>
            <Formik
                initialValues={{
                    branch_id: '',
                    product_id: product_details.product_id,
                    product_quantity: '',
                }}
                validationSchema={addBranchStockSchema}
                onSubmit={(payload) => {
                    updateProductStock(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            p: 1,
                                            border: `1px solid ${colors.bgColor2}`,
                                            borderRadius: 2,
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            Total In-stock
                                        </Typography>
                                        <Typography>{getInStock()}</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            p: 1,
                                            border: `1px solid ${colors.bgColor2}`,
                                            borderRadius: 2,
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            Total Sold
                                        </Typography>
                                        <Typography>{getSold()}</Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            p: 1,
                                            border: `1px solid ${colors.bgColor2}`,
                                            borderRadius: 2,
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            Total sells
                                        </Typography>
                                        <Typography>
                                            {getTotalSell()} <sup>TZS</sup>{' '}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={8}>
                                <CustomSelectForm
                                    name="branch_id"
                                    formikName="branch_id"
                                    label="Branch"
                                    formik={formik}
                                >
                                    {size(branches) > 0 ? (
                                        map(branches, ({ branch_name, id }) => (
                                            <MenuItem key={id} value={id}>
                                                {branch_name}{' '}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value={null}>
                                            No category
                                        </MenuItem>
                                    )}
                                </CustomSelectForm>
                            </Grid>
                            <Grid item sm={4}>
                                <CustomTextField
                                    name="product_quantity"
                                    label={'Stock quantity'}
                                    color="primary"
                                    type={'number'}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        onClick={formik.handleSubmit}
                                        startIcon={<Inventory />}
                                    >
                                        Add stock quantity
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddBranchStock;
