import React, { useState } from 'react';
import { Add } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import CustomCard2 from '../cards/CustomCard2';
import ProductBranchesTable from '../tables/ProductBranchesTable';
import AddBranchStock from '../../pages/products/AddBranchStock';
import CustomDialogy from '../dialogys/CustomDialogy';
import { numberWithCommas } from '../../utils/helpers';
import { isEmpty } from 'lodash';

// !############### MAIN FUNC ####################
const ProductDetailsSidebar = ({ product, refetch }) => {
    // ################# Comp state ################
    const [openDialogy, setOpenDialogy] = useState(false);
    const { product_details } = product;
    const { product_stocks } = product;

    // ################# FUNC ################
    const handleOpenDialogy = () => {
        setOpenDialogy(true);
    };
    const handleCloseDialogy = () => {
        setOpenDialogy(false);
    };
    // => Get in-stock
    const getInStock = () => {
        const inStockCount =
            product_details?.product_quantity - product_details?.product_sold;
        return inStockCount;
    };
    // => Get sold
    const getSold = () => {
        const soldCount = product_details?.product_sold;
        return soldCount;
    };
    // => Get in-stock
    const getTotalSell = () => {
        const totalSells = getSold() * product_details?.product_price;
        return totalSells;
    };

    return (
        <>
            {/* ############# DIALOG ############### */}
            <CustomDialogy
                title={'Add product stock'}
                open={openDialogy}
                handleCloseDialogy={handleCloseDialogy}
            >
                <AddBranchStock
                    product={product}
                    refetch={refetch}
                    handleCloseDialogy={handleCloseDialogy}
                />
            </CustomDialogy>

            {/* ################ Main Content ################ */}
            <CustomCard2 title={'Products perfomance'} height={640}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item sm={12}>
                        <ProductBranchesTable stocks={product_stocks} />
                    </Grid>
                    <Grid item sm={12}>
                        <Box sx={{ textAlign: 'center' }}>
                            <Button
                                startIcon={<Add />}
                                className="app-btn"
                                variant="contained"
                                onClick={() => {
                                    let title = 'Add product on-sell';
                                    let content = 'addOnSell';
                                    handleOpenDialogy(title, content);
                                }}
                            >
                                Add stock
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
                >
                    <Grid container justifyContent={'center'}>
                        <Grid item sm={12}>
                            <Box
                                sx={{
                                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor1})`,
                                    borderRadius: 2,
                                    py: 2,
                                    color: colors.primary,
                                }}
                            >
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        py: 1,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    General stats
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mt: 0.5,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            In-stock
                                        </Typography>
                                        <Typography sx={{ fontSize: 13 }}>
                                            {!isEmpty(getInStock())
                                                ? getInStock()
                                                : 0}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            borderLeft: '1px solid green',
                                            borderRight: '1px solid green',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Sold
                                        </Typography>
                                        <Typography sx={{ fontSize: 13 }}>
                                            {getSold()}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Total sells
                                        </Typography>
                                        <Typography sx={{ fontSize: 13 }}>
                                            {numberWithCommas(getTotalSell())}{' '}
                                            <sup>TZS</sup>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </CustomCard2>
        </>
    );
};

export default ProductDetailsSidebar;
