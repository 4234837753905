import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    categories: [],
    sub_categories: [],
    refetchCategories: 0,
    refetchSubCategories: 0,
};

export const categorySlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
        setSubCategories: (state, action) => {
            state.sub_categories = action.payload;
        },
        addCategory: (state, action) => {
            let category = action.payload;
            state.categories = [category, ...state.categories];
        },
        addSubCategory: (state, action) => {
            let subCategory = action.payload;
            state.sub_categories = [subCategory, ...state.sub_categories];
        },
        setRefetchCategories: (state, action) => {
            state.refetchCategories = state.refetchBrands + 1;
        },
        setRefetchSubCategories: (state, action) => {
            state.refetchSubCategories = state.refetchBrands + 1;
        },
    },
});

export const {
    setCategories,
    setSubCategories,
    addCategory,
    addSubCategory,
    setRefetchCategories,
    setRefetchSubCategories,
} = categorySlice.actions;

export default categorySlice.reducer;
