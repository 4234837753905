import React, { useEffect } from "react";
import moment from "moment";
import {
    LocalShipping,
    LocationOn,
    Person,
    Timelapse,
    TimeToLeave,
} from "@mui/icons-material";
import { Box, Button, Chip, Grid, Link, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../assets/utils/colors";
import CustomCard2 from "../../components/cards/CustomCard2";
import OrdersSideBar from "../../components/sidebars/OrdersSideBar";
import OrderProductTable from "../../components/tables/OrderProductTable";
import PageLayout from "../layouts/PageLayout";

// -> rtk query and redux imports
import { useGetOneOrderQuery } from "../../api/orders/orderAPI";
import { setOrder } from "../../features/orderSlice";

// -> helpers imports
import { getChipColor, numberWithCommas } from "../../utils/helpers";
import DataFetchingFeedback from "../../components/feedbacks/DataFetchingFeedback";
import { ordersSelector } from "../../features/selectors";
import NoDataFeedback from "../../components/feedbacks/NoDataFeedback";
import { removeSpecialChar } from "../../helpers/stringHelpers";
import CustomDialogy from "../../components/dialogys/CustomDialogy";
import OrderShipping from "./OrderShipping";
import { getRTKResponseData } from "../../helpers/RTKHelpers";
import { isEmpty } from "lodash";
import DeliverOrder from "./DeliverOrder";

// !############### MAIN FUNC ####################
const OrderDetails = () => {
    // ################ CONST #################
    const dispatch = useDispatch();
    const { order_id } = useParams();

    // ############### Comp States ############
    const [openDialogy, setOpenDialogy] = React.useState(false);

    // ############### Redux state ################
    const { orders, order } = useSelector(ordersSelector);

    // ################# RTK ##################
    const {
        data: orderDetails,
        isLoading,
        isSuccess,
        isError,
        refetch: refetchOrder,
    } = useGetOneOrderQuery(order_id);

    // ############## FUNC ###################
    // => Dialogy open
    const handleDialogyOpen = () => {
        setOpenDialogy(true);
    };
    // => Dialogy close
    const handleCloseDialogy = () => {
        setOpenDialogy(false);
    };

    // ############ useEffect #####################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(setOrder(getRTKResponseData(orderDetails)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, orderDetails]);

    return (
        <>
            {/* ############### Dialogy ################# */}
            <CustomDialogy
                title={"Order shipping"}
                open={openDialogy}
                handleCloseDialogy={handleCloseDialogy}
            >
                <OrderShipping
                    orderID={order_id}
                    refetchOrder={refetchOrder}
                    closeDialogy={handleCloseDialogy}
                />
            </CustomDialogy>

            <PageLayout
                sidebar={<OrdersSideBar orders={orders} />}
                head="Order details"
            >
                {isLoading ? (
                    <CustomCard2 height={635}>
                        <DataFetchingFeedback />
                    </CustomCard2>
                ) : isError ? (
                    <CustomCard2 height={635}>
                        <NoDataFeedback message={"Order detail not found"} />
                    </CustomCard2>
                ) : (
                    !isEmpty(order) && (
                        <CustomCard2
                            title={`#${order.order_number}`}
                            height={640}
                            action={
                                <Chip
                                    sx={{
                                        textTransform: "capitalize",
                                    }}
                                    size="small"
                                    label={removeSpecialChar(
                                        order.order_status,
                                        " "
                                    )}
                                    color={getChipColor(order.order_status)}
                                />
                            }
                        >
                            <Grid container>
                                <Grid item sm={12}>
                                    <OrderProductTable
                                        ordered_products={
                                            order.ordered_products
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item sm={8}>
                                    <Grid container>
                                        <Grid item sm={8}>
                                            <Box
                                                sx={{
                                                    height: 200,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    spacing={4}
                                                    padding={2}
                                                >
                                                    <Grid item sm={6}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <Box sx={{ p: 1 }}>
                                                                <Person
                                                                    sx={{
                                                                        color: colors.primary,
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 12,
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    Customer
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    {`${order.customer.first_name} ${order.customer.last_name}`}
                                                                </Typography>
                                                                <Link
                                                                    sx={{
                                                                        fontSize: 13,
                                                                        fontWeight:
                                                                            "bold",
                                                                        color: colors.warning,
                                                                    }}
                                                                >
                                                                    {`${order.customer.phone_number}`}
                                                                </Link>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item sm={6}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <Box sx={{ p: 1 }}>
                                                                <Timelapse
                                                                    sx={{
                                                                        color: colors.primary,
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 12,
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    Ordered On
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    {moment(
                                                                        order.order_date
                                                                    ).format(
                                                                        "MMM Do YY"
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item sm={6}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <Box sx={{ p: 1 }}>
                                                                <LocalShipping
                                                                    sx={{
                                                                        color: colors.primary,
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 12,
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    Delivery
                                                                    option
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    {order.delivery_type ===
                                                                    "delivery"
                                                                        ? "Door delivery"
                                                                        : "Self pickup"}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item sm={6}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <Box sx={{ p: 1 }}>
                                                                <LocationOn
                                                                    sx={{
                                                                        color: colors.primary,
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 12,
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    Delivered
                                                                    address
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    {order.pickup_branch
                                                                        ? order.pickup_branch
                                                                        : order.delivery_address
                                                                        ? order.delivery_address
                                                                        : "unavailable"}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Box
                                                sx={{
                                                    height: 200,
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-around",
                                                    flexDirection: "column",
                                                    p: 2,
                                                }}
                                            >
                                                {order.order_status ===
                                                    "placed" && (
                                                    <Button
                                                        variant="contained"
                                                        color="info"
                                                        fullWidth
                                                        className="app-btn"
                                                        onClick={() => {
                                                            handleDialogyOpen();
                                                        }}
                                                    >
                                                        <TimeToLeave
                                                            sx={{
                                                                fontSize: 18,
                                                                mr: 1,
                                                            }}
                                                        />
                                                        Ship order
                                                    </Button>
                                                )}
                                                {order.order_status ===
                                                    "shipped" && (
                                                    <DeliverOrder
                                                        orderID={order_id}
                                                        refetchOrder={
                                                            refetchOrder
                                                        }
                                                    />
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={4}>
                                    <Box
                                        sx={{
                                            height: 200,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontStyle: "italic",
                                                color: colors.primary,
                                            }}
                                        >
                                            Subtotal
                                            <sup
                                                style={{
                                                    fontSize: 12,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                (TZS)
                                            </sup>
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 25,
                                                fontWeight: "bold",
                                                color: colors.primary,
                                            }}
                                        >
                                            {numberWithCommas(
                                                order.total_amount
                                            )}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CustomCard2>
                    )
                )}
            </PageLayout>
        </>
    );
};

export default OrderDetails;
