import { Inventory, Outbox, ShoppingCart } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DashInfobox2 from './infoboxes/DashInfobox2';
import { useSelector } from 'react-redux';
import { productsSelector } from '../features/selectors';
import { filter, size } from 'lodash';

const DashMinInfoboxes = () => {
    // ############## Comp state ###################
    const [inStockProducts, setInStockProducts] = useState([]);
    const [soldProducts, setSoldProducts] = useState([]);
    const [outOfStockProducts, setOutOfStockProducts] = useState([]);

    // ############## Redux state #######################
    const { products } = useSelector(productsSelector);

    // => Get in stock products
    const getInStockProducts = () => {
        const outOfStockProducts = filter(
            products,
            ({ product_details }) =>
                product_details.product_quantity -
                    product_details.product_sold !==
                0,
        );
        setInStockProducts(outOfStockProducts);
    };

    // => Get in-stock
    const getSoldProducts = () => {
        const soldProducts = filter(
            products,
            ({ product_details }) => product_details.product_sold !== 0,
        );
        setSoldProducts(soldProducts);
    };

    // => Get out of stock products
    const getOutOfStockProducts = () => {
        const outOfStockProducts = filter(
            products,
            ({ product_details }) =>
                product_details.product_quantity -
                    product_details.product_sold ===
                0,
        );
        setOutOfStockProducts(outOfStockProducts);
    };

    // ############## UseEffect #######################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getSoldProducts();
            getInStockProducts();
            getOutOfStockProducts();
        }
        return () => {
            isSubscribed = false;
        };
    }, []);

    const infoBox = [
        {
            id: 1,
            head: 'In-Stock',
            subhead: <strong style={{fontSize: 15}}>{size(inStockProducts)}</strong>,
            icon: <Inventory className="icon" />,
        },
        {
            id: 2,
            head: 'Sold products',
            subhead: <strong style={{fontSize: 15}}>{size(soldProducts)}</strong>,
            icon: <ShoppingCart className="icon" />,
        },
        {
            id: 3,
            head: 'Out-of-stocks',
            subhead: <strong style={{fontSize: 15}}>{size(outOfStockProducts)}</strong>,
            icon: <Outbox className="icon" />,
        },
    ];
    return (
        <>
            {infoBox.map((item) => (
                <Grid item sm={4} key={item.id}>
                    <DashInfobox2
                        head={item.head}
                        subhead={item.subhead}
                        icon={item.icon}
                    />
                </Grid>
            ))}
        </>
    );
};

export default DashMinInfoboxes;
