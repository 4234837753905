import React from "react";
import CustomCard2 from "../../components/cards/CustomCard2";
import OrdersSideBar from "../../components/sidebars/OrdersSideBar";
import OrdersTable from "../../components/tables/OrdersTable";
import { useSelector } from "react-redux";
import PageLayout from "../layouts/PageLayout";
import { isEmpty, size, reverse } from "lodash";
import { ordersSelector } from "../../features/selectors";
import CountBadge from "../../components/CountBadge";
import NoDataFeedback from "../../components/feedbacks/NoDataFeedback";

// !############### MAIN FUNC ####################
const Orders = () => {
    const { orders } = useSelector(ordersSelector);
    return (
        <>
            <PageLayout
                head="Orders managements"
                sidebar={<OrdersSideBar orders={orders} />}
            >
                <CustomCard2
                    title={`Customer orders`}
                    height={640}
                    action={<CountBadge total={size(orders)} item={"Orders"} />}
                >
                    {isEmpty(orders) ? (
                        <NoDataFeedback message={"No order found"} />
                    ) : (
                        <OrdersTable orders={orders} />
                    )}
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default Orders;
