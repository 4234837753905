import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TopBar from '../../components/TopBar';
import SideBar from '../../components/SideBar';
import { colors } from '../../assets/utils/colors';
import HotToastfy from '../../components/HotToastfy';

// importing slices
import { setProducts } from '../../features/productSlice';
import { setOrders } from '../../features/orderSlice';

// RTK query api imports
import {
    useGetProductsQuery,
    useGetOrdersQuery,
    useGetBrandsQuery,
} from '../../api/dashboard/dashboardAPI';
import { useDispatch, useSelector } from 'react-redux';
import FullAppLoader from '../../components/FullAppLoader';
import { getRTKResponseData } from '../../helpers/RTKHelpers';
import { categoriesSelector } from '../../features/selectors';
import {
    useGetAllSubCategoriesQuery,
    useGetCategoriesQuery,
} from '../../api/categories/categoryAPI';
import { setCategories, setSubCategories } from '../../features/categorySlice';
import { useGetAllManagersQuery } from '../../api/users/userAPI';
import { setManagers } from '../../features/userSlice';
import { setBrands } from '../../features/brandSlice';

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

//! ################## MAIN FUNC ##########################
const AppLayout = ({ children }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [desktopOpen, setDesktopOpen] = React.useState(true);
    const handleDrawerOpen = () => {
        setDesktopOpen(true);
    };
    const handleDrawerClose = () => {
        setDesktopOpen(false);
    };

    // ################# CONST ##################
    const dispatch = useDispatch();

    // ################# RTK ####################
    const {
        isLoading: brandsLoading,
        isSuccess: brandsSuccess,
        data: brandsData,
    } = useGetBrandsQuery();
    const {
        isLoading: productsLoading,
        isSuccess: productSuccess,
        data: products,
    } = useGetProductsQuery();
    const {
        isLoading: ordersLoading,
        isSuccess: orderSuccess,
        data: orders,
    } = useGetOrdersQuery();
    const {
        isLoading: categoriesLoading,
        isSuccess: categoriesuccess,
        data: categories,
    } = useGetCategoriesQuery();
    const {
        isLoading: subCategoriesLoading,
        isSuccess: subCategoriesuccess,
        data: subCategories,
    } = useGetAllSubCategoriesQuery();

    // ################ MAIN dispatch ####################
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (productSuccess) {
                dispatch(setProducts(getRTKResponseData(products)));
            }
            if (orderSuccess) {
                dispatch(setOrders(getRTKResponseData(orders)));
            }
            if (categoriesuccess) {
                dispatch(setCategories(getRTKResponseData(categories)));
            }
            if (subCategoriesuccess) {
                dispatch(setSubCategories(getRTKResponseData(subCategories)));
            }
            if (brandsSuccess) {
                dispatch(setBrands(getRTKResponseData(brandsData)));
            }
        }

        return () => {
            isSubscribed = false;
        };
    }, [
        orders,
        dispatch,
        products,
        categories,
        orderSuccess,
        productSuccess,
        categoriesLoading,
        categoriesuccess,
        subCategoriesLoading,
        subCategoriesuccess,
        subCategories,
        brandsSuccess,
    ]);

    return (
        <>
            {/* {false ? ( */}
            {brandsLoading &&
            productsLoading &&
            ordersLoading &&
            categoriesLoading &&
            subCategoriesLoading ? (
                <>
                    <FullAppLoader />
                </>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        top: 0,
                        bottom: '100%',
                        right: 0,
                        left: 0,
                    }}
                >
                    {/* ############## Tostfy ############### */}
                    <HotToastfy />

                    {/* ############### topbar ############### */}
                    <TopBar
                        mobileOpen={mobileOpen}
                        desktopOpen={desktopOpen}
                        handleDrawerClose={handleDrawerClose}
                        handleDrawerToggle={handleDrawerToggle}
                    />

                    {/* ############### sidebar ###############*/}
                    <SideBar
                        mobileOpen={mobileOpen}
                        desktopOpen={desktopOpen}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDrawerClose={handleDrawerClose}
                    />

                    {/* ############### content ###############*/}
                    <Box
                        component="main"
                        sx={{ bgcolor: colors.secondary, flexGrow: 1 }}
                    >
                        <DrawerHeader />
                        {children}
                    </Box>
                </Box>
            )}
        </>
    );
};

export default AppLayout;
