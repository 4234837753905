import { isEmpty } from 'lodash';

// => Remove special charactor
export const removeSpecialChar = (value, replace) => {
    let string = value.replace(/[^a-zA-Z0-9]/g, replace);
    return string;
};

// => Get name initial char
export const getInitialNameChar = (name) => {
    if (!isEmpty(name)) {
        return {
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
};
