import React from 'react';
import { Box, Grid, MenuItem } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomTextField from '../../components/forms/CustomTextField';

import CustomeMultlineForm from '../../components/forms/CustomeMultlineForm';
import CustomSelectForm from '../../components/forms/CustomSelectForm';
import { map, size } from 'lodash';
import { categoriesSelector } from '../../features/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
    addSubCategory,
    setRefetchCategories,
    setRefetchSubCategories,
} from '../../features/categorySlice';
import useRTK from '../../hooks/useRTK';
import { useUpdateSubCategoryMutation } from '../../api/categories/categoryAPI';
import CustomSubmitButton from '../../components/forms/CustomSubmitButton';

const updateSubcategoryValidationSchema = Yup.object({
    category_id: Yup.string().required('Category is required'),
    sub_category_name: Yup.string()
        .required('Sub category name is required')
        .max(50, "sub category name must be at most 50 characters"),
    description: Yup.string().nullable(),
});

const UpdateSubcategory = ({ closeDialogy, category }) => {
    // ############### CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Redux state ##############
    const { categories } = useSelector(categoriesSelector);

    // ############### RTK ###############
    const [
        updateSubCategory,
        { isLoading, isSuccess, isError, error, data: subCategoryData },
    ] = useUpdateSubCategoryMutation();

    // ############## Resp ###############
    const rtkActions = (data) => {
        closeDialogy();
        dispatch(addSubCategory(data));
        dispatch(setRefetchSubCategories());
        dispatch(setRefetchCategories());
    };
    RTK.useRTKResponse(isSuccess, isError, error, subCategoryData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    category_id: category.category_id,
                    sub_category_id: category.id,
                    sub_category_name: category.sub_category_name,
                    description: category.description,
                }}
                validationSchema={updateSubcategoryValidationSchema}
                onSubmit={(payload) => {
                    updateSubCategory(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item sm={6}>
                                <CustomTextField
                                    name="sub_category_name"
                                    label={'Sub category name'}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomSelectForm
                                    name="category_id"
                                    formikName="category_id"
                                    label="Category"
                                    formik={formik}
                                >
                                    {size(categories) > 0 ? (
                                        map(categories, (category) => (
                                            <MenuItem
                                                key={category.id}
                                                value={category.id}
                                            >
                                                {category.category_name}{' '}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value={null}>
                                            No category
                                        </MenuItem>
                                    )}
                                </CustomSelectForm>
                            </Grid>
                            <Grid item sm={12}>
                                <CustomeMultlineForm
                                    name="description"
                                    label={'Sub category description'}
                                    color="primary"
                                    rows={5}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        onClick={formik.handleSubmit}
                                    >
                                        Create sub category
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateSubcategory;
