import React from 'react';
import {
    Avatar,
    Box,
    Chip,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/colors';
import { Link } from 'react-router-dom';
import { RemoveRedEye } from '@mui/icons-material';
import { size } from 'lodash';

const BrandWidget2 = ({ brand }) => {
    const { brand_image, brand_name, brand_status, id } = brand;
    return (
        <>
            <Box
                sx={{
                    borderRadius: 2,
                    p: 0.5,
                    border: `1px solid ${colors.bgColor1}`,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                    transition: '.2s all ease-in-out',
                    boxShadow: `1px 1px 5px 2px ${colors.bgColor2}`,
                }}
            >
                <Avatar
                    variant="square"
                    src={brand_image}
                    sx={{
                        height: 85,
                        width: '100%',
                        img: {
                            objectFit: 'contain !important',
                        },
                        borderRadius: 1,
                        p: 1,
                    }}
                />
                <Box>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: 12,
                            textAlign: 'center',
                            p: 0.5,
                            color: colors.primary,
                        }}
                    >
                        {brand_name}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 0.5,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                            }}
                        >
                            Products
                        </Typography>
                        <Typography sx={{ fontSize: 13 }}>
                            {size(brand.brand_products)}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            borderLeft: '1px solid green',
                            borderRight: '1px solid green',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {brand_status ? (
                            <Chip size="small" color="success" label="active" />
                        ) : (
                            <Chip size="small" color="error" label="inactive" />
                        )}
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        <Tooltip title="Brand details">
                            <Link to={`/brands/details/${id}`}>
                                <IconButton color="info">
                                    <RemoveRedEye />
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default BrandWidget2;
