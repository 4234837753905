import { Image } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { colors } from '../../assets/utils/colors';
import CustomeFileForm from '../../components/forms/CustomeFileForm';
import CustomeMultlineForm from '../../components/forms/CustomeMultlineForm';
import CustomTextField from '../../components/forms/CustomTextField';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import CustomSubmitButton from '../../components/forms/CustomSubmitButton';
import { useCreatePromotionMutation } from '../../api/promotions/promotionAPI';
import { addPromotion } from '../../features/promotionSlice';

// ################### validation schema ###############
const createPromotionSchema = Yup.object({
    promotion_name: Yup.string().required('Promotion name is required'),
    promotion_description: Yup.string().nullable(),
    file: Yup.mixed().required('Promotion image is required'),
});

const CreatePromotion = ({ closeDialogy, promotionsRefetch }) => {
    // -> handle file preview
    const [filePreview, setFilePreview] = useState('');
    // ############### CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK ###############
    const [
        createPromotion,
        { isLoading, isSuccess, isError, error, data: promotionData },
    ] = useCreatePromotionMutation();

    // ############## Resp ###############
    const rtkActions = (data) => {
        closeDialogy();
        promotionsRefetch();
        dispatch(addPromotion(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, promotionData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    promotion_name: '',
                    file: '',
                    promotion_description: '',
                }}
                validationSchema={createPromotionSchema}
                onSubmit={(values) => {
                    const payload = new FormData();
                    payload.append('promo_image', values.file);
                    payload.append('promotion_name', values.promotion_name);
                    payload.append(
                        'promotion_description',
                        values.promotion_description,
                    );
                    createPromotion(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item sm={7} sx={{ mt: 2 }}>
                                <Box
                                    sx={{
                                        height: 225,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: 5,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                    }}
                                >
                                    {filePreview ? (
                                        <>
                                            <img
                                                src={filePreview}
                                                alt="img"
                                                style={{
                                                    width: '100%',
                                                    height: '10em',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Image
                                                sx={{
                                                    width: '100%',
                                                    height: '7em',
                                                    objectFit: 'contain',
                                                    opacity: 0.7,
                                                }}
                                            />
                                        </>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item sm={5}>
                                <CustomeFileForm
                                    name="promotion_image"
                                    label={'Promotion image'}
                                    type="file"
                                    color="primary"
                                    setFilePreview={setFilePreview}
                                    formik={formik}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomTextField
                                    name="promotion_name"
                                    label={'Promotion name'}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomeMultlineForm
                                    name="promotion_description"
                                    label={'Promotion description'}
                                    color="primary"
                                    rows={5}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        onClick={formik.handleSubmit}
                                    >
                                        Create promotion
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CreatePromotion;
