import React from 'react';
import { LocalShipping, LocationOn, LockClock } from '@mui/icons-material';
import { Box, Chip, Typography } from '@mui/material';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/colors';
import { getChipColor } from '../../utils/helpers';
import { removeSpecialChar } from '../../helpers/stringHelpers';
import { currencyFomater } from '../../helpers/currencyFomater';

const OrderWidget = ({ order }) => {
    return (
        <>
            <Link to={`/orders/details/${order.id}`}>
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                        borderRadius: 2,
                        overflow: "hidden",
                        p: 0.5,
                        mb: 2,
                        transition: ".3s all ease-in-out",
                        boxShadow: `.5px 1px 5px 1px ${colors.bgColor3}`,
                        "&:hover": {
                            boxShadow: `.5px 1px 5px 1px ${colors.bgColor1}`,
                            transition: ".3s all ease-in-out",
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            px: 1,
                            py: 0.5,
                            alignItems: "center",
                        }}
                    >
                        <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
                            #{order.order_number}
                        </Typography>
                        <Chip
                            size="small"
                            variant="contained"
                            color={getChipColor(order.order_status)}
                            label={removeSpecialChar(order.order_status, " ")}
                            sx={{
                                fontSize: 10,
                                fontWeight: "bold",
                                textTransform: "capitalize",
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            p: 1,
                        }}
                    >
                        <Box>
                            <Typography
                                noWrap
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 10,
                                    mb: 0.5,
                                    textTransform: "capitalize",
                                }}
                            >
                                <LocalShipping sx={{ fontSize: 12, mr: 1 }} />
                                {order.delivery_type}
                            </Typography>
                            <Typography
                                noWrap
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 10,
                                }}
                            >
                                <LocationOn sx={{ fontSize: 12, mr: 1 }} />
                                {order.pickup_branch
                                    ? order.pickup_branch
                                    : order.delivery_address
                                    ? order.delivery_address
                                    : "unavailable"}
                            </Typography>
                            <Typography
                                noWrap
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 10,
                                    fontWeight: "bold",
                                }}
                            >
                                <LockClock sx={{ fontSize: 12, mr: 1 }} />
                                {moment(order.created_at).fromNow()}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    color: colors.primary,
                                    fontWeight: "bold",
                                }}
                            >
                                {currencyFomater(order.total_amount)}
                                <sup
                                    style={{
                                        fontSize: 10,
                                        color: colors.primary,
                                    }}
                                >
                                    Tshs
                                </sup>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Link>
        </>
    );
};

export default OrderWidget;
