import React from 'react';
import {
  // Inventory,
  Message,
  Phone,
  RemoveRedEye,
  WhatsApp,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import user from '../../assets/media/images/user3.png';
import { colors } from '../../assets/utils/colors';
import CustomDialogy from '../dialogys/CustomDialogy';
import ManagerDetails from '../../pages/users/ManagerDetails';

const ManagerWidget = ({ manager }) => {
  const [openDialogy, setOpenDialogy] = React.useState(false);

  const handleOpenDialogy = () => {
    setOpenDialogy(true);
  };

  const handleCloseDialogy = () => {
    setOpenDialogy(false);
  };

  return (
    <>
      {/* ############### Dialogy ################# */}
      <CustomDialogy
        title={'Manager Details'}
        open={openDialogy}
        noDropOut={true}
        handleCloseDialogy={handleCloseDialogy}
      >
        <ManagerDetails manager={manager} />
      </CustomDialogy>

      <Box
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
          position: 'relative',
          zIndex: 2,
          '& .social-icons': {
            transition: '.3s all ease-in-out',
            '& .icon': {
              fontSize: 40,
              background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
              p: 1,
              borderRadius: 50,
            },
          },
          ':hover': {
            '& .social-icons': {
              bottom: 60,
              transition: '.3s all ease-in-out',
            },
          },
        }}
      >
        <Box
          sx={{
            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
            p: 2,
          }}
        >
          <Avatar
            src={user}
            variant="square"
            sx={{
              height: 200,
              width: '100%',
              borderRadius: 5,
              img: {
                objectFit: 'contain',
              },
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              py: 1,
              borderRadius: 2,
              top: 0,
            }}
          >
            {manager.isActive ? (
              <Chip
                label="Active"
                color="success"
                size="small"
                sx={{
                  fontSize: 8,
                  fontWeight: 'bold',
                }}
              />
            ) : (
              <Chip
                label="Inactive"
                color="error"
                size="small"
                sx={{
                  fontSize: 8,
                  fontWeight: 'bold',
                }}
              />
            )}
          </Box>
        </Box>
        <Box
          className="social-icons"
          sx={{
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            bottom: -5,
            right: 0,
            left: 0,
            zIndex: 1,
            borderRadius: 1,
          }}
        >
          <Tooltip title={manager.phone_number} arrow>
            <IconButton size="small" color="warning">
              <Phone sx={{ fontSize: 35 }} className="icon" />
            </IconButton>
          </Tooltip>
          <Tooltip title={manager.email ? manager.email : 'unavailable'} arrow>
            <IconButton size="small" color="info">
              <Message sx={{ fontSize: 35 }} className="icon" />
            </IconButton>
          </Tooltip>
          <Tooltip title={manager.phone_number} arrow>
            <IconButton size="small" color="success">
              <WhatsApp sx={{ fontSize: 35 }} className="icon" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Manager details" arrow>
            <IconButton
              size="small"
              color="info"
              onClick={() => handleOpenDialogy()}
            >
              <RemoveRedEye sx={{ fontSize: 35 }} className="icon" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
            textAlign: 'center',
            py: 1,
            zIndex: 12,
            position: 'relative',
          }}
        >
          <Typography
            sx={{
              fontSize: 13,
              fontWeight: 'bold',
              color: colors.primary,
            }}
          >
            {`${manager.first_name} ${manager.last_name}  `}
          </Typography>
          {/* <Typography
            sx={{
              fontSize: 12,
              color: colors.primary,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Inventory sx={{ fontSize: 13, mr: 0.3 }} />
            Kariakoo Branch
          </Typography> */}
        </Box>
      </Box>
    </>
  );
};

export default ManagerWidget;
