import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    GET_SINGLE_BRAND,
    GET_BRAND_PRODUCTS,
    CREATE_BRAND,
    UPDATE_BRAND,
    ACTIVATE_BRAND,
    DEACTIVATE_BRAND,
    DELETE_BRAND,
} from '../endPoints';
import { headers } from '../baseUrl/setHeaders';
import { baseURL } from '../baseUrl/baseURL';

export const brandAPI = createApi({
    reducerPath: 'brandAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        getSingleBrand: builder.query({
            query: (id) => `${GET_SINGLE_BRAND}${id}`,
        }),
        getBrandProducts: builder.query({
            query: (id) => `${GET_BRAND_PRODUCTS}${id}`,
        }),
        createBrand: builder.mutation({
            query: (payload) => ({
                url: `${CREATE_BRAND}`,
                method: 'POST',
                body: payload,
            }),
        }),
        updateBrand: builder.mutation({
            query: (payload) => ({
                url: `${UPDATE_BRAND}/${payload.get('brand_id')}`,
                method: 'POST',
                body: payload,
            }),
        }),
        activateBrand: builder.mutation({
            query: (payload) => ({
                url: `${ACTIVATE_BRAND}/${payload}`,
                method: 'PUT',
            }),
        }),
        deactivateBrand: builder.mutation({
            query: (payload) => ({
                url: `${DEACTIVATE_BRAND}/${payload}`,
                method: 'PUT',
            }),
        }),
        deleteBrand: builder.mutation({
            query: (payload) => ({
                url: `${DELETE_BRAND}/${payload}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetSingleBrandQuery,
    useGetBrandProductsQuery,
    useCreateBrandMutation,
    useUpdateBrandMutation,
    useActivateBrandMutation,
    useDeactivateBrandMutation,
    useDeleteBrandMutation,
} = brandAPI;
