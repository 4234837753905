import React from 'react';
import {  Grid } from '@mui/material';
import InnerPageTopbar from '../../components/InnerPageTopbar';

const DashLayout = ({ sidebar, pageHead, children }) => {
    return (
        <>
            <Grid container padding={2} spacing={2}>
                <Grid item sm={12} xs={12}>
                    {/* ############# Page title ############# */}
                    {pageHead ? (
                        <>
                            <InnerPageTopbar title={pageHead} />
                        </>
                    ) : (
                        <></>
                    )}

                    {/* ############# Content ############# */}
                    {children}
                </Grid>
            </Grid>
        </>
    );
};

export default DashLayout;
