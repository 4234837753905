import { Delete } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import useRTK from '../../hooks/useRTK';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setBranches } from '../../features/branchSlice';
import { useDeleteBranchMutation } from '../../api/branches/branchAPI';

const DeleteBranchAction = ({ branchID }) => {
    // ############### CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // ############### RTK ###############
    const [
        deleteBranch,
        { isLoading, isSuccess, isError, error, data: branchData },
    ] = useDeleteBranchMutation();

    // ############## Resp ###############
    const rtkActions = (data) => {
        navigate('/branches');
        dispatch(setBranches(data));
    };
    RTK.useRTKResponse(
        isSuccess,
        isError,
        error,
        branchData,
        rtkActions,
    );

    return (
        <>
            <Button
                variant="contained"
                size="small"
                className="app-btn"
                startIcon={<Delete sx={{ fontSize: 12 }} />}
                color="error"
                disabled={isLoading ? true : false}
                onClick={() => {
                    deleteBranch(branchID);
                }}
            >
                {isLoading ? <CircularProgress size={22} /> : 'Delete'}
            </Button>
        </>
    );
};

export default DeleteBranchAction;
