import {
    Box,
    CircularProgress,
    IconButton,
    Switch,
    Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../assets/utils/colors';
import { Edit, Remove } from '@mui/icons-material';
import { setRefetchCategories, setRefetchSubCategories, setSubCategories } from '../../features/categorySlice';
import { useDispatch } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import {
    useActivateSubCategoryMutation,
    useDeactivateSubCategoryMutation,
    useDeleteSubCategoryMutation,
} from '../../api/categories/categoryAPI';
import CustomDialogy from '../../components/dialogys/CustomDialogy';
import UpdateSubcategory from './UpdateSubcategory';

const SubCategoriesActions = ({ category }) => {
    // ############### CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp states #############
    const [openDialogy, setOpenDialogy] = React.useState(false);
    const [updateCategory, setUpdateCategory] = useState({});

    // ############# FUNC #############
    const handleOpenDialogy = (category) => {
        setUpdateCategory(category);
        setOpenDialogy(true);
    };
    const handleCloseDialogy = () => {
        setOpenDialogy(false);
    };

    // ############### RTK ###############
    const [
        activateCategory,
        {
            isLoading: activateLoading,
            isSuccess: activateSuccess,
            isError: activateIsError,
            error: activateError,
            data: activateData,
        },
    ] = useActivateSubCategoryMutation();
    const [
        deactivateCategory,
        {
            isLoading: deactivateLoading,
            isSuccess: deactivateSuccess,
            isError: deactivateIsError,
            error: deactivateError,
            data: deactivateData,
        },
    ] = useDeactivateSubCategoryMutation();
    const [
        deleteCategory,
        {
            isLoading: deleteLoading,
            isSuccess: deleteSuccess,
            isError: deleteIsError,
            error: deleteError,
            data: deleteData,
        },
    ] = useDeleteSubCategoryMutation();

    // ############## Resp ###############
    const rtkActions = (data) => {
        dispatch(setSubCategories(data));
        dispatch(setRefetchCategories());
        dispatch(setRefetchSubCategories());
    };
    RTK.useRTKResponse(
        activateSuccess,
        activateIsError,
        activateError,
        activateData,
        rtkActions,
    );
    RTK.useRTKResponse(
        deactivateSuccess,
        deactivateIsError,
        deactivateError,
        deactivateData,
        rtkActions,
    );
    RTK.useRTKResponse(
        deleteSuccess,
        deleteIsError,
        deleteError,
        deleteData,
        rtkActions,
    );

    return (
        <>
            {/* ############### Dialogy ################# */}
            <CustomDialogy
                title={'Update category'}
                open={openDialogy}
                handleCloseDialogy={handleCloseDialogy}
            >
                <UpdateSubcategory
                    closeDialogy={handleCloseDialogy}
                    category={updateCategory}
                />
            </CustomDialogy>

            {/* ################ Main Content ################ */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={category.status ? 'Deactivate' : 'Activate'}>
                    <IconButton
                        size="small"
                        onClick={() => {
                            category.status === 1
                                ? deactivateCategory(category.id)
                                : activateCategory(category.id);
                        }}
                    >
                        {activateLoading || deactivateLoading ? (
                            <CircularProgress size={21} sx={{ mx: 1 }} />
                        ) : (
                            <Switch
                                size="small"
                                checked={category.status ? true : false}
                            />
                        )}
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit category">
                    <IconButton
                        size="small"
                        sx={{
                            'mr': 1,
                            'bgcolor': colors.warning,
                            'height': 25,
                            'width': 25,
                            ':hover': {
                                bgcolor: colors.warning,
                            },
                        }}
                        onClick={() => {
                            handleOpenDialogy(category);
                        }}
                    >
                        <Edit
                            sx={{
                                color: colors.bgColor1,
                                fontSize: 15,
                            }}
                        />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Remove category">
                    <IconButton
                        size="small"
                        disabled={deleteLoading ? true : false}
                        onClick={() => {
                            deleteCategory(category.id);
                        }}
                        sx={{
                            'bgcolor': colors.danger,
                            ':hover': {
                                bgcolor: colors.danger,
                            },
                        }}
                    >
                        {deleteLoading ? (
                            <CircularProgress
                                size={20}
                                sx={{}}
                                color="primary"
                            />
                        ) : (
                            <Remove
                                sx={{
                                    color: colors.bgColor1,
                                    fontSize: 15,
                                }}
                            />
                        )}
                    </IconButton>
                </Tooltip>
            </Box>
        </>
    );
};

export default SubCategoriesActions;
