import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    Switch,
    Typography,
} from '@mui/material';
import { Edit, Inventory, LocationOn } from '@mui/icons-material';
import { isEmpty, forEach } from 'lodash';
import { colors } from '../../assets/utils/colors';
import { numberWithCommas } from '../../utils/helpers';
import UpdateBranch from '../../pages/branches/UpdateBranch';
import CustomCard2 from '../cards/CustomCard2';
import CustomDialogy from '../dialogys/CustomDialogy';

// import RTK query and redux
import {
    useActivateBranchMutation,
    useDeactivateBranchMutation,
    useGetSingleBranchQuery,
} from '../../api/branches/branchAPI';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';
import { errorNotify, successNotify } from '../../helpers/notify';
import DeleteBranchAction from '../../pages/branches/DeleteBranchAction';

// !############### MAIN FUNC ####################
const BranchDetailsSidebar = ({
    productsCount,
    outStockCount,
    inStockProduct,
    branchID,
    refetch,
}) => {
    // ################ CompState ################
    const [branchDetails, setBranchDetails] = useState({});
    const [openDialogy, setOpenDialogy] = React.useState(false);

    // ################ RTK ################
    const {
        isSuccess,
        isError,
        error,
        refetch: branchRefetch,
        data: branchData,
    } = useGetSingleBranchQuery(branchID);
    const [
        activeBranch,
        {
            isLoading: activateLoading,
            isSuccess: activateSuccess,
            isError: activateIsError,
            error: activateError,
            data: activateData,
        },
    ] = useActivateBranchMutation();
    const [
        deactivBranch,
        {
            isLoading: deactivateLoading,
            isSuccess: deactivateSuccess,
            isError: deactivateIsError,
            error: deactivateError,
            data: deactivateData,
        },
    ] = useDeactivateBranchMutation();

    // ################ FUNC ################
    const handleOpenDialogy = () => {
        setOpenDialogy(true);
    };
    const handleCloseDialogy = () => {
        setOpenDialogy(false);
    };
    // => Get total sell
    const getTotalSell = () => {
        let totalSales = 0;
        if (!isEmpty(branchDetails)) {
            const { branch_products } = branchDetails;
            forEach(
                branch_products,
                ({ product_sold, product_price }) =>
                    (totalSales += product_sold * product_price),
            );
        }
        return totalSales;
    };

    // ################ useEffect ################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                setBranchDetails(getRTKResponseData(branchData));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, error, branchData]);
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (activateSuccess) {
                refetch();
                setBranchDetails(getRTKResponseData(activateData));
                successNotify(getRTKSuccessMessage(activateData));
            }
            if (activateIsError) {
                errorNotify(getRTKErrorMessage(activateError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [activateSuccess, activateIsError, activateError, activateData]);
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (deactivateSuccess) {
                refetch();
                setBranchDetails(getRTKResponseData(deactivateData));
                successNotify(getRTKSuccessMessage(deactivateData));
            }
            if (deactivateIsError) {
                errorNotify(getRTKErrorMessage(deactivateError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [
        deactivateSuccess,
        deactivateIsError,
        deactivateError,
        deactivateData,
        refetch,
    ]);

    return (
        <>
            {/* ############### Dialogy ################# */}
            <CustomDialogy
                title={'Update branch details'}
                open={openDialogy}
                handleCloseDialogy={handleCloseDialogy}
            >
                <UpdateBranch
                    branch={branchDetails}
                    branchRefetch={branchRefetch}
                    closeDialog={handleCloseDialogy}
                />
            </CustomDialogy>

            {/* ################ Main Content ################ */}
            <CustomCard2
                title={'Branch details'}
                height={640}
                action={
                    <Box>
                        <Button
                            variant="contained"
                            size="small"
                            className="app-btn"
                            startIcon={<Edit sx={{ fontSize: 12 }} />}
                            color="warning"
                            onClick={() => handleOpenDialogy()}
                            sx={{ mr: 1 }}
                        >
                            Edit
                        </Button>
                        <DeleteBranchAction branchID={branchID} />
                    </Box>
                }
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 40,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor6})`,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            p: 1,
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Inventory
                                sx={{ fontSize: 55, color: colors.primary }}
                            />
                            <Box>
                                <Typography
                                    sx={{
                                        color: colors.primary,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {branchDetails?.branch_name}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 13,
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: colors.primary,
                                    }}
                                >
                                    <LocationOn sx={{ fontSize: 16 }} />
                                    {branchDetails?.branch_location}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            {!isEmpty(branchDetails) && (
                                <Chip
                                    size="small"
                                    sx={{
                                        fontSize: 10,
                                        fontWeight: 'bold',
                                        px: 1,
                                    }}
                                    label={
                                        branchDetails.branch_status
                                            ? 'Active'
                                            : 'In-active'
                                    }
                                    color={
                                        branchDetails.branch_status
                                            ? 'success'
                                            : 'warning'
                                    }
                                />
                            )}

                            <Typography sx={{ textAlign: 'center' }}>
                                {!isEmpty(branchDetails) && (
                                    <IconButton
                                        onClick={() => {
                                            branchDetails?.branch_status
                                                ? deactivBranch(branchID)
                                                : activeBranch(branchID);
                                        }}
                                    >
                                        {activateLoading ||
                                        deactivateLoading ? (
                                            <CircularProgress
                                                size={20}
                                                sx={{ my: 0.25 }}
                                            />
                                        ) : (
                                            <Switch
                                                checked={
                                                    branchDetails?.branch_status
                                                        ? true
                                                        : false
                                                }
                                                size="small"
                                            />
                                        )}
                                    </IconButton>
                                )}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            sx={{
                                fontSize: 35,
                                color: colors.primary,
                                fontWeight: 'bold',
                            }}
                        >
                            {numberWithCommas(getTotalSell())}
                            <sup
                                style={{
                                    fontSize: 14,
                                    color: colors.primary,
                                }}
                            >
                                TZS
                            </sup>
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 14,
                                textAlign: 'center',
                                color: colors.primary,
                            }}
                        >
                            Total Sales
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            p: 2,
                        }}
                    >
                        <Grid container spacing={4}>
                            <Grid item sm={6}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                        p: 0.5,
                                        px: 1,
                                        borderRadius: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            color: colors.primary,
                                            p: 1,
                                            mr: 1,
                                            fontWeight: 'bold',
                                            borderRight: `1px solid ${colors.bgColor3}`,
                                        }}
                                    >
                                        {productsCount}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            color: colors.primary,
                                        }}
                                    >
                                        Products
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                        p: 0.5,
                                        px: 1,
                                        borderRadius: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            color: colors.primary,
                                            fontWeight: 'bold',
                                            p: 1,
                                            mr: 1,
                                            borderRight: `1px solid ${colors.bgColor3}`,
                                        }}
                                    >
                                        50
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            color: colors.primary,
                                        }}
                                    >
                                        Orders
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                        p: 0.5,
                                        px: 1,
                                        borderRadius: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            color: colors.primary,
                                            fontWeight: 'bold',
                                            p: 1,
                                            mr: 1,
                                            borderRight: `1px solid ${colors.bgColor3}`,
                                        }}
                                    >
                                        {inStockProduct}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            color: colors.primary,
                                        }}
                                    >
                                        In stock products
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                        p: 0.5,
                                        px: 1,
                                        borderRadius: 2,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            color: colors.primary,
                                            fontWeight: 'bold',
                                            p: 1,
                                            mr: 1,
                                            borderRight: `1px solid ${colors.bgColor3}`,
                                        }}
                                    >
                                        {outStockCount}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            color: colors.primary,
                                        }}
                                    >
                                        Out stock products
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </CustomCard2>
        </>
    );
};

export default BranchDetailsSidebar;
