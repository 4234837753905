import * as React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Badge, Box, Stack, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '../assets/utils/colors';
import user from '../assets/media/images/user.png';
import { NotificationsActive } from '@mui/icons-material';
import NotificationsBar from './NotificationsBar';

// ################# CONSTANT ###############
const drawerWidth = 250;

// ################# STYLED APPBAR ###############
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    backgroundColor: colors.bgColor1,
}));

// ################# TOPBAR RIGHT ###############
const TopbarRight = styled(Stack)({});

// !###################### MAIN FUNC ############################
const TopBar = ({ mobileOpen, desktopOpen, handleDrawerToggle }) => {
    const [openNotBar, setOpenNotBar] = React.useState(false);

    const toggleNotDrawer = () => {
        setOpenNotBar(true);
    };

    const closeNotDrawer = () => {
        setOpenNotBar(false);
    };
    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    width: {
                        xs: '100%',
                        sm: desktopOpen
                            ? `calc(100% - ${drawerWidth}px)`
                            : `95.8%`,
                    },
                    borderBottom: `1px solid ${colors.bgColor1}`,
                    boxShadow: 'none',
                    background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                }}
            >
                <Toolbar
                    sx={{
                        justifyContent: 'space-between',
                    }}
                >
                    {/* ############### Notificationbar ###############*/}
                    <NotificationsBar
                        openNotBar={openNotBar}
                        closeNotDrawer={closeNotDrawer}
                    />

                    {/* ################### LEFT ################# */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {mobileOpen ? (
                            <IconButton
                                onClick={handleDrawerToggle}
                                color="inherit"
                                sx={{
                                    display: { xs: 'block', sm: 'none' },
                                    marginTop: 1.3,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : (
                            <IconButton
                                onClick={handleDrawerToggle}
                                color="inherit"
                                sx={{
                                    display: { xs: 'block', sm: 'none' },
                                    marginTop: 1.3,
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        <Typography sx={{}}>
                            <span
                                style={{
                                    color: colors.secondary,
                                    fontWeight: 'bold',
                                }}
                            >
                                Hello!
                            </span>{' '}
                            Welcome to{' '}
                            <span
                                style={{
                                    color: colors.secondary,
                                    fontWeight: 'bold',
                                }}
                            >
                                CAICA
                            </span>{' '}
                            admin dashboard
                        </Typography>
                    </Box>

                    {/* ################### RIGHT ################# */}
                    <TopbarRight
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <IconButton onClick={() => toggleNotDrawer()}>
                            <Badge
                                badgeContent={4}
                                color="info"
                                sx={{ fontSize: 9 }}
                            >
                                <NotificationsActive
                                    sx={{ color: colors.white }}
                                />
                            </Badge>
                        </IconButton>
                        <Avatar src={user} />
                    </TopbarRight>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default TopBar;
