import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  products: [],
  onSell: [],
  outOfStock: [],
  promotedProducts: [],
  product: {},
  productCount: 0,
  onSellCount: 0,
  outOfStockCount: 0,
};

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setOnSellProducts: (state, action) => {
      state.onSell = action.payload.onSell;
    },
    setOoutStockProducts: (state, action) => {
      state.outOfStock = action.payload.outOfStock;
    },
    setProductCount: (state, action) => {
      state.productCount = action.payload.productCount;
    },
    setOnSellCount: (state, action) => {
      state.onSellCount = action.payload.onSellCount;
    },
    setOutStockCount: (state, action) => {
      state.outOfStockCount = action.payload.outOfStockCount;
    },
    setPromotedProducts: (state, action) => {
      state.promotedProducts = action.payload.promotedProducts;
    },
  },
});

export const {
  setProducts,
  setProduct,
  setProductCount,
  setOnSellCount,
  setOutStockCount,
  setOnSellProducts,
  setOoutStockProducts,
  setPromotedProducts,
} = productSlice.actions;

export default productSlice.reducer;
