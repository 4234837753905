import React from 'react';
import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomTextField from '../../components/forms/CustomTextField';
import CustomeMultlineForm from '../../components/forms/CustomeMultlineForm';
import { useCreateCategoryMutation } from '../../api/categories/categoryAPI';
import CustomSubmitButton from '../../components/forms/CustomSubmitButton';
import useRTK from '../../hooks/useRTK';
import { useDispatch } from 'react-redux';
import {
    addCategory,
    setRefetchCategories,
} from '../../features/categorySlice';

const createCategoryValidationSchema = Yup.object({
    category_name: Yup.string().required('Category name is required').max(50, "category name must be at most 50 characters"),
    description: Yup.string(),
});

const CreateCategory = ({ closeDialogy }) => {
    // ############### CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK ###############
    const [
        createCategory,
        { isLoading, isSuccess, isError, error, data: categoryData },
    ] = useCreateCategoryMutation();

    // ############## Resp ###############
    const rtkActions = (data) => {
        closeDialogy();
        dispatch(addCategory(data));
        dispatch(setRefetchCategories());
    };
    RTK.useRTKResponse(isSuccess, isError, error, categoryData, rtkActions);

    return (
        <>
            <Formik
                initialValues={{
                    category_name: '',
                    description: '',
                }}
                validationSchema={createCategoryValidationSchema}
                onSubmit={(payload) => {
                    createCategory(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item sm={12}>
                                <CustomTextField
                                    name="category_name"
                                    label={'Category name'}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomeMultlineForm
                                    name="description"
                                    label={'Category description'}
                                    color="primary"
                                    rows={5}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        onClick={formik.handleSubmit}
                                    >
                                        Create category
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CreateCategory;
