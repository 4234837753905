import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { colors } from '../../assets/utils/colors';
import {
    Avatar,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { LocationOn, RemoveRedEye } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { map, size } from 'lodash';
import moment from 'moment';

const columns = [
    {
        id: 's_n',
        label: 'S/N',
        align: 'center',
        fontWeight: 'bold',
        width: 10,
    },
    {
        id: 'name',
        label: 'Customer',
    },
    {
        id: 'created_at',
        label: 'Member since',
    },
    {
        id: 'orders',
        label: 'Total orders',
    },
    {
        id: 'action',
        label: '',
    },
];

function createData(s_n, name, created_at, orders, action) {
    return { s_n, name, created_at, orders, action };
}

export default function CustomerTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const customers = useSelector((state) => state.users.customers);

    const rows = map(customers, (customer, index) =>
        createData(
            index + 1,
            <Box>
                <List
                    sx={{
                        width: '100%',
                        bgcolor: 'transparent',
                        p: 0,
                    }}
                >
                    <ListItem sx={{ p: 0 }}>
                        <ListItemAvatar>
                            <Avatar />
                        </ListItemAvatar>
                        <ListItemText
                            primaryTypographyProps={{
                                fontWeight: 'bold',
                                color: colors.primary,
                            }}
                            primary={`${customer?.first_name}  ${customer?.last_name}`}
                            secondary={
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: 12,
                                        color: colors.primary,
                                    }}
                                >
                                    <LocationOn
                                        sx={{
                                            fontSize: 14,
                                            color: colors.secondary,
                                            mr: 0.3,
                                        }}
                                    />
                                    {customer.location
                                        ? customer.location
                                        : 'unavailable'}
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
            </Box>,
            moment(customer.created_at).format('ll'),
            size(customer?.customer_orders),
            <Tooltip title="Customer details">
                <Link to={`/customers/details/${customer.id}`}>
                    <IconButton size="small" color="info">
                        <RemoveRedEye
                            sx={{
                                bgcolor: colors.bgColor1,
                                borderRadius: 50,
                            }}
                        />
                    </IconButton>
                </Link>
            </Tooltip>,
        ),
    );

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ height: 535, m: 0 }}>
                <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{
                        '& th': {
                            bgcolor: `${colors.bgColor1} !important`,
                        },
                    }}
                >
                    <TableHead>
                        <TableRow
                            sx={{
                                '& th': {
                                    fontWeight: 'bold',
                                },
                            }}
                        >
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    sx={{
                                        color: colors.primary,
                                        textAlign: column.align,
                                        fontWeight: column.fontWeight,
                                        width: column.width,
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage,
                            )
                            .map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.code}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    sx={{
                                                        fontWeight:
                                                            column.fontWeight,
                                                    }}
                                                >
                                                    {column.format &&
                                                    typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                sx={{
                    '&.MuiTablePagination-root': {
                        bgcolor: `${colors.bgColor2} !important`,
                    },
                }}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
