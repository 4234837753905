import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';
import { getInitialNameChar } from '../../helpers/stringHelpers';

const ProductBranchWidget = ({ branch }) => {
    return (
        <>
            <Box
                sx={{
                    p: 1,
                    border: `1px solid ${colors.bgColor2}`,
                    borderRadius: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        borderBottom: '1px solid green',
                        pb: 0.5,
                    }}
                >
                    <Avatar
                        variant="square"
                        sx={{
                            opacity: 0.6,
                            borderRadius: 2,
                            mr: 1,
                            background: `linear-gradient(180deg, ${colors.secondary}, ${colors.primary})`,
                        }}
                        {...getInitialNameChar(branch.branch_name)}
                    />
                    <Box>
                        <Typography
                            sx={{
                                color: colors.primary,
                                fontWeight: 'bold',
                                fontSize: 12,
                            }}
                        >
                            {branch.branch_name}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                display: 'flex',
                                alignItems: 'center',
                                color: colors.primary,
                            }}
                        >
                            {branch.branch_location}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 0.5,
                    }}
                >
                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                        <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                            Quantity
                        </Typography>
                        <Typography sx={{ fontSize: 13 }}>{branch.product_quantity}</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            borderLeft: '1px solid green',
                            borderRight: '1px solid green',
                        }}
                    >
                        <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                            Sold
                        </Typography>
                        <Typography sx={{ fontSize: 13 }}>{branch.product_sold}</Typography>
                    </Box>
                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                        <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                            In-stock
                        </Typography>
                        <Typography sx={{ fontSize: 13 }}>{branch.in_stock}</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ProductBranchWidget;
