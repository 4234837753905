import React from 'react';
import { MoreVert } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/colors';
import { forEach, size } from 'lodash';
import { numberWithCommas } from '../../utils/helpers';
import { getInitialNameChar } from '../../helpers/stringHelpers';
import {
    useActivateBranchMutation,
    useDeactivateBranchMutation,
} from '../../api/branches/branchAPI';
import { setBranchesRefetch } from '../../features/branchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { errorNotify, successNotify } from '../../helpers/notify';
import { getRTKSuccessMessage } from '../../helpers/RTKHelpers';
import { productsSelector } from '../../features/selectors';

const BranchWidget = ({ branch, branchesRefetch }) => {
    // ############### CONST ################
    const dispatch = useDispatch();

    // ############## Redux state #######################
    const { products } = useSelector(productsSelector);

    // ################ RTK #################
    const [
        activateBranch,
        {
            isLoading: activateLoading,
            isSuccess: activateSuccess,
            isError: activateIsError,
            error: activateError,
            data: activatedBranch,
        },
    ] = useActivateBranchMutation();
    const [
        deactivateBranch,
        {
            isLoading: deactivateLoading,
            isSuccess: deactivateSuccess,
            isError: deactivateIsError,
            error: deactivateError,
            data: deactivatedBranch,
        },
    ] = useDeactivateBranchMutation();

    // ################# FUNC ###############
    // => Get in stock
    const getInStock = () => {
        let inStockCount = size(branch.in_stock_products);
        return inStockCount;
    };
    // => Get out of stock
    const getOutOfStock = () => {
        let outStockCount = size(branch.out_stock_products);
        return outStockCount;
    };
    // => Get total sale
    const getTotalSale = () => {
        let totalSales = 0;
        forEach(
            branch.branch_products,
            ({ product_sold, product_price }) =>
                (totalSales += product_sold * product_price),
        );
        return totalSales;
    };

    // ################ UseEffect #################
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (activateSuccess) {
                branchesRefetch();
                successNotify(getRTKSuccessMessage(activatedBranch));
            }
            if (activateIsError) {
                successNotify(getRTKSuccessMessage(activateError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [activateSuccess, activateIsError]);
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (deactivateSuccess) {
                branchesRefetch();
                successNotify(getRTKSuccessMessage(deactivatedBranch));
            }
            if (deactivateIsError) {
                errorNotify(getRTKSuccessMessage(deactivateError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [deactivateSuccess, deactivateIsError]);

    return (
        <>
            <Box
                sx={{
                    'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                    'borderRadius': 3,
                    'p': 1.5,
                    'border': `5px solid ${colors.bgColor1}`,
                    'width': '100%',
                    'transition': '.5s all ease-in-out',
                    'boxShadow': `1px 1px 10px 1px ${colors.bgColor2}`,
                    '&:hover': {
                        border: `5px solid ${colors.bgColor2}`,
                        transition: '.5s all ease-in-out',
                    },
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    <Box
                        sx={{
                            p: 1,
                            mr: 2,
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor6})`,
                        }}
                    >
                        <Avatar
                            variant="square"
                            sx={{
                                opacity: 0.6,
                                borderRadius: 2,
                                background: `linear-gradient(180deg, ${colors.secondary}, ${colors.primary})`,
                            }}
                            {...getInitialNameChar(branch.branch_name)}
                        />
                    </Box>
                    <Typography
                        sx={{
                            'fontSize': 16,
                            'fontWeight': 'bold',
                            'position': 'relative',
                            'width': '100%',
                            'color': colors.primary,
                            'display': 'flex',
                            'justifyContent': 'space-between',
                            '&::before': {
                                content: "''",
                                height: 4,
                                bgcolor: colors.bgColor6,
                                width: '100%',
                                position: 'absolute',
                                bottom: 12,
                            },
                        }}
                    >
                        {branch.branch_name}
                        <Tooltip title="Branch details" placement="right">
                            <Link to={`/branches/details/${branch.id}`}>
                                <MoreVert />
                            </Link>
                        </Tooltip>
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Divider orientation="vertical" flexItem>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        color: colors.primary,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {numberWithCommas(getTotalSale())}
                                    <sup
                                        style={{
                                            fontSize: 11,
                                            color: colors.primary,
                                        }}
                                    >
                                        TZS
                                    </sup>
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 10,
                                        textAlign: 'center',
                                        color: colors.primary,
                                    }}
                                >
                                    Total Sales
                                </Typography>
                            </Box>
                        </Divider>
                        <Box
                            sx={{
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {branch.branch_status ? (
                                <Chip
                                    label="Open"
                                    size="small"
                                    color="success"
                                    sx={{ fontSize: 10 }}
                                />
                            ) : (
                                <Chip
                                    label="Closed"
                                    size="small"
                                    color="error"
                                    sx={{ fontSize: 10 }}
                                />
                            )}
                            {activateLoading || deactivateLoading ? (
                                <CircularProgress size={20} sx={{ ml: 2 }} />
                            ) : (
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        if (branch.branch_status) {
                                            deactivateBranch(branch.id);
                                        }
                                        if (!branch.branch_status) {
                                            activateBranch(branch.id);
                                        }
                                    }}
                                >
                                    <Switch
                                        checked={
                                            branch.branch_status ? true : false
                                        }
                                        size="small"
                                    />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            width: '100%',
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item sm={6}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor5})`,
                                        p: 0.5,
                                        px: 1,
                                        borderRadius: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            color: colors.primary,
                                            p: 1,
                                            fontWeight: 'bold',
                                            borderRight: `1px solid ${colors.bgColor3}`,
                                            mr: 1,
                                        }}
                                    >
                                        {size(products)}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: 'bold',
                                            color: colors.primary,
                                        }}
                                    >
                                        Products
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor5})`,
                                        p: 0.5,
                                        px: 1,
                                        borderRadius: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            color: colors.primary,
                                            fontWeight: 'bold',
                                            p: 1,
                                            borderRight: `1px solid ${colors.bgColor3}`,
                                            mr: 1,
                                        }}
                                    >
                                        50
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: 'bold',
                                            color: colors.primary,
                                        }}
                                    >
                                        Orders
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor5})`,
                                        p: 0.5,
                                        px: 1,
                                        borderRadius: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            p: 1,
                                            borderRight: `1px solid ${colors.bgColor3}`,
                                            mr: 1,
                                            color: 'green',
                                        }}
                                    >
                                        {getInStock()}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: 'bold',
                                            color: colors.primary,
                                        }}
                                    >
                                        In stock
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor5})`,
                                        p: 0.5,
                                        px: 1,
                                        borderRadius: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            p: 1,
                                            borderRight: `1px solid ${colors.bgColor3}`,
                                            mr: 1,
                                            color: 'red',
                                        }}
                                    >
                                        {getOutOfStock()}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: 'bold',
                                            color: colors.primary,
                                        }}
                                    >
                                        Out stock
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default BranchWidget;
