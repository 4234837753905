import React from 'react';
import { ArrowBackSharp } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { colors } from '../assets/utils/colors';

const Pageheader = ({ head }) => {
    const navigate = useNavigate();
    return (
        <>
            <Box
                sx={{
                    height: 40,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 1,
                    borderLeft: `5px solid ${colors.bgColor2}`,
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            color: colors.primary,
                            background: `linear-gradient(90deg, ${colors.secondary}, ${colors.bgColor1})`,
                            p: 1,
                            pr: 5
                        }}
                    >
                        {head}
                    </Typography>
                </Box>
                <Box>
                    <IconButton
                        size="small"
                        sx={{
                            background: `linear-gradient(270deg, ${colors.secondary}, ${colors.bgColor1})`,
                        }}
                        onClick={()=>navigate(-1)}
                    >
                        <ArrowBackSharp />
                    </IconButton>
                </Box>
            </Box>
        </>
    );
};

export default Pageheader;
