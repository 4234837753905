import { Edit, Message, Phone, WhatsApp } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Chip,
    Grid,
    IconButton,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colors } from '../../assets/utils/colors';
import BranchWidget from '../../components/widgets/BranchWidget';
import user from '../../assets/media/images/user3.png';
import CustomDialogy from '../../components/dialogys/CustomDialogy';
import UpdateAdmin from './UpdateAdmin';
import { useGetSingleBranchQuery } from '../../api/branches/branchAPI';
import DataFetchingFeedback from '../../components/feedbacks/DataFetchingFeedback';
import { getRTKResponseData } from '../../helpers/RTKHelpers';

const ManagerDetails = ({ manager }) => {
    // ############## CONST #################

    // ############# Comp state ###############
    const [openDialogy, setOpenDialogy] = useState(false);
    const [branch, setBranch] = useState({});

    // ############## RTK ###################
    const {
        isSuccess,
        isLoading,
        data: branchDetails,
    } = useGetSingleBranchQuery(manager?.branch_id);

    // ############## FUNC ###################
    const handleOpenDialogy = () => {
        setOpenDialogy(true);
    };
    const handleCloseDialogy = () => {
        setOpenDialogy(false);
    };

    // ############## useEffect ##################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                setBranch(getRTKResponseData(branchDetails));
            }
        }

        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, branchDetails]);

    return (
        <>
            {/* ############### Dialogy ################# */}
            <CustomDialogy
                title={'Update manager details'}
                open={openDialogy}
                handleCloseDialogy={handleCloseDialogy}
            >
                <UpdateAdmin />
            </CustomDialogy>

            <Grid container justifyContent={'center'}>
                <Grid item sm={12}>
                    <Box
                        sx={{
                            'borderRadius': 2,
                            'overflow': 'hidden',
                            'position': 'relative',
                            'zIndex': 2,
                            '& .social-icon': {
                                'transition': '.3s all ease-in-out',
                                '& .icon': {
                                    fontSize: 40,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                    p: 1,
                                    borderRadius: 50,
                                },
                            },
                            ':hover': {
                                '& .social-icons': {
                                    bottom: 60,
                                    transition: '.3s all ease-in-out',
                                },
                            },
                        }}
                    >
                        <Box
                            sx={{
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                p: 2,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Avatar
                                src={user}
                                variant="square"
                                sx={{
                                    height: 250,
                                    width: '90%',
                                    borderRadius: 5,
                                    img: {
                                        objectFit: 'contain',
                                    },
                                }}
                            />
                            <Box
                                sx={{
                                    width: 100,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                    position: 'absolute',
                                    right: 0,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    py: 1,
                                    borderRadius: 2,
                                }}
                            >
                                {manager?.isActive ? (
                                    <Chip label="Active" color="success" />
                                ) : (
                                    <Chip label="inActive" color="error" />
                                )}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                textAlign: 'center',
                                py: 1,
                                zIndex: 12,
                                position: 'relative',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 15,
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                }}
                            >
                                {`${manager?.first_name} ${manager?.last_name}`}
                            </Typography>
                        </Box>
                        <Box
                            className="social-icons"
                            sx={{
                                height: 50,
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                bottom: 60,
                                right: 0,
                                left: 0,
                                zIndex: 1,
                                borderRadius: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                            }}
                        >
                            <Tooltip title={manager?.phone_number} arrow>
                                <IconButton size="small" color="primary">
                                    <Phone
                                        sx={{ fontSize: 35 }}
                                        className="icon"
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={manager?.email} arrow>
                                <IconButton size="small" color="info">
                                    <Message
                                        sx={{ fontSize: 35 }}
                                        className="icon"
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={manager?.phone_number} arrow>
                                <IconButton size="small" color="success">
                                    <WhatsApp
                                        sx={{ fontSize: 35 }}
                                        className="icon"
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Activate/Deactivate manager" arrow>
                                {manager?.isActive ? (
                                    <Switch size="small" defaultChecked />
                                ) : (
                                    <Switch
                                        size="small"
                                        defaultChecked={false}
                                    />
                                )}
                            </Tooltip>
                            <Tooltip title="Edit manager details" arrow>
                                <IconButton
                                    size="small"
                                    color="warning"
                                    onClick={() => handleOpenDialogy()}
                                >
                                    <Edit
                                        sx={{ fontSize: 25 }}
                                        className="icon"
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        {isLoading ? (
                            <DataFetchingFeedback />
                        ) : (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        p: 1,
                                        color: colors.primary,
                                    }}
                                >
                                    Manager branch
                                </Typography>
                                <BranchWidget branch={branch} />
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default ManagerDetails;
