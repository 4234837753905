import { useEffect } from 'react';
import { errorNotify, successNotify } from '../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../helpers/RTKHelpers';
import { isEmpty } from 'lodash';

const useRTK = () => {
    const useRTKResponse = (
        isSuccess,
        isError,
        error,
        data,
        actions,
        // refetch,
    ) => {
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isSuccess) {
                    actions && actions(getRTKResponseData(data));
                    successNotify(getRTKSuccessMessage(data));
                }
                if (isError) {
                    errorNotify(
                        !isEmpty(error)
                            ? getRTKErrorMessage(error)
                            : 'Some error occur. Please try again!',
                    );
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [isSuccess, isError, error, data]);
    };

    return {
        useRTKResponse,
    };
};

export default useRTK;
