import React, { useEffect, useState } from 'react';
import { Box, Chip, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { filter, isEmpty, size } from 'lodash';
import CustomCard2 from '../../components/cards/CustomCard2';
import ProductsSidebar from '../../components/sidebars/ProductsSidebar';
import PageLayout from '../layouts/PageLayout';
import PropTypes from 'prop-types';
import { colors } from '../../assets/utils/colors';
import ProductWidget from '../../components/widgets/ProductWidget';

// RTK query imports and redux
import NoDataFeedback from '../../components/feedbacks/NoDataFeedback';
import { productsSelector } from '../../features/selectors';
import CountBadge from '../../components/CountBadge';
import { useGetProductsQuery } from '../../api/products/productAPI';
import { setProducts } from '../../features/productSlice';
import { getRTKResponseData } from '../../helpers/RTKHelpers';
import DataFetchingFeedback from '../../components/feedbacks/DataFetchingFeedback';
import { paginator } from '../../helpers/paginationHelper';
import CustomPagination from '../../components/paginations/CustomPagination';
import ProductFilter from '../../components/filters/ProductFilter';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        'id': `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// !############### MAIN FUNC ####################
const Products = () => {
    // ############## Comp state ###################
    const [value, setValue] = React.useState(0);
    const [products, setAllProducts] = useState([]);
    const [onSellProducts, setOnSellProducts] = useState([]);
    const [deactivatedProducts, setDeactivatedProducts] = useState([]);
    const [inStockProducts, setInStockProducts] = useState([]);
    const [outOfStockProducts, setOutOfStockProducts] = useState([]);
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);

    // ############## CONST #######################
    const dispatch = useDispatch();

    // ############## Redux state #######################
    const { products: allProducts } = useSelector(productsSelector);

    // ############## RTK ####################
    const {
        isLoading: productsLoading,
        isSuccess: productSuccess,
        data: productsData,
        refetch: productRefetch,
    } = useGetProductsQuery();

    // ############## FUNC #######################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Handle change
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // => Get on-sell products
    const getOnSellProducts = () => {
        const onSellProducts = filter(
            products,
            ({ product_details }) => product_details.isOnSale === 1,
        );
        setOnSellProducts(onSellProducts);
    };

     // => Get deactivated products
     const getDeactivatedProducts = () => {
        const deactivatedProducts = filter(
            products,
            ({ product_details }) => product_details.isActive === 0,
        );
        setDeactivatedProducts(deactivatedProducts);
    };

    // => Get out of stock products
    const getOutOfStockProducts = () => {
        const outOfStockProducts = filter(
            products,
            ({ product_details }) =>
                product_details.product_quantity -
                    product_details.product_sold ===
                0,
        );
        setOutOfStockProducts(outOfStockProducts);
    };

    // => Get in stock products
    const getInStockProducts = () => {
        const outOfStockProducts = filter(
            products,
            ({ product_details }) =>
                product_details.product_quantity -
                    product_details.product_sold !==
                0,
        );
        setInStockProducts(outOfStockProducts);
    };

    // ############# Paginations #####################
    const paginatedProducts = paginator(products, page, perPage);
    const paginatedOnSellProducts = paginator(onSellProducts, page, perPage);
    const paginatedDeactivatedProducts = paginator(deactivatedProducts, page, perPage);
    const paginatedInStockProducts = paginator(inStockProducts, page, perPage);
    const paginatedOutStockProducts = paginator(
        outOfStockProducts,
        page,
        perPage,
    );

    // ############## Filter ##################
    // => Filter by product name
    const filterByProductName = (search) => {
        let filteredProducts = [];
        if (search) {
            filteredProducts = allProducts.filter((product) => {
                return product.product_details.product_name
                    .toLowerCase()
                    .includes(search.toLowerCase());
            });
            return setAllProducts(filteredProducts);
        }
        return setAllProducts(products);
    };

    // ############## UseEffect #######################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getOnSellProducts();
            getInStockProducts();
            getOutOfStockProducts();
            getDeactivatedProducts();
        }
        return () => {
            isSubscribed = false;
        };
    }, [products]);
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            productRefetch();
            if (productSuccess) {
                setAllProducts(getRTKResponseData(productsData));
                dispatch(setProducts(getRTKResponseData(productsData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [productSuccess, productsData, productRefetch, dispatch]);

    return (
        <>
            <PageLayout
                sidebar={<ProductsSidebar />}
                head="Products managements"
            >
                <CustomCard2
                    height={productsLoading ? '81vh' : '100%'}
                    title={'CAICA products'}
                    padding={0}
                    action={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ProductFilter
                                filterByProductName={filterByProductName}
                            />
                            <Box sx={{ ml: 2 }}>
                                <CountBadge
                                    total={size(products)}
                                    item={'Products'}
                                />
                            </Box>
                        </Box>
                    }
                >
                    {productsLoading ? (
                        <>
                            <DataFetchingFeedback />
                        </>
                    ) : (
                        <Box sx={{ width: '100%' }}>
                            <Box
                                sx={{
                                    borderBottom: 1,
                                    borderColor: 'divider',
                                    position: 'sticky',
                                    top: 0,
                                    background: `linear-gradient(180deg, ${colors.white}, ${colors.white})`,
                                    zIndex: 999,
                                }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                >
                                    <Tab
                                        label={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        textTransform:
                                                            'capitalize',
                                                        fontWeight: 'bold',
                                                        mr: 1,
                                                    }}
                                                >
                                                    All
                                                </Typography>
                                                <Chip
                                                    sx={{ fontSize: 10 }}
                                                    label={size(products)}
                                                    color="primary"
                                                    size="small"
                                                />
                                            </Box>
                                        }
                                        {...a11yProps(0)}
                                    />

                                    <Tab
                                        label={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        textTransform:
                                                            'capitalize',
                                                        fontWeight: 'bold',
                                                        mr: 1,
                                                    }}
                                                >
                                                    In-stock
                                                </Typography>
                                                <Chip
                                                    sx={{ fontSize: 10 }}
                                                    label={size(
                                                        inStockProducts,
                                                    )}
                                                    color="info"
                                                    size="small"
                                                />
                                            </Box>
                                        }
                                        {...a11yProps(1)}
                                    />
                                    <Tab
                                        label={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        textTransform:
                                                            'capitalize',
                                                        fontWeight: 'bold',
                                                        mr: 1,
                                                    }}
                                                >
                                                    Out-stock
                                                </Typography>
                                                <Chip
                                                    sx={{ fontSize: 10 }}
                                                    label={size(
                                                        outOfStockProducts,
                                                    )}
                                                    color="error"
                                                    size="small"
                                                />
                                            </Box>
                                        }
                                        {...a11yProps(2)}
                                    />
                                    <Tab
                                        label={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        textTransform:
                                                            'capitalize',
                                                        fontWeight: 'bold',
                                                        mr: 1,
                                                    }}
                                                >
                                                    On-sell
                                                </Typography>
                                                <Chip
                                                    sx={{ fontSize: 10 }}
                                                    label={size(onSellProducts)}
                                                    color="warning"
                                                    size="small"
                                                />
                                            </Box>
                                        }
                                        {...a11yProps(3)}
                                    />
                                    <Tab
                                        label={
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        textTransform:
                                                            'capitalize',
                                                        fontWeight: 'bold',
                                                        mr: 1,
                                                    }}
                                                >
                                                    Deactivated
                                                </Typography>
                                                <Chip
                                                    sx={{ fontSize: 10 }}
                                                    label={size(deactivatedProducts)}
                                                    color="warning"
                                                    size="small"
                                                />
                                            </Box>
                                        }
                                        {...a11yProps(4)}
                                    />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <CustomPagination
                                    height={'60vh'}
                                    data={paginatedProducts}
                                    handlePageChange={handlePageChange}
                                    handlePerPageChage={handlePerPageChage}
                                >
                                    <Grid container spacing={2} padding={2}>
                                        {isEmpty(products) ? (
                                            <>
                                                <NoDataFeedback message="No product found" />
                                            </>
                                        ) : (
                                            paginatedProducts.data?.map(
                                                (product, index) => (
                                                    <Grid
                                                        item
                                                        sm={3}
                                                        key={index}
                                                    >
                                                        <ProductWidget
                                                            product={
                                                                product.product_details
                                                            }
                                                        />
                                                    </Grid>
                                                ),
                                            )
                                        )}
                                    </Grid>
                                </CustomPagination>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <CustomPagination
                                    height={'60vh'}
                                    data={paginatedInStockProducts}
                                    handlePageChange={handlePageChange}
                                    handlePerPageChage={handlePerPageChage}
                                >
                                    <Grid container spacing={2} padding={2}>
                                        {isEmpty(
                                            paginatedInStockProducts.data,
                                        ) ? (
                                            <>
                                                <NoDataFeedback message="No product found" />
                                            </>
                                        ) : (
                                            paginatedInStockProducts.data?.map(
                                                (product, index) => (
                                                    <Grid
                                                        item
                                                        sm={3}
                                                        key={index}
                                                    >
                                                        <ProductWidget
                                                            product={
                                                                product.product_details
                                                            }
                                                        />
                                                    </Grid>
                                                ),
                                            )
                                        )}
                                    </Grid>
                                </CustomPagination>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <CustomPagination
                                    height={'60vh'}
                                    data={paginatedOutStockProducts}
                                    handlePageChange={handlePageChange}
                                    handlePerPageChage={handlePerPageChage}
                                >
                                    <Grid container spacing={2} padding={2}>
                                        {isEmpty(
                                            paginatedOutStockProducts.data,
                                        ) ? (
                                            <>
                                                <NoDataFeedback message="No product found" />
                                            </>
                                        ) : (
                                            paginatedOutStockProducts.data?.map(
                                                (product, index) => (
                                                    <Grid
                                                        item
                                                        sm={3}
                                                        key={index}
                                                    >
                                                        <ProductWidget
                                                            product={
                                                                product.product_details
                                                            }
                                                        />
                                                    </Grid>
                                                ),
                                            )
                                        )}
                                    </Grid>
                                </CustomPagination>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <CustomPagination
                                    height={'60vh'}
                                    data={paginatedOnSellProducts}
                                    handlePageChange={handlePageChange}
                                    handlePerPageChage={handlePerPageChage}
                                >
                                    <Grid container spacing={2} padding={2}>
                                        {isEmpty(
                                            paginatedOnSellProducts.data,
                                        ) ? (
                                            <>
                                                <NoDataFeedback message="No product found" />
                                            </>
                                        ) : (
                                            paginatedOnSellProducts.data?.map(
                                                (product, index) => (
                                                    <Grid
                                                        item
                                                        sm={3}
                                                        key={index}
                                                    >
                                                        <ProductWidget
                                                            product={
                                                                product.product_details
                                                            }
                                                        />
                                                    </Grid>
                                                ),
                                            )
                                        )}
                                    </Grid>
                                </CustomPagination>
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <CustomPagination
                                    height={'60vh'}
                                    data={paginatedDeactivatedProducts}
                                    handlePageChange={handlePageChange}
                                    handlePerPageChage={handlePerPageChage}
                                >
                                    <Grid container spacing={2} padding={2}>
                                        {isEmpty(
                                            paginatedDeactivatedProducts.data,
                                        ) ? (
                                            <>
                                                <NoDataFeedback message="No product found" />
                                            </>
                                        ) : (
                                            paginatedDeactivatedProducts.data?.map(
                                                (product, index) => (
                                                    <Grid
                                                        item
                                                        sm={3}
                                                        key={index}
                                                    >
                                                        <ProductWidget
                                                            product={
                                                                product.product_details
                                                            }
                                                        />
                                                    </Grid>
                                                ),
                                            )
                                        )}
                                    </Grid>
                                </CustomPagination>
                            </TabPanel>
                        </Box>
                    )}
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default Products;
