import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import { colors } from '../assets/utils/colors';
import {
    Avatar,
    Box,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
} from '@mui/material';
import {
    AdminPanelSettings,
    BrandingWatermark,
    Category,
    Equalizer,
    FeaturedPlayList,
    Group,
    Home,
    Inventory,
    Logout,
    PersonalVideo,
    Settings,
    ShoppingCart,
} from '@mui/icons-material';
import SidebarListIitem from './Lists/SidebarListIitem';
import AppName from './AppName';
import user from '../assets/media/images/user.png';
import useAuth from '../hooks/useAuth';
import CustomSubmitButton from './forms/CustomSubmitButton';
import { useSelector } from 'react-redux';
import { useLogoutMutation } from '../api/auth/authAPI';
import { errorNotify, successNotify } from '../helpers/notify';

// ############### CONSTANTS #################
const drawerWidth = 250;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

// ############### STYLED DRAWER HEADER #################
const DrawerToggleBtn = styled(Box)(({ theme }) => ({
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'padding': theme.spacing(1, 1),
    '& .icon': {
        'padding': 1,
        'backgroundColor': colors.secondary,
        'color': colors.primary,
        'fontWeight': 'bold',
        '&:hover': {
            backgroundColor: colors.secondary,
            color: colors.primary,
            fontWeight: 'bold',
        },
    },
}));

// ############### STYLED DESKTOP DRAWER #################
const DrawerDesktop = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    'width': drawerWidth,
    'flexShrink': 0,
    'whiteSpace': 'nowrap',
    'boxSizing': 'border-box',
    '& .MuiPaper-root': {
        backgroundColor: colors.bgColor1,
        border: 'none !important',
    },
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

// ################# DrawerContent ################
const DrawerContent = styled(Box)(({ theme }) => ({
    // 'background': colors.secondary,
    'height': '100vh',
    'display': 'flex',
    'flexDirection': 'column',
    // 'justifyContent': 'space-between',
    'paddingTop': 20,
    'paddingBottom': 20,
    'paddingLeft': 15,
    'paddingRight': 15,
    'transition': '.3s all ease-in-out',
    '& .list-title': {
        fontWeight: 'bold',
        fontSize: 12,
    },
    '& .list-icon': {
        fontSize: 18,
        color: colors.primary,
    },
}));

//! #################### MAIN FUNC ######################
const SideBar = ({
    handleDrawerToggle,
    mobileOpen,
    desktopOpen,
    handleDrawerOpen,
    handleDrawerClose,
}) => {
    // *############# CONST #############
    const auth = useAuth();
    const theme = useTheme();
    const [isLogout, setIsLogout] = React.useState(false);

    const { window } = 'props';
    const container =
        window !== undefined ? () => window().document.body : undefined;

    // ################# Links ########################
    const navLinks = [
        {
            id: 1,
            name: 'Dashboard',
            icon: <Home className="list-icon" />,
            url: '/dashboard',
            tooltip: 'Dashboard',
        },
        {
            id: 4,
            name: 'Orders',
            icon: <ShoppingCart className="list-icon" />,
            url: '/orders',
            tooltip: 'Orders',
        },
        {
            id: 2,
            name: 'Branches',
            icon: <Inventory className="list-icon" />,
            url: '/branches',
            tooltip: 'Branches',
        },
        {
            id: 3,
            name: 'Products',
            icon: <Category className="list-icon" />,
            url: '/products',
            tooltip: 'Products',
        },
        {
            id: 5,
            name: 'Promotions',
            icon: <PersonalVideo className="list-icon" />,
            url: '/promotions',
            tooltip: 'Promotions',
        },
        {
            id: 6,
            name: 'Brands',
            icon: <BrandingWatermark className="list-icon" />,
            url: '/brands',
            tooltip: 'Brands',
        },
        {
            id: 7,
            name: 'Categories',
            icon: <FeaturedPlayList className="list-icon" />,
            url: '/categories',
            tooltip: 'Categories',
        },
        {
            id: 8,
            name: 'Customers',
            icon: <Group className="list-icon" />,
            url: '/customers',
            tooltip: 'Customers',
        },
        // {
        //     id: 9,
        //     name: 'Mangers',
        //     icon: <AdminPanelSettings className="list-icon" />,
        //     url: '/mangers',
        //     tooltip: 'Mangers',
        // },
    ];

    const [logoutUser, { isSuccess, isLoading, isError }] = useLogoutMutation();

    // => Handle logout
    const handleLogout = () => {
        logoutUser();
    };
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                auth.removeAuthenticatedUser();
                setTimeout(() => {
                    successNotify('Logout successfully');
                }, 500);
            }
            if (isError) {
                auth.removeAuthenticatedUser();
                setTimeout(() => {
                    successNotify('Logout successfully');
                }, 500);
                // errorNotify('Fail to logout. Try again!');
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError]);

    // ################## RTK ######################
    const user = useSelector((state) => state.auth.user);

    // ################# DRAWER CONTENT ################
    const drawer = (
        <DrawerContent sx={{ p: 1 }}>
            <AppName desktopOpen={desktopOpen} />
            <Box sx={{ p: 1, mt: 2 }}>
                <List sx={{ color: colors.primary }}>
                    {navLinks.map((item) => (
                        <SidebarListIitem
                            key={item.id}
                            name={item.name}
                            icon={item.icon}
                            desktopOpen={desktopOpen}
                            tooltip={item.tooltip}
                            url={item.url}
                        />
                    ))}
                </List>
            </Box>
            <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                <Box
                    sx={{
                        height: 150,
                        display: 'flex',
                        alignItems: 'center',
                        p: desktopOpen ? 2 : 1,
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        borderTopLeftRadius: desktopOpen ? 35 : 15,
                        borderTopRightRadius: desktopOpen ? 35 : 15,
                        background: `linear-gradient(180deg, ${colors.secondary}, ${colors.primary})`,
                    }}
                >
                    {desktopOpen ? (
                        <>
                            <List
                                sx={{
                                    width: '100%',
                                    p: 0,
                                    borderRadius: 2,
                                    borderBottom: `1px solid ${colors.secondary}`,
                                }}
                            >
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar src={''} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            color: colors.white,
                                        }}
                                        secondaryTypographyProps={{
                                            fontSize: 10,
                                            color: colors.white,
                                        }}
                                        primary={`${user.first_name} ${user.last_name}`}
                                        secondary="CAICA Super Admin"
                                    />
                                </ListItem>
                            </List>
                        </>
                    ) : (
                        <>
                            <Avatar src={''} />
                        </>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: desktopOpen ? 'row' : 'column',
                        }}
                    >
                        <Tooltip title={'Settings'} placement="right" arrow>
                            <Button
                                variant="text"
                                fullWidth
                                sx={{
                                    borderRadius: 0,
                                    color: colors.white,
                                    textTransform: 'capitalize',
                                }}
                            >
                                {desktopOpen ? (
                                    <>
                                        <Settings sx={{ mr: 2 }} />
                                        Settings
                                    </>
                                ) : (
                                    <>
                                        <Settings />
                                    </>
                                )}
                            </Button>
                        </Tooltip>
                        <CustomSubmitButton
                            loadingColor={'secondary'}
                            btnColor={'error'}
                            loading={isLoading}
                            onClick={handleLogout}
                        >
                            <Logout />
                        </CustomSubmitButton>
                    </Box>
                </Box>
            </Box>
        </DrawerContent>
    );
    return (
        <>
            {/* ##################### mobile ################# */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    'display': { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: '70%', // Change the width of drawer in mobile
                        backgroundColor: colors.primary,
                    },
                }}
            >
                <Toolbar />
                {drawer}
            </Drawer>

            {/* ##################### desktop ################ */}
            <DrawerDesktop
                variant="permanent"
                open={desktopOpen}
                sx={{
                    display: { xs: 'none', sm: 'block' },
                }}
            >
                {/* <AppBarHeight /> */}
                {/* ========== Drawer desk toggle button ======== */}
                <DrawerToggleBtn
                    sx={{
                        position: 'absolute',
                        top: 60,
                        zIndex: 1098,
                        right: 5,
                    }}
                >
                    {desktopOpen ? (
                        <Tooltip title={'Close'} placement="right" arrow>
                            <IconButton
                                className="icon"
                                onClick={handleDrawerClose}
                                sx={{
                                    '&.icon': {
                                        'color': colors.primary,
                                        'bgcolor': 'transparent',
                                        'transform': `rotate(90deg)`,
                                        '&:hover': {
                                            bgcolor: 'transparent',
                                        },
                                    },
                                }}
                            >
                                {theme.direction === 'rtl' ? (
                                    <Equalizer />
                                ) : (
                                    <Equalizer />
                                )}
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title={'Open'} placement="right" arrow>
                            <IconButton
                                className="icon"
                                onClick={handleDrawerOpen}
                                sx={{
                                    '&.icon': {
                                        'color': colors.primary,
                                        'bgcolor': 'transparent',
                                        'transform': `rotate(90deg)`,
                                        '&:hover': {
                                            bgcolor: 'transparent',
                                        },
                                    },
                                }}
                            >
                                {theme.direction === 'rtl' ? (
                                    <Equalizer />
                                ) : (
                                    <Equalizer />
                                )}
                            </IconButton>
                        </Tooltip>
                    )}
                </DrawerToggleBtn>
                <Divider />
                {drawer}
            </DrawerDesktop>
        </>
    );
};

export default SideBar;
