import React from 'react';
import { PlaylistAddCheck } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';

const ProductDetailInfobox = ({ detail, value, color }) => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                <Box sx={{ p: 1 }}>
                    <PlaylistAddCheck
                        sx={{
                            color: colors.primary,
                        }}
                    />
                </Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: color ? color : colors.primary,
                        }}
                    >
                        {detail}
                    </Typography>
                    <Box
                        sx={{
                            fontSize: 12,
                        }}
                    >
                        {value}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ProductDetailInfobox;
