import React from 'react';
import { Box, Chip, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import { size } from 'lodash';
import PromotionActivateDeactivate from '../../pages/promotions/PromotionActivateDeactivate';

const PromotionWidget2 = ({ promotion, refetch }) => {
    return (
        <>
            <Box
                sx={{
                    overflow: 'hidden',
                    border: `1px solid ${colors.bgColor1}`,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    transition: '.2s all ease-in-out',
                    boxShadow: `2px 2px 10px 4px ${colors.bgColor3}`,
                }}
            >
                <Box>
                    <img
                        src={promotion.promotion_image}
                        alt=""
                        style={{ width: '100%', height: 150 }}
                    />
                </Box>
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                        p: 1.5,
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            color: colors.primary,
                            fontSize: 13,
                        }}
                    >
                        {promotion.promotion_name}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 0.5,
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                            }}
                        >
                            Products
                        </Typography>
                        <Typography sx={{ fontSize: 13 }}>
                            {size(promotion.promotion_products)}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            borderLeft: '1px solid green',
                            borderRight: '1px solid green',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Chip
                            size="small"
                            color={promotion.status ? 'success' : 'warning'}
                            label={promotion.status ? 'active' : 'in-active'}
                        />
                        <PromotionActivateDeactivate
                            promotion={promotion}
                            refetch={refetch}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default PromotionWidget2;
