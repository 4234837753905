import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { colors } from '../../assets/utils/colors';
import { Box, Chip } from '@mui/material';
import { isEmpty, map } from 'lodash';
import SubCategoriesActions from '../../pages/categories/SubCategoriesActions';
import NoDataFeedback from '../feedbacks/NoDataFeedback';

const columns = [
    {
        id: 's_n',
        label: 'S/N',
        align: 'center',
        fontWeight: 'bold',
        width: 10,
    },
    {
        id: 'name',
        label: 'Sub category',
        width: '50%',
    },
    {
        id: 'discretpion',
        label: 'Discreption',
        width: '50%',
    },
    {
        id: 'status',
        label: 'Status',
        align: 'center',
    },
    {
        id: 'action',
        label: 'Actions',
        align: 'center',
    },
];

function createData(s_n, name, discretpion, status, action) {
    return { s_n, name, discretpion, action, status };
}

export default function SubCategoriesTable({ sub_categories }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = map(sub_categories, (category, index) =>
        createData(
            index + 1,
            category.sub_category_name,
            category.description || 'unavailable',
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {category.status ? (
                    <Chip
                        label="active"
                        size="small"
                        color="success"
                        sx={{ fontSize: 10 }}
                    />
                ) : (
                    <Chip
                        label="inactive"
                        size="small"
                        color="error"
                        sx={{ fontSize: 10 }}
                    />
                )}
            </Box>,
            <SubCategoriesActions category={category} />,
        ),
    );

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', position: 'relative' }}>
            <TableContainer sx={{ height: 485, m: 0 }}>
                {isEmpty(sub_categories) ? (
                    <NoDataFeedback message={'No sub-category added.'} />
                ) : (
                    <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                            '& th': {
                                bgcolor: `${colors.bgColor1} !important`,
                            },
                        }}
                    >
                        <TableHead>
                            <TableRow
                                sx={{
                                    '& th': {
                                        fontWeight: 'bold !important',
                                        whiteSpace: 'nowrap',
                                    },
                                }}
                            >
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        sx={{
                                            color: colors.primary,
                                            textAlign: column.align,
                                            fontWeight: column.fontWeight,
                                            width: column.width,
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.code}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        sx={{
                                                            fontWeight:
                                                                column.fontWeight,
                                                        }}
                                                    >
                                                        {column.format &&
                                                        typeof value ===
                                                            'number'
                                                            ? column.format(
                                                                  value,
                                                              )
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            <TablePagination
                sx={{
                    '&.MuiTablePagination-root': {
                        bgcolor: `${colors.bgColor2} !important`,
                    },
                }}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
