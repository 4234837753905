import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';

// RTK query and redux imports
import { useDeleteProductSizeMutation } from '../../api/products/productAPI';
import { numberWithCommas } from '../../utils/helpers';
import { errorNotify, successNotify } from '../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';

const ProductSizeWidget = ({ productSize, product_price, refetch }) => {
    // ################ RTK #####################
    const [
        deleteSize,
        { isSuccess, isError, error, isLoading, data },
    ] = useDeleteProductSizeMutation();

    // ################## FUNC ###################
    const deleteProductSize = () => {
        deleteSize(productSize.size_id);
    };

    // ################## use Effect ##################
    useEffect(() => {
        let isSubscribe = true;
        if (isSubscribe) {
            if (isSuccess) {
                refetch();
                successNotify(getRTKSuccessMessage(data));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribe = false;
        };
    }, [isSuccess, isError, error]);

    return (
        <>
            <Box
                sx={{
                    textAlign: 'center',
                    border: `1px solid ${colors.bgColor3}`,
                    p: 1,
                    borderRadius: 2,
                    m: 1,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        borderBottom: `1px solid ${colors.bgColor3}`,
                    }}
                >
                    {productSize.size_name}
                </Typography>
                <Typography
                    sx={{
                        fontSize: 12,
                    }}
                >
                    {productSize.size_price
                        ? numberWithCommas(productSize.size_price)
                        : numberWithCommas(product_price)}
                    <sup style={{ fontSize: 9 }}>TZS</sup>
                </Typography>
                <Button
                    variant="text"
                    color="error"
                    onClick={() => deleteProductSize()}
                    disabled={isLoading ? true : false}
                    sx={{
                        textTransform: 'capitalize',
                        fontSize: 9,
                        fontWeight: 'bold',
                    }}
                >
                    {isLoading ? <CircularProgress size={10} /> : 'remove'}
                </Button>
            </Box>
        </>
    );
};

export default ProductSizeWidget;
