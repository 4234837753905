import React from 'react';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { branchesSelector } from '../../features/selectors';
import CustomSelectForm from '../../components/forms/CustomSelectForm';
import { map, size } from 'lodash';
import useRTK from '../../hooks/useRTK';
import { useShipOrderMutation } from '../../api/orders/orderAPI';
import CustomSubmitButton from '../../components/forms/CustomSubmitButton';
import { setOrder } from '../../features/orderSlice';

const orderShippingValidationSchema = Yup.object({
    branch_id: Yup.string().required('Branch is required'),
});
const OrderShipping = ({ orderID, closeDialogy, refetchOrder }) => {
    // ############# Redux state ##############
    const { branches } = useSelector(branchesSelector);

    // ############### CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK ###############
    const [
        shipOrder,
        { isLoading, isSuccess, isError, error, data: promotionData },
    ] = useShipOrderMutation();

    // ############## Resp ###############
    const rtkActions = (data) => {
        closeDialogy();
        refetchOrder();
        dispatch(setOrder(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, promotionData, rtkActions);

    return (
        <>
            <Formik
                initialValues={{
                    branch_id: '',
                    order_id: orderID,
                }}
                validationSchema={orderShippingValidationSchema}
                onSubmit={(payload) => {
                    shipOrder(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item sm={12}>
                                <Box>
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: 14,
                                        }}
                                    >
                                        Please select the branch where the
                                        ordered products will be picked up.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={12}>
                                <CustomSelectForm
                                    name="branch_id"
                                    formikName="branch_id"
                                    label="Branch"
                                    formik={formik}
                                >
                                    {size(branches) > 0 ? (
                                        map(branches, (branch) => (
                                            <MenuItem
                                                key={branch.id}
                                                value={branch.id}
                                            >
                                                {branch.branch_name}{' '}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value={null}>
                                            No category
                                        </MenuItem>
                                    )}
                                </CustomSelectForm>
                            </Grid>
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        onClick={formik.handleSubmit}
                                    >
                                        Ship order
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default OrderShipping;
