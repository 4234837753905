import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { CircularProgress, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import { errorNotify, successNotify } from '../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';
import { useNavigate } from 'react-router-dom';

const ConfirmationDialog = ({
    open,
    cancel,
    title,
    message,
    action,
    loading,
    success,
    error,
    data,
    navigateURL,
}) => {
    // ############## CONST ################
    const navigate = useNavigate();

    // ############## useEffect #############
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (success) {
                cancel();
                navigate(navigateURL);
                successNotify(getRTKSuccessMessage(data));
            }
            if (error) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [success, error]);

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <Dialog
                sx={{
                    '& .MuiDialog-paper': {
                        width: '80%',
                        maxHeight: 435,
                        borderRadius: 4,
                        bgcolor: '#fff5f5',
                    },
                }}
                maxWidth="xs"
                open={open}
            >
                <DialogTitle
                    sx={{
                        textAlign: 'center',
                        fontSize: 16,
                        bgcolor: '#ffcccc',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        color: colors.primary,
                        opacity: 0.8,
                    }}
                >
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography sx={{ textAlign: 'center', fontSize: 14 }}>
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={cancel}>
                        Cancel
                    </Button>
                    {loading ? (
                        <CircularProgress size={20} sx={{ mx: 2 }} />
                    ) : (
                        <Button onClick={action}>Ok</Button>
                    )}
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ConfirmationDialog;
