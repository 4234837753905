import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    CREATE_BRANCH,
    GET_SINGLE_BRANCH,
    GET_BRANCH_PRODUCTS,
    ACTIVATE_BRANCH,
    DEACTIVATE_BRANCH,
    UPDATE_BRANCH,
    DELETE_BRANCH,
} from '../endPoints';
import { baseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';

export const branchAPI = createApi({
    reducerPath: 'branchAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        prepareHeaders: headers,
    }),
    endpoints: (builder) => ({
        getBranchProducts: builder.query({
            query: (branch_id) => `${GET_BRANCH_PRODUCTS}${branch_id}`,
        }),
        getSingleBranch: builder.query({
            query: (branch_id) => `${GET_SINGLE_BRANCH}${branch_id}`,
        }),
        addBranch: builder.mutation({
            query: (payload) => ({
                url: `${CREATE_BRANCH}`,
                method: 'POST',
                body: payload,
            }),
        }),
        updateBranch: builder.mutation({
            query: (payload) => ({
                url: `${UPDATE_BRANCH}/${payload.branch_id}`,
                method: 'PUT',
                body: payload,
            }),
        }),
        activateBranch: builder.mutation({
            query: (id) => ({
                url: `${ACTIVATE_BRANCH}/${id}`,
                method: 'PUT',
            }),
        }),
        deactivateBranch: builder.mutation({
            query: (id) => ({
                url: `${DEACTIVATE_BRANCH}/${id}`,
                method: 'PUT',
            }),
        }),
        deleteBranch: builder.mutation({
            query: (id) => ({
                url: `${DELETE_BRANCH}/${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useAddBranchMutation,
    useUpdateBranchMutation,
    useActivateBranchMutation,
    useDeactivateBranchMutation,
    useGetBranchProductsQuery,
    useGetSingleBranchQuery,
    useDeleteBranchMutation,
} = branchAPI;
