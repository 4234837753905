import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { map, size } from 'lodash';
import PromotionWidget3 from '../widgets/PromotionWidget3';
import DataFetchingFeedback from '../feedbacks/DataFetchingFeedback';

const steps = [
    {
        promo: <DataFetchingFeedback />,
    },
];

export default function PromoCarousel({ promotions }) {
    let mappedPromos = map(promotions, (promotion) => {
        return {
            promo: <PromotionWidget3 promotion={promotion} />,
        };
    });

    if (!size(promotions)) {
        mappedPromos = steps;
    }

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = size(mappedPromos);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ width: '100%', p: 0 }}>
                {mappedPromos[activeStep].promo}
            </Box>
            {size(promotions) ? (
                <MobileStepper
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ borderRadius: 2, p: 0, mt: 1 }}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                            sx={{ fontSize: 10, fontWeight: 'bold' }}
                        >
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                            sx={{ fontSize: 10, fontWeight: 'bold' }}
                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                        </Button>
                    }
                />
            ) : null}
        </Box>
    );
}
