import React from 'react';
import { Add } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import CustomCard2 from '../cards/CustomCard2';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { filter, size } from 'lodash';
import { productsSelector } from '../../features/selectors';

// !############### MAIN FUNC ####################
const ProductsSidebar = () => {
    const { products } = useSelector(productsSelector);

    let productCount = 0;
    let activeCount = 0;
    let inActiveCount = 0;

    if (size(products)) {
        productCount = size(products);
        activeCount = size(
            filter(
                products,
                ({ product_details }) => product_details.isActive === 1,
            ),
        );
        inActiveCount = size(
            filter(
                products,
                ({ product_details }) => product_details.isActive === 0,
            ),
        );
    }

    return (
        <>
            {/* ################ Main Content ################ */}
            <CustomCard2 title={'Products actions'} height={640}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item sm={12}>
                        <Box
                            sx={{
                                height: 80,
                                px: 1.5,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: `2px 2px 4px 4px ${colors.bgColor3}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                            }}
                        >
                            <Link to={'/products/create'}>
                                <Button
                                    className="app-btn"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{
                                        boxShadow: 0,
                                        height: 55,
                                        width: 250,
                                        fontWeight: 'bold',
                                    }}
                                    startIcon={<Add />}
                                >
                                    Add new product
                                </Button>
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
                >
                    <Grid container justifyContent={'center'}>
                        <Grid item sm={12}>
                            <Box
                                sx={{
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    mt: 5,
                                    p: 2,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                }}
                            >
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography
                                        sx={{
                                            fontSize: 22,
                                            color: colors.primary,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {productCount}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            color: colors.primary,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Total products
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mt: 4,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            borderLeft: '4px solid green',
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                            p: 1,
                                            borderRadius: 2,
                                            mr: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 18,
                                                color: colors.primary,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {activeCount}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                color: colors.primary,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Active products
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            borderLeft: '4px solid red',
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                            p: 1,
                                            borderRadius: 2,
                                            ml: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 18,
                                                color: colors.primary,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {inActiveCount}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                color: colors.primary,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Inactive products
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </CustomCard2>
        </>
    );
};

export default ProductsSidebar;
