import React, { useEffect, useState } from 'react';
import CustomCard2 from '../../components/cards/CustomCard2';
import CustomersSidebar from '../../components/sidebars/CustomersSidebar';
import CustomerTable from '../../components/tables/CustomerTable';
import PageLayout from '../layouts/PageLayout';
import { useGetAllCustomersQuery } from '../../api/users/userAPI';
import { useDispatch } from 'react-redux';
import { setCustomers } from '../../features/userSlice';
import NoDataFeedback from '../../components/feedbacks/NoDataFeedback';
import DataFetchingFeedback from '../../components/feedbacks/DataFetchingFeedback';
import CountBadge from '../../components/CountBadge';
import { size } from 'lodash';
import { getRTKResponseData } from '../../helpers/RTKHelpers';

// !############### MAIN FUNC ####################
const Customers = () => {
    // ############# CONST ##################
    const dispatch = useDispatch();

    // ############## Comp State ################
    const [allCustomers, setAllCustomers] = useState([]);

    // ############### RTK ######################
    const {
        data: customersData,
        isSuccess,
        isError,
        isLoading,
    } = useGetAllCustomersQuery();

    // ################## use Effect #################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                setAllCustomers(getRTKResponseData(customersData));
                dispatch(setCustomers(getRTKResponseData(customersData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, customersData, dispatch]);

    return (
        <>
            <PageLayout
                sidebar={<CustomersSidebar />}
                head="Customers managements"
            >
                <CustomCard2
                    title={`CAICA Customers`}
                    height={640}
                    action={
                        <CountBadge
                            total={size(allCustomers)}
                            item={'Customers'}
                        />
                    }
                >
                    {isLoading ? (
                        <DataFetchingFeedback />
                    ) : isError ? (
                        <NoDataFeedback message="No customer found" />
                    ) : (
                        <CustomerTable />
                    )}
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default Customers;
