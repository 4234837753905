import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import CustomTextField from '../../components/forms/CustomTextField';
import * as Yup from 'yup';
import { useUpdateBranchMutation } from '../../api/branches/branchAPI';
import { setBranch } from '../../features/branchSlice';
import { useDispatch } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import CustomSubmitButton from '../../components/forms/CustomSubmitButton';

const updateBranchValidationSchema = Yup.object({
    branch_name: Yup.string().required('Branch name is required'),
    branch_location: Yup.string().required('Branch location is required'),
});

// !############### MAIN FUNC ####################
const UpdateBranch = ({ branch, closeDialog, branchRefetch }) => {
    // ############### CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();
    const contacts = JSON.parse(branch.contact_info);
    let contactInfos = contacts[0];

    // ############### RTK ###############
    const [
        updateBranch,
        { isLoading, isSuccess, isError, error, data: brandData },
    ] = useUpdateBranchMutation();

    // ############## Resp ###############
    const rtkActions = (data) => {
        closeDialog();
        branchRefetch();
        dispatch(setBranch(data));
    };
    RTK.useRTKResponse(
        isSuccess,
        isError,
        error,
        brandData,
        rtkActions,
    );
    return (
        <>
            <Formik
                initialValues={{
                    branch_id: branch.id,
                    branch_name: branch.branch_name,
                    branch_location: branch.branch_location,
                    open_hours: branch.open_hours,
                    close_hours: branch.close_hours,
                    email: contactInfos.email,
                    phone_number: contactInfos.phone_number,
                }}
                validationSchema={updateBranchValidationSchema}
                onSubmit={(values) => {
                    const payload = {
                        ...values,
                        contact_info: [
                            {
                                phone_number: values.phone_number,
                                email: values.email,
                            },
                        ],
                    };
                    updateBranch(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item sm={6}>
                                <CustomTextField
                                    name="branch_name"
                                    label={'Branch name'}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    name="branch_location"
                                    label={'Branch location'}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    name="phone_number"
                                    label={'Branch phone'}
                                    color="primary"
                                    type={'text'}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    name="email"
                                    label={'Branch email'}
                                    color="primary"
                                    type={'email'}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    name="open_hours"
                                    label={'Open hours'}
                                    color="primary"
                                    type={'time'}
                                    shrink
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    name="close_hours"
                                    label={'Close hours'}
                                    color="primary"
                                    type={'time'}
                                    shrink
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        onClick={formik.handleSubmit}
                                    >
                                        Update branch
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateBranch;
