import React from 'react';
import { ArrowRight } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/colors';

const CustomCard1 = ({ height, title, children, bBtn, url, borderBottom }) => {
    return (
        <>
            <Box
                sx={{
                    overflow: 'hidden',
                    position: 'relative',
                    bgcolor: colors.white,
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    border: `1px solid ${colors.bgColor2}`,
                    borderBottom:
                        borderBottom && `15px solid ${colors.bgColor3}`,
                    boxShadow: `.5px 1px 10px 1px ${colors.bgColor1}`,
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        bgcolor: colors.bgColor1,
                        py: 1.5,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 13,
                            fontWeight: 'bold',
                            color: colors.primary,
                        }}
                    >
                        {title}
                    </Typography>
                    <Link to={url}>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{
                                fontSize: 10,
                                display: 'flex',
                                alignItems: 'center',
                                py: 0.1,
                            }}
                        >
                            Manage <ArrowRight />
                        </Button>
                    </Link>
                </Box>
                <Box sx={{ p: 1.5, height: height, overflow: 'auto' }}>
                    {children}
                </Box>
                {bBtn && (
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                        }}
                    >
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{
                                'textTransform': 'capitalize',
                                'fontWeight': 'bold',
                                'fontSize': 12,
                                'transition': '.3s all ease-in-out',
                                '&:hover': {
                                    '& .arrow': {
                                        right: 45,
                                        transition: '.3s all ease-in-out',
                                    },
                                },
                                '& .arrow': {
                                    transition: '.3s all ease-in-out',
                                },
                                'background': `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                            }}
                        >
                            {bBtn}
                            <ArrowRight
                                sx={{ position: 'absolute', right: 50 }}
                                className="arrow"
                            />
                        </Button>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default CustomCard1;
