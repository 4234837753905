import React from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import CustomCard2 from '../cards/CustomCard2';
import CustomDialogy from '../dialogys/CustomDialogy';
import BrandWidget2 from '../widgets/BrandWidget2';
import UpdateBrand from '../../pages/brands/UpdateBrand';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import { useDeleteBrandMutation } from '../../api/brands/brandAPI';
import { setBrands } from '../../features/brandSlice';
import { useNavigate } from 'react-router-dom';
import BrandActivateDeactivate from '../../pages/brands/BrandActivateDeactivate';

// !############### MAIN FUNC ####################
const BrandDetailsSidebar = ({ brand, refetchBrandDetails }) => {
    const [openDialogy, setOpenDialogy] = React.useState(false);
    const handleOpenDialogy = () => {
        setOpenDialogy(true);
    };
    const handleCloseDialogy = () => {
        setOpenDialogy(false);
    };

    // ############### CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // ############### RTK ###############
    const [
        deleteBrand,
        { isLoading, isSuccess, isError, error, data: brandData },
    ] = useDeleteBrandMutation();

    // ############## Resp ###############
    const rtkActions = (data) => {
        dispatch(setBrands(data));
        navigate('/brands');
    };
    RTK.useRTKResponse(isSuccess, isError, error, brandData, rtkActions);

    return (
        <>
            {/* ############### Dialogy ################# */}
            <CustomDialogy
                title={'Update brand'}
                open={openDialogy}
                handleCloseDialogy={handleCloseDialogy}
            >
                <UpdateBrand brand={brand} closeDialogy={handleCloseDialogy} refetchBrandDetails={refetchBrandDetails} />
            </CustomDialogy>

            {/* ################ Main Content ################ */}
            <CustomCard2
                title={'Brand actions'}
                height={640}
                action={
                    <BrandActivateDeactivate
                        brand={brand}
                        refetchBrandDetails={refetchBrandDetails}
                    />
                }
            >
                <Grid container spacing={2} justifyContent="center">
                    <Grid item sm={12}>
                        <BrandWidget2 brand={brand} />
                    </Grid>
                </Grid>

                <Box sx={{}}>
                    <Grid
                        container
                        justifyContent={'center'}
                        spacing={3}
                        paddingTop={3}
                    >
                        <Grid item sm={11}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    p: 2,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                    borderRadius: 4,
                                }}
                            >
                                <Typography
                                    sx={{
                                        py: 1,
                                        fontSize: 13,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    Brand descriptions
                                </Typography>
                                <Box>
                                    <Typography sx={{ fontSize: 13 }}>
                                        {brand.brand_description}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={11}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    Created on
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    {moment(brand.created_at).format('ll')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={11}>
                            <Box sx={{ display: 'flex' }}>
                                <Button
                                    startIcon={<Edit />}
                                    color="warning"
                                    className="app-btn"
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        m: 1,
                                    }}
                                    onClick={() => handleOpenDialogy()}
                                >
                                    Edit
                                </Button>
                                <Button
                                    startIcon={<Delete />}
                                    color="error"
                                    className="app-btn"
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        m: 1,
                                    }}
                                    disabled={isLoading ? true : false}
                                    onClick={() => {
                                        deleteBrand(brand.id);
                                    }}
                                >
                                    {isLoading ? (
                                        <CircularProgress size={22} />
                                    ) : (
                                        'Delete'
                                    )}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </CustomCard2>
        </>
    );
};

export default BrandDetailsSidebar;
