import React from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import CustomCard2 from '../cards/CustomCard2';
import CustomDialogy from '../dialogys/CustomDialogy';
import UpdatePromotion from '../../pages/promotions/UpdatePromotion';
import PromotionWidget2 from '../widgets/PromotionWidget2';
import moment from 'moment';
import { useDeletePromotionMutation } from '../../api/promotions/promotionAPI';
import { setPromotions } from '../../features/promotionSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useRTK from '../../hooks/useRTK';

// !############### MAIN FUNC ####################
const PromotionDetailsSidebar = ({ promotion, refetch }) => {
    const [openDialogy, setOpenDialogy] = React.useState(false);
    const handleOpenDialogy = () => {
        setOpenDialogy(true);
    };

    const handleCloseDialogy = () => {
        setOpenDialogy(false);
    };

    // ############### CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // ############### RTK ###############
    const [
        deletePromotion,
        { isLoading, isSuccess, isError, error, data: brandData },
    ] = useDeletePromotionMutation();

    // ############## Resp ###############
    const rtkActions = (data) => {
        navigate('/promotions');
        dispatch(setPromotions(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, brandData, rtkActions);

    return (
        <>
            {/* ############### Dialogy ################# */}
            <CustomDialogy
                title={'Update promotion'}
                open={openDialogy}
                handleCloseDialogy={handleCloseDialogy}
            >
                <UpdatePromotion
                    promotion={promotion}
                    closeDialogy={handleCloseDialogy}
                    refetch={refetch}
                />
            </CustomDialogy>

            {/* ################ Main Content ################ */}
            <CustomCard2 title={'Promotions actions'} height={640}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item sm={12}>
                        <PromotionWidget2
                            refetch={refetch}
                            promotion={promotion}
                        />
                    </Grid>
                </Grid>

                <Box sx={{}}>
                    <Grid
                        container
                        justifyContent={'center'}
                        spacing={3}
                        paddingTop={3}
                    >
                        <Grid item sm={11}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    p: 2,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                    borderRadius: 4,
                                }}
                            >
                                <Typography
                                    sx={{
                                        py: 1,
                                        fontSize: 13,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    Promotion descriptions
                                </Typography>
                                <Box>
                                    <Typography sx={{ fontSize: 13 }}>
                                        {promotion.description}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={11}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    Created on
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    {moment(promotion.created_at).format('ll')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={11}>
                            <Box sx={{ display: 'flex' }}>
                                <Button
                                    startIcon={<Edit />}
                                    color="warning"
                                    className="app-btn"
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        m: 1,
                                    }}
                                    onClick={() => handleOpenDialogy()}
                                >
                                    Edit
                                </Button>
                                <Button
                                    startIcon={<Delete />}
                                    color="error"
                                    className="app-btn"
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        m: 1,
                                    }}
                                    disabled={isLoading ? true : false}
                                    onClick={() => {
                                        deletePromotion(promotion.id);
                                    }}
                                >
                                    {isLoading ? (
                                        <CircularProgress size={22} />
                                    ) : (
                                        'Delete'
                                    )}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </CustomCard2>
        </>
    );
};

export default PromotionDetailsSidebar;
