import React from 'react';
import DataFetchingFeedback from '../feedbacks/DataFetchingFeedback';
import { isEmpty } from 'lodash';
import NoDataFeedback from '../feedbacks/NoDataFeedback';
import PromoCarousel from '../carousel/PromoCarousel';
import { setPromotions } from '../../features/promotionSlice';
import { getRTKResponseData } from '../../helpers/RTKHelpers';
import { useGetPromotionsQuery } from '../../api/dashboard/dashboardAPI';
import { useDispatch, useSelector } from 'react-redux';
import { promotionsSelector } from '../../features/selectors';

const DashPromotions = () => {
    // ################# CONST ##################
    const dispatch = useDispatch();

    // ############## Redux ###################
    const { promotions } = useSelector(promotionsSelector);

    // ############## RTK ####################
    const {
        isLoading: promotionsLoading,
        isSuccess: promotionSuccess,
        data: promotionsData,
        refetch: promotionsRefetch,
    } = useGetPromotionsQuery();

    // ############## useEffect ###################
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            promotionsRefetch();
            if (promotionSuccess) {
                dispatch(setPromotions(getRTKResponseData(promotionsData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [promotionSuccess, promotionsData, promotionsRefetch]);
    return (
        <>
            {promotionsLoading ? (
                <>
                    <DataFetchingFeedback />
                </>
            ) : isEmpty(promotions) ? (
                <>
                    <NoDataFeedback message={'No branch added'} />
                </>
            ) : (
                <>
                    <PromoCarousel promotions={promotions} />
                </>
            )}
        </>
    );
};

export default DashPromotions;
