import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Box, Button, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { colors } from '../../assets/utils/colors';
import CustomeFileForm from '../../components/forms/CustomeFileForm';
import { Image } from '@mui/icons-material';

// RTK Query and redux imports
import { useAddProductImageMutation } from '../../api/products/productAPI';
import { errorNotify, successNotify } from '../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';
import CustomSubmitButton from '../../components/forms/CustomSubmitButton';

// ################### validation schema ###############
const addProductImageSchema = Yup.object({
    file: Yup.string().required(),
});

const AddProductImage = ({ product_id, refetch, handleCloseDialogy }) => {
    // ################ Comp state ####################
    const [filePreview, setFilePreview] = useState('');

    // ############### RTK ########################
    const [
        uploadImage,
        { isSuccess, isLoading, isError, error, data },
    ] = useAddProductImageMutation();

    // ################ useEffect ####################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                refetch();
                handleCloseDialogy();
                successNotify(getRTKSuccessMessage(data));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isError, error, isSuccess, data]);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    file: '',
                }}
                validationSchema={addProductImageSchema}
                onSubmit={async (values, actions) => {
                    const data = new FormData();
                    data.append('product_id', product_id);
                    data.append('image', values.file);
                    await uploadImage(data);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item sm={12}>
                                <Grid container>
                                    <Grid item sm={12} sx={{ mt: 2 }}>
                                        <Box
                                            sx={{
                                                height: 225,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderRadius: 5,
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                            }}
                                        >
                                            {filePreview ? (
                                                <>
                                                    <img
                                                        src={filePreview}
                                                        alt="img"
                                                        style={{
                                                            width: '100%',
                                                            height: '10em',
                                                            objectFit:
                                                                'contain',
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Image
                                                        sx={{
                                                            width: '100%',
                                                            height: '7em',
                                                            objectFit:
                                                                'contain',
                                                            opacity: 0.7,
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <CustomeFileForm
                                            name="image"
                                            label={'Product image'}
                                            type="file"
                                            color="primary"
                                            setFilePreview={setFilePreview}
                                            formik={formik}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        onClick={formik.handleSubmit}
                                    >
                                        Add product image
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddProductImage;
