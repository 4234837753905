import React from 'react';
import { Box, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';

const DashInfobox2 = ({ head, subhead, icon }) => {
    return (
        <>
            <Box
                sx={{
                    'display': 'flex',
                    'alignItems': 'center',
                    '& .icon': {
                        fontSize: 35,
                        color: colors.white,
                    },
                }}
            >
                <Box
                    sx={{
                        p: 1,
                        background: `linear-gradient(180deg, ${colors.primary}, ${colors.secondary})`,
                        borderRadius: 1,
                    }}
                >
                    {icon}
                </Box>
                <Box sx={{ pl: 1 }}>
                    <Typography
                        sx={{
                            fontSize: 11,
                            mb: 0.3,
                            fontWeight: 'bold',
                            color: colors.primary,
                            textTransform: 'uppercase',
                        }}
                    >
                        {head}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                        ({subhead}) products
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default DashInfobox2;
