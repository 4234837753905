import {
    Avatar,
    Box,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';
import userAvatar from '../../assets/media/images/user.png';
import { useSelector } from 'react-redux';
import { useDate } from '../../hooks/useDate';

const DashStatsWidget = () => {
    const user = useSelector((state) => state.auth.user);
    const date = useDate();

    return (
        <>
            <Box
                sx={{
                  p: 2,
                  borderRadius: 2,
                  background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                }}
            >
                <Box
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: 'bold',
                        }}
                    >
                        {date.wish_1}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 1,
                        }}
                    >
                        <List
                            sx={{
                                width: '100%',
                                p: 0,
                                borderRadius: 2,
                                borderBottom: `1px solid ${colors.secondary}`,
                            }}
                        >
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar src={userAvatar} />
                                </ListItemAvatar>
                                <ListItemText
                                    primaryTypographyProps={{
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                    }}
                                    secondaryTypographyProps={{
                                        fontSize: 10,
                                    }}
                                    primary={`${user.first_name} ${user.last_name} `}
                                    secondary={`CAICA  ${
                                        user.isAdmin && 'Super Admin'
                                    }`}
                                />
                            </ListItem>
                        </List>
                        <Typography
                            sx={{
                                fontSize: 11,
                                fontWeight: 'bold',
                                color: colors.primary,
                            }}
                        >
                            {date.time}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Box
                        sx={{
                            height: 200,
                            background: `linear-gradient(180deg, ${colors.secondary}, ${colors.bgColor1})`,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            borderRadius: 2,
                        }}
                    >
                        <Divider orientation="horizontal" flexItem>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    textAlign: 'left',
                                    color: colors.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                This week stats
                            </Typography>
                        </Divider>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 22,
                                    color: colors.primary,
                                    fontWeight: 'bold',
                                }}
                            >
                                50,000,000
                                <sup
                                    style={{
                                        fontSize: 12,
                                        color: colors.primary,
                                    }}
                                >
                                    Tshs
                                </sup>
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                }}
                            >
                                Your balance
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                px: 2,
                            }}
                        >
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        color: colors.primary,
                                    }}
                                >
                                    50
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 10,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    Total Sales
                                </Typography>
                            </Box>
                            <Divider orientation="vertical" flexItem />
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        color: colors.primary,
                                    }}
                                >
                                    50
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 10,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    Avarage sales
                                </Typography>
                            </Box>
                            <Divider orientation="vertical" flexItem />
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        color: colors.primary,
                                    }}
                                >
                                    50
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 10,
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    Out of stock
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default DashStatsWidget;
