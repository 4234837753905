import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/colors';

const DashInfobox = ({ head, subhead, total, icon, url }) => {
    return (
        <>
            <Link to={url}>
                <Box
                    sx={{
                        'height': 100,
                        'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                        'borderRadius': 3,
                        'display': 'flex',
                        'alignItems': 'center',
                        'overflow': 'hidden',
                        'transition': '.3s all ease-in-out',
                        border: `1px solid ${colors.bgColor2}`,
                        '& .icon': {
                            fontSize: 60,
                            color: colors.primary,
                        },
                        '&:hover': {
                            boxShadow: `.5px 1px 5px 1px ${colors.bgColor3}`,
                            transition: '.3s all ease-in-out',
                        },
                    }}
                >
                    <Box
                        sx={{
                            p: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                            borderRadius: 2,
                            borderLeft: `4px solid ${colors.secondary}`,
                        }}
                    >
                        {icon}
                    </Box>
                    <Box sx={{ p: 2, color: colors.primary }}>
                        <Typography>
                            {total} <small>{subhead}</small>
                        </Typography>
                        <Typography sx={{ fontSize: 11, fontWeight: 'bold', width: 100 }}>
                            {head}
                        </Typography>
                    </Box>
                </Box>
            </Link>
        </>
    );
};

export default DashInfobox;
