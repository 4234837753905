import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { colors } from '../../assets/utils/colors';
import { Chip, IconButton, Typography } from '@mui/material';
import { PinDrop, RemoveRedEye } from '@mui/icons-material';
import { slice, map, size } from 'lodash';
import { getChipColor, numberWithCommas } from '../../utils/helpers';
import { Link } from 'react-router-dom';
import { removeSpecialChar } from '../../helpers/stringHelpers';

const columns = [
    {
        id: 's_n',
        label: 'S/N',
        align: 'center',
        fontWeight: 'bold',
        width: 10,
    },
    {
        id: 'order_id',
        label: 'OrderID',
    },
    {
        id: 'deliver_opt',
        label: 'DeliveryOPT',
    },
    {
        id: 'pick_up',
        label: 'Pick-up Address',
    },
    {
        id: 'amount',
        label: (
            <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                Amount <sup style={{ color: 'green' }}>TZS</sup>
            </Typography>
        ),
    },
    {
        id: 'status',
        label: 'Status',
    },
    {
        id: 'action',
        label: 'Action',
    },
];

const createData = (
    s_n,
    order_id,
    deliver_opt,
    pick_up,
    amount,
    status,
    action,
) => {
    return { s_n, order_id, deliver_opt, pick_up, amount, status, action };
};

export default function CustomerOrdersTable({ orders }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const orderRows = map(orders, (order, index) => {
        return createData(
            index + 1,
            <Typography
                sx={{ fontWeight: 'bold', fontSize: 12 }}
            >{`${order.order_number}`}</Typography>,
            <Typography
                sx={{
                    fontSize: 13,
                    fontWeight: 'bold',
                    color:
                        order.delivery_type === 'self_pickup'
                            ? colors.warning
                            : colors.info,
                }}
            >
                {removeSpecialChar(order.delivery_type, ' ')}
            </Typography>,
            <Typography
                sx={{ fontSize: 13, display: 'flex', alignItems: 'center' }}
            >
                <PinDrop sx={{ fontSize: 14 }} color="secondary" />
                {order.pickup_branch}
            </Typography>,
            <Typography
                sx={{ fontWeight: 'bold', opacity: 0.8, color: colors.primary }}
            >
                {numberWithCommas(order.total_amount)}
            </Typography>,
            <Chip
                size="small"
                sx={{ fontSize: 9, fontWeight: 'bold' }}
                color={getChipColor(order.order_status)}
                label={removeSpecialChar(order.order_status, ' ')}
            />,
            <Link to={`/orders/details/${order.id}`}>
                <IconButton color="info" size="small">
                    <RemoveRedEye />
                </IconButton>
            </Link>,
        );
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ height: 540, m: 0 }}>
                <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{
                        '& th': {
                            bgcolor: `${colors.bgColor1} !important`,
                        },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            {map(columns, (column) => (
                                <TableCell
                                    key={column.id}
                                    sx={{
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                        textAlign: column.align,
                                        width: column.width,
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {slice(
                            orderRows,
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage,
                        ).map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                >
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                sx={{
                                                    fontWeight:
                                                        column.fontWeight,
                                                }}
                                            >
                                                {column.format &&
                                                typeof value === 'number'
                                                    ? column.format(value)
                                                    : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                sx={{
                    '&.MuiTablePagination-root': {
                        bgcolor: `${colors.bgColor2} !important`,
                    },
                }}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={size(orderRows)}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
