import React, { useEffect } from 'react';
import {
    Box,
    CircularProgress,
    IconButton,
    ImageListItem,
    Tooltip,
} from '@mui/material';
import { colors } from '../../assets/utils/colors';
import { useDeleteProductImageMutation } from '../../api/products/productAPI';
import { errorNotify, successNotify } from '../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';
import { Remove } from '@mui/icons-material';

const ProductAdditionImg = ({ image, refetch }) => {
    // ############## RTK ########################
    const [
        deleteImage,
        { isSuccess, isError, error, isLoading, data },
    ] = useDeleteProductImageMutation();

    // ############# useEffect ##############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                refetch();
                successNotify(getRTKSuccessMessage(data));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, data, error, isError]);

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                }}
            >
                <ImageListItem
                    sx={{
                        borderRadius: 2,
                        overflow: 'hidden',
                        border: `1px solid ${colors.bgColor5}`,
                        p: 0.5,
                    }}
                >
                    <img
                        src={`${image.image}?w=164&h=164&fit=crop&auto=format`}
                        loading="lazy"
                        alt="p_img"
                        style={{
                            objectFit: 'cover',
                        }}
                    />
                </ImageListItem>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                    }}
                >
                    <Tooltip title="Remove image" arrow>
                        <IconButton
                            disabled={isLoading ? true : false}
                            size="small"
                            sx={{
                                'bgcolor': colors.danger,
                                'color': colors.bgColor1,
                                ':hover': {
                                    bgcolor: colors.danger,
                                },
                            }}
                            onClick={() => {
                                deleteImage(image.image_id);
                            }}
                        >
                            {isLoading ? (
                                <CircularProgress size={25} />
                            ) : (
                                <Remove />
                            )}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </>
    );
};

export default ProductAdditionImg;
