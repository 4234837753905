import React from 'react';
import { Grid } from '@mui/material';
import CustomCard1 from '../components/cards/CustomCard1';
import DashStatsWidget from '../components/widgets/DashStatsWidget';
import DashMinInfoboxes from '../components/DashMinInfoboxes';
import DashMainInfobox from '../components/DashMainInfobox';
import { useSelector } from 'react-redux';
import { colors } from '../assets/utils/colors';
import { isEmpty, reverse, size } from 'lodash';
import DashLayout from './layouts/DashLayout';
import OrderWidget from '../components/widgets/OrderWidget';
import NoDataFeedback from '../components/feedbacks/NoDataFeedback';
import DashBrands from '../components/dash/DashBrands';
import DashBranches from '../components/dash/DashBranches';
import DashPromotions from '../components/dash/DashPromotions';
import DashTodayOders from '../components/dash/DashTodayOders';

const Dashboard = () => {
    const promotions = useSelector((state) => state.promotions.promotions);
    const branches = useSelector((state) => state.branches.branches);
    const brands = useSelector((state) => state.brands.brands);

    return (
        <>
            <DashLayout>
                <Grid container spacing={3}>
                    <Grid item sm={12}>
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                pb: 3,
                                borderBottom: `2px solid ${colors.bgColor4}`,
                                boxShadow: `-5px 0px 10px 0px ${colors.bgColor3}`,
                            }}
                        >
                            <Grid item sm={3}>
                                <DashStatsWidget />
                            </Grid>
                            <Grid item sm={5.5}>
                                {/* ############## Main infoboxes ########### */}
                                <Grid container spacing={2}>
                                    <DashMainInfobox />
                                </Grid>

                                {/* ############## Sub infoboxes ########### */}
                                <Grid container sx={{ mt: 4 }}>
                                    <DashMinInfoboxes />
                                </Grid>
                            </Grid>
                            <Grid item sm={3.5}>
                                <CustomCard1
                                    title={'Today orders'}
                                    height={275}
                                    borderBottom={true}
                                    url={'/orders'}
                                >
                                    <DashTodayOders />
                                </CustomCard1>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12}>
                        <Grid container spacing={3}>
                            <Grid item sm={4.25}>
                                <CustomCard1
                                    height={250}
                                    title={`CAICA promotions (${size(
                                        promotions,
                                    )})`}
                                    url={'/promotions'}
                                >
                                    <DashPromotions />
                                </CustomCard1>
                            </Grid>
                            <Grid item sm={4.25}>
                                <CustomCard1
                                    height={250}
                                    title={`CAICA branches (${size(branches)})`}
                                    url={'/branches'}
                                >
                                    <DashBranches />
                                </CustomCard1>
                            </Grid>
                            <Grid item sm={3.5}>
                                <CustomCard1
                                    title={`Associated brands (${size(
                                        brands,
                                    )})`}
                                    url={'/brands'}
                                    height={235}
                                    borderBottom={true}
                                >
                                    <DashBrands />
                                </CustomCard1>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DashLayout>
        </>
    );
};

export default Dashboard;
