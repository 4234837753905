import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from '../layouts/PageLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import { colors } from '../../assets/utils/colors';
import ProductWidget from '../../components/widgets/ProductWidget';
import BranchDetailsSidebar from '../../components/sidebars/BranchDetailsSidebar';
import { isEmpty, size } from 'lodash';
import CountBadge from '../../components/CountBadge';
import NoDataFeedback from '../../components/feedbacks/NoDataFeedback';
import { branchesSelector, productsSelector } from '../../features/selectors';
import { useGetSingleBranchQuery } from '../../api/branches/branchAPI';
import { getRTKResponseData } from '../../helpers/RTKHelpers';
import DataFetchingFeedback from '../../components/feedbacks/DataFetchingFeedback';
import { setBranch } from '../../features/branchSlice';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        'id': `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// !############### MAIN FUNC ####################
const BranchDetails = () => {
    // ############### CONST ################
    const dispatch = useDispatch();
    const { branch_id } = useParams();

    // ############## Comp state ##################
    const [value, setValue] = useState(0);

    // ############## Redux state ##################
    const { branch } = useSelector(branchesSelector);
    const { products: branch_products } = useSelector(productsSelector);

    // ############## Distructure state ##################
    const inStockProducts = branch?.in_stock_products;
    const outOfStockProducts = branch?.out_stock_products;

    // ################# RTK ###########################
    const {
        isLoading,
        isSuccess,
        data: branchData,
        refetch,
    } = useGetSingleBranchQuery(branch_id);

    // ################## useEffect #####################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            refetch();
            if (isSuccess) {
                dispatch(setBranch(getRTKResponseData(branchData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, branchData, dispatch, refetch]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <PageLayout
                head="Branch details"
                sidebar={
                    !isLoading && (
                        <BranchDetailsSidebar
                            refetch={refetch}
                            branchID={branch_id}
                            branchInfo={branch}
                            productsCount={size(branch_products)}
                            inStockProduct={size(inStockProducts)}
                            outStockCount={size(outOfStockProducts)}
                        />
                    )
                }
            >
                <CustomCard2
                    title={'Branch products'}
                    heightClear={640}
                    action={
                        <CountBadge
                            total={size(branch_products)}
                            item={'Products'}
                        />
                    }
                >
                    {isLoading ? (
                        <>
                            <DataFetchingFeedback />
                        </>
                    ) : (
                        <Grid container spacing={3} padding={2}>
                            <Box sx={{ width: '100%' }}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                        position: 'sticky',
                                        top: 0,
                                        background: `linear-gradient(180deg, ${colors.white}, ${colors.white})`,
                                        zIndex: 999,
                                    }}
                                >
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                    >
                                        <Tab
                                            label={
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            textTransform:
                                                                'capitalize',
                                                            fontWeight: 'bold',
                                                            mr: 1,
                                                        }}
                                                    >
                                                        All
                                                    </Typography>
                                                    <Chip
                                                        sx={{ fontSize: 10 }}
                                                        label={size(
                                                            branch_products,
                                                        )}
                                                        color="primary"
                                                        size="small"
                                                    />
                                                </Box>
                                            }
                                            {...a11yProps(0)}
                                        />
                                        <Tab
                                            label={
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            textTransform:
                                                                'capitalize',
                                                            fontWeight: 'bold',
                                                            mr: 1,
                                                        }}
                                                    >
                                                        In-stock
                                                    </Typography>
                                                    <Chip
                                                        sx={{ fontSize: 10 }}
                                                        label={size(
                                                            inStockProducts,
                                                        )}
                                                        color="info"
                                                        size="small"
                                                    />
                                                </Box>
                                            }
                                            {...a11yProps(1)}
                                        />
                                        <Tab
                                            label={
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            textTransform:
                                                                'capitalize',
                                                            fontWeight: 'bold',
                                                            mr: 1,
                                                        }}
                                                    >
                                                        Out-stock
                                                    </Typography>
                                                    <Chip
                                                        sx={{ fontSize: 10 }}
                                                        label={size(
                                                            outOfStockProducts,
                                                        )}
                                                        color="error"
                                                        size="small"
                                                    />
                                                </Box>
                                            }
                                            {...a11yProps(2)}
                                        />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <Grid container spacing={3} padding={2}>
                                        {isEmpty(branch_products) ? (
                                            <NoDataFeedback message="No product found" />
                                        ) : (
                                            branch_products.map(
                                                (product, index) => (
                                                    <Grid
                                                        item
                                                        sm={3}
                                                        key={index}
                                                    >
                                                        <ProductWidget
                                                            product={
                                                                product.product_details
                                                            }
                                                        />
                                                    </Grid>
                                                ),
                                            )
                                        )}
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <Grid container spacing={3} padding={2}>
                                        {isEmpty(inStockProducts) ? (
                                            <NoDataFeedback message="No product found" />
                                        ) : (
                                            inStockProducts.map(
                                                (product, index) => (
                                                    <Grid
                                                        item
                                                        sm={3}
                                                        key={index}
                                                    >
                                                        <ProductWidget
                                                            product={product}
                                                        />
                                                    </Grid>
                                                ),
                                            )
                                        )}
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <Grid container spacing={3} padding={2}>
                                        {isEmpty(outOfStockProducts) ? (
                                            <NoDataFeedback message="No product found" />
                                        ) : (
                                            outOfStockProducts.map(
                                                (product, index) => (
                                                    <Grid
                                                        item
                                                        sm={3}
                                                        key={index}
                                                    >
                                                        <ProductWidget
                                                            product={product}
                                                        />
                                                    </Grid>
                                                ),
                                            )
                                        )}
                                    </Grid>
                                </TabPanel>
                            </Box>
                        </Grid>
                    )}
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default BranchDetails;
