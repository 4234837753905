import React from 'react';
import { Grid } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import BranchWidget from '../../components/widgets/BranchWidget';
import PageLayout from '../layouts/PageLayout';
import BranchesSidebar from '../../components/sidebars/BranchesSidebar';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, map, reverse, size } from 'lodash';
import { branchesSelector } from '../../features/selectors';
import CountBadge from '../../components/CountBadge';
import NoDataFeedback from '../../components/feedbacks/NoDataFeedback';
import { setBranches } from '../../features/branchSlice';
import { getRTKResponseData } from '../../helpers/RTKHelpers';
import DataFetchingFeedback from '../../components/feedbacks/DataFetchingFeedback';
import { useGetBranchesQuery } from '../../api/dashboard/dashboardAPI';

const Branches = () => {
    // ################# CONST ##################
    const dispatch = useDispatch();
    const { branches } = useSelector(branchesSelector);

    // ################# RTK ####################
    const {
        isLoading: branchesLoading,
        isSuccess: branchSuccess,
        data: branchesData,
        refetch: branchesRefetch,
    } = useGetBranchesQuery();

    // ################ MAIN dispatch ####################
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            branchesRefetch();
            if (branchSuccess) {
                dispatch(setBranches(getRTKResponseData(branchesData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [branchSuccess, branchesData, branchesRefetch, dispatch]);

    return (
        <>
            <PageLayout
                head="Branches managements"
                sidebar={
                    <BranchesSidebar
                        branches={branches}
                        branchesRefetch={branchesRefetch}
                    />
                }
            >
                <CustomCard2
                    title={`CAICA Branches`}
                    heightClear={640}
                    action={
                        <CountBadge total={size(branches)} item={'Branches'} />
                    }
                >
                    {branchesLoading ? (
                        <DataFetchingFeedback />
                    ) : isEmpty(branches) ? (
                        <NoDataFeedback message={'No branch added'} />
                    ) : (
                        <Grid container spacing={3} padding={2}>
                            {reverse(
                                map(branches, (branch) => (
                                    <Grid item sm={6} key={branch.id}>
                                        <BranchWidget
                                            branch={branch}
                                            branchesRefetch={branchesRefetch}
                                        />
                                    </Grid>
                                )),
                            )}
                        </Grid>
                    )}
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default Branches;
