import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const DataFetchingFeedback = () => {
    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    top: 43,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 10,
                }}
            >
                <Box sx={{ textAlign: 'center' }}>
                    <CircularProgress color="secondary" />
                </Box>
            </Box>
        </>
    );
};

export default DataFetchingFeedback;
