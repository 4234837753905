import React from 'react';
import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { colors } from '../../assets/utils/colors';

const SidebarListIitem = ({ desktopOpen, name, url, icon, tooltip }) => {
    return (
        <>
            <Tooltip title={tooltip} placement="right" arrow>
                <NavLink to={url}>
                    {({ isActive }) => (
                        <ListItem disablePadding>
                            <ListItemButton
                                sx={{
                                    borderBottom: `1px solid ${colors.bgColor2}`,
                                    borderLeft: isActive
                                        ? `4px solid ${colors.secondary}`
                                        : undefined,
                                    px: 0,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 40,
                                        mr: desktopOpen ? 3 : 'auto',
                                        justifyContent: 'center',
                                        transition: '.3s all ease-in-out',
                                    }}
                                >
                                    {icon}
                                </ListItemIcon>
                                <ListItemText
                                    sx={{
                                        'opacity': desktopOpen ? 1 : 0,
                                        'transition': '.3s all ease-in-out',
                                        '& .MuiTypography-root': {
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            color: colors.primary,
                                            transition: '.3s all ease-in-out',
                                        },
                                    }}
                                    primary={name}
                                />
                            </ListItemButton>
                        </ListItem>
                    )}
                </NavLink>
            </Tooltip>
        </>
    );
};

export default SidebarListIitem;
