import React from 'react';
import { Box, FormControl, InputLabel, Select } from '@mui/material';
import { ErrorMessage, useField } from 'formik';

const CustomSelectForm = ({ formikName, label, formik, children, ...props }) => {
    const [value, setValue] = React.useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
        formik.setFieldValue(formikName, event.target.value)
    };
    const [field, meta] = useField(props);
    return (
        <>
            <Box sx={{ marginTop: 2 }}>
                <FormControl fullWidth size="small">
                    <InputLabel>{label}</InputLabel>
                    <Select
                        value={value}
                        label={label}
                        {...field}
                        {...props}
                        onChange={handleChange}
                        error={meta.touched && meta.error ? true : false}
                        variant="outlined"
                    >
                        {children}
                    </Select>
                </FormControl>
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomSelectForm;
