import React from 'react';
import {
    Category,
    Inventory,
    PersonalVideo,
    ShoppingCart,
} from '@mui/icons-material';
import { Grid } from '@mui/material';
import DashInfobox from './infoboxes/DashInfobox';
import { useSelector } from 'react-redux';
import { size } from 'lodash';

const DashMainInfobox = () => {
    const orders = useSelector((state) => state.orders.orders);
    const promotions = useSelector((state) => state.promotions.promotions);
    const branches = useSelector((state) => state.branches.branches);
    const products = useSelector((state) => state.products.products);

    const infoBox = [
        {
            id: 1,
            head: 'Branch managements',
            subhead: 'Branches',
            total: size(branches),
            icon: <Inventory className="icon" />,
            url: '/branches',
        },
        {
            id: 2,
            head: 'Promotion managements',
            subhead: 'Promotions',
            total: size(promotions),
            icon: <PersonalVideo className="icon" />,
            url: '/promotions',
        },
        {
            id: 3,
            head: 'Products managements',
            subhead: 'Products',
            total: size(products),
            icon: <Category className="icon" />,
            url: '/Products',
        },
        {
            id: 4,
            head: 'Orders managements',
            subhead: 'Orders',
            total: size(orders),
            icon: <ShoppingCart className="icon" />,
            url: '/orders',
        },
    ];
    return (
        <>
            {infoBox.map((item) => (
                <Grid item sm={6} key={item.id}>
                    <DashInfobox
                        head={item.head}
                        subhead={item.subhead}
                        total={item.total}
                        icon={item.icon}
                        url={item.url}
                    />
                </Grid>
            ))}
        </>
    );
};

export default DashMainInfobox;
