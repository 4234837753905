import React from 'react';
import { Add } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';
import CreateBranch from '../../pages/branches/CreateBranch';
import CustomCard2 from '../cards/CustomCard2';
import CustomDialogy from '../dialogys/CustomDialogy';
import { useSelector } from 'react-redux';
import { filter, size } from 'lodash';

// !############### MAIN FUNC ####################
const BranchesSidebar = ({ branches, branchesRefetch }) => {
    const [openDialogy, setOpenDialogy] = React.useState(false);
    const branchAddedStatus = useSelector(
        (state) => state.branches.branchAddedSuccess,
    );

    if (branchAddedStatus) {
        setTimeout(() => setOpenDialogy(false), 2500);
    }

    const handleOpenDialogy = () => {
        setOpenDialogy(true);
    };

    const handleCloseDialogy = () => {
        setOpenDialogy(false);
    };

    return (
        <>
            {/* ############### Dialogy ################# */}
            <CustomDialogy
                title={'Create new branch'}
                open={openDialogy}
                handleCloseDialogy={handleCloseDialogy}
            >
                <CreateBranch
                    handleCloseDialogy={handleCloseDialogy}
                    branchesRefetch={branchesRefetch}
                />
            </CustomDialogy>

            {/* ################ Main Content ################ */}
            <CustomCard2 title={'Branches actions'} height={640}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item sm={12}>
                        <Box
                            sx={{
                                height: 80,
                                px: 1.5,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: `2px 2px 4px 4px ${colors.bgColor3}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                            }}
                        >
                            <Button
                                onClick={() => handleOpenDialogy()}
                                className="app-btn"
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{
                                    boxShadow: 0,
                                    height: 55,
                                    width: 250,
                                    fontWeight: 'bold',
                                }}
                                startIcon={<Add />}
                            >
                                Create new branch
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
                >
                    <Grid container justifyContent={'center'}>
                        <Grid item sm={12}>
                            <Box
                                sx={{
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    mt: 5,
                                    p: 2,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                }}
                            >
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography
                                        sx={{
                                            fontSize: 22,
                                            color: colors.primary,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {size(branches)}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            color: colors.primary,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Total branches
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mt: 4,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            borderLeft: '4px solid green',
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                            p: 1,
                                            borderRadius: 2,
                                            mr: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 18,
                                                color: colors.primary,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {size(
                                                filter(branches, {
                                                    branch_status: 1,
                                                }),
                                            )}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                color: colors.primary,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Active branches
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            borderLeft: '4px solid red',
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                            p: 1,
                                            borderRadius: 2,
                                            ml: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 18,
                                                color: colors.primary,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {size(
                                                filter(branches, {
                                                    branch_status: 0,
                                                }),
                                            )}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                color: colors.primary,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Inactive branches
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </CustomCard2>
        </>
    );
};

export default BranchesSidebar;
