import React from 'react';
import { Box, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';

const CustomCard2 = ({
    height,
    title,
    children,
    padding,
    heightClear,
    action,
    borderBottom,
}) => {
    return (
        <>
            <Box
                sx={{
                    height: { height },
                    bgcolor: colors.white,
                    borderRadius: 2,
                    overflow: 'hidden',
                    position: 'relative',
                    border: `1px solid ${colors.bgColor1}`,
                    boxShadow: `.5px .1px 10px .1px ${colors.bgColor2}`,
                    borderBottom:
                        borderBottom && `15px solid ${colors.bgColor3}`,
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        bgcolor: colors.bgColor1,
                        py: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        zIndex: 200,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 13,
                            fontWeight: 'bold',
                            color: colors.primary,
                        }}
                    >
                        {title}
                    </Typography>
                    {action}
                </Box>
                <Box
                    sx={{
                        p: padding,
                        overflow: 'auto',
                        height: heightClear - 55,
                    }}
                >
                    {children}
                </Box>
            </Box>
        </>
    );
};

export default CustomCard2;
