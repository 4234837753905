import { DatasetLinked } from '@mui/icons-material';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { colors } from '../../assets/utils/colors';

const NoDataFeedback = ({ message }) => {
    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    top: 43,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 10,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                }}
            >
                <Box sx={{ textAlign: 'center' }}>
                    <DatasetLinked
                        sx={{ fontSize: 50, color: colors.primary }}
                    />
                    <Typography sx={{ fontSize: 14, color: colors.primary }}>
                        {message}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default NoDataFeedback;
