import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';
import DataPagnation from '../DataPagnation';

// ############# CARD ###############
const Card = styled(Box)(({ theme }) => ({
    // background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
    borderRadius: 10,
    overflow: 'hidden',
    position: 'relative',
}));

// ############# CARD HEADER ###############
const CardHeader = styled(Box)(({ theme }) => ({
    background: colors.bgColor1,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 10,
}));

// ############# CARD BODY ###############
const CardBody = styled(Box)(({ theme }) => ({}));

// ############# CARD FOOTER ###############
const CardFooter = styled(Box)(({ theme }) => ({}));

const CustomPagination = ({
    data,
    height,
    action,
    title,
    handlePageChange,
    handlePerPageChage,
    children,
}) => {
    const currentPage = data?.page;
    const handleNext = (nextPage) => {
        handlePageChange(nextPage);
    };
    const handlePrev = (prevPage) => {
        handlePageChange(prevPage);
    };
    const handlePageSelect = (page) => {
        handlePageChange(page);
    };
    return (
        <>
            <Card
                sx={{
                    m: 0,
                    // borderBottom: `15px solid ${colors.bgColor1}`,
                    // background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                }}
            >
                {/* ==== header ===== */}
                <CardHeader sx={{ display: title ? 'flex' : 'none' }}>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                opacity: 0.8,
                            }}
                            className="head-font"
                        >
                            {title}
                        </Typography>
                    </Box>
                    <Box>{action}</Box>
                </CardHeader>

                {/* ==== Body ===== */}
                <CardBody
                    sx={{
                        height: height,
                        overflow: 'auto',
                        position: 'relative',
                    }}
                >
                    {children}
                </CardBody>
                <CardFooter>
                    <DataPagnation
                        data={data}
                        currentPage={currentPage}
                        perPage={handlePerPageChage}
                        handlePageSelect={handlePageSelect}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                    />
                </CardFooter>
            </Card>
        </>
    );
};

export default CustomPagination;
