import { Chip } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/colors';
import { useDispatch } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import { useDeleteSubCategoryMutation } from '../../api/categories/categoryAPI';
import { setSubCategories } from '../../features/categorySlice';

const SubCategoryChipWidget = ({ lable, id }) => {
    // ############### CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK ###############
    const [
        deleteCategory,
        {
            isLoading: deleteLoading,
            isSuccess: deleteSuccess,
            isError: deleteIsError,
            error: deleteError,
            data: deleteData,
        },
    ] = useDeleteSubCategoryMutation();
    // ############## Resp ###############
    const rtkActions = (data) => {
        dispatch(setSubCategories(data));
    };
    RTK.useRTKResponse(
        deleteSuccess,
        deleteIsError,
        deleteError,
        deleteData,
        'subCategories',
        rtkActions,
    );

    return (
        <>
            <Chip
                label={lable}
                disabled={deleteLoading ? true : false}
                size="small"
                sx={{
                    m: 0.5,
                    opacity: 0.8,
                    border: `1px solid ${colors.bgColor2}`,
                    fontSize: 11,
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                }}
                onDelete={() => {
                    deleteCategory(id);
                }}
            />
        </>
    );
};

export default SubCategoryChipWidget;
