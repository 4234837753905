import React from 'react';
import CustomCard2 from '../../components/cards/CustomCard2';
import CategoriesSidebar from '../../components/sidebars/CategoriesSidebar';
import CategoriesTable from '../../components/tables/CategoriesTable';
import PageLayout from '../layouts/PageLayout';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs } from '@mui/material';
import SubCategoriesTable from '../../components/tables/SubCategoriesTable';
import { useSelector } from 'react-redux';
import CountBadge from '../../components/CountBadge';
import { isEmpty, size } from 'lodash';
import NoDataFeedback from '../../components/feedbacks/NoDataFeedback';
import { categoriesSelector } from '../../features/selectors';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        'id': `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Categories = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { categories, sub_categories } = useSelector(categoriesSelector);

    return (
        <PageLayout
            head="Categories managements"
            sidebar={<CategoriesSidebar />}
        >
            <CustomCard2
                title={'CAICA Categories'}
                height={640}
                action={
                    <CountBadge total={size(categories)} item={'Categories'} />
                }
            >
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label="Main categories" {...a11yProps(0)} />
                            <Tab label="Sub categories" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {isEmpty(categories) ? (
                            <NoDataFeedback message={'No category added.'} />
                        ) : (
                            <CategoriesTable categories={categories} />
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <SubCategoriesTable sub_categories={sub_categories} />
                    </TabPanel>
                </Box>
            </CustomCard2>
        </PageLayout>
    );
};

export default Categories;
