import React from 'react';
import { Box, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ErrorMessage, useField } from 'formik';
import { colors } from '../../assets/utils/colors';

const CustomInput = styled(TextField)({});

const CustomeFileForm = ({
    label,
    type,
    value,
    color,
    setFilePreview,
    formik,
    ...props
}) => {
    const [field, meta] = useField(props);
    const handleFileInputChange = (e) => {
        setFilePreview(URL.createObjectURL(e.target.files[0]));
        formik.setFieldValue('file', e.currentTarget.files[0]);
    };
    return (
        <>
            <Box sx={{ marginTop: 2 }}>
                <CustomInput
                    error={meta.touched && meta.error ? true : false}
                    label={label}
                    variant="filled"
                    fullWidth
                    type={type}
                    placeholder={label}
                    {...field}
                    {...props}
                    size="small"
                    color={color ? color : 'secondary'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleFileInputChange}
                    sx={{
                        '& input': {
                            color: color ? color : colors.white,
                            fontSize: 12,
                            pb: 1,
                        },
                        '& label': {
                            color: color ? color : colors.white,
                        },
                    }}
                />
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomeFileForm;
