import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import PromotionDetailsSidebar from '../../components/sidebars/PromotionDetailsSidebar';
import ProductWidget from '../../components/widgets/ProductWidget';
import PageLayout from '../layouts/PageLayout';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, size } from 'lodash';
import NoDataFeedback from '../../components/feedbacks/NoDataFeedback';

// RTK query imports and redux
import { useGetSinglePromotionQuery } from '../../api/promotions/promotionAPI';
import { setPromotion } from '../../features/promotionSlice';
import CountBadge from '../../components/CountBadge';
import { promotionsSelector } from '../../features/selectors';
import { getRTKResponseData } from '../../helpers/RTKHelpers';
import DataFetchingFeedback from '../../components/feedbacks/DataFetchingFeedback';

// !############### MAIN FUNC ####################
const PromotionDetails = () => {
    // ############### CONST ####################
    const dispatch = useDispatch();
    const { promotion_id } = useParams();

    // ############## Redux state ##############
    const { promotion } = useSelector(promotionsSelector);
    const { promotion_products } = promotion;

    // ############### RTK #####################
    const {
        isLoading,
        isSuccess,
        data: promotionData,
        refetch,
    } = useGetSinglePromotionQuery(promotion_id);

    // ############### useEffect #####################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(setPromotion(getRTKResponseData(promotionData)));
            }
        }
    }, [isSuccess, promotionData, dispatch]);

    return (
        <>
            <PageLayout
                sidebar={
                    !isLoading && (
                        <PromotionDetailsSidebar
                            promotion={promotion}
                            refetch={refetch}
                        />
                    )
                }
                head="Promotions details"
            >
                <CustomCard2
                    title={`Promotion products`}
                    heightClear={640}
                    action={
                        <CountBadge
                            total={size(promotion_products)}
                            item={'Products'}
                        />
                    }
                >
                    {isLoading ? (
                        <>
                            <DataFetchingFeedback />
                        </>
                    ) : (
                        <Grid container spacing={3} padding={2}>
                            {isEmpty(promotion_products) ? (
                                <NoDataFeedback message="No product found" />
                            ) : (
                                promotion_products.map((product, index) => (
                                    <Grid item sm={3} key={index}>
                                        <ProductWidget
                                            product={product.product_details}
                                        />
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    )}
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default PromotionDetails;
