import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../pages/auth/Login';
import BranchDetails from '../pages/branches/BranchDetails';
import Branches from '../pages/branches/Branches';
import BrandDetails from '../pages/brands/BrandDetails';
import Brands from '../pages/brands/Brands';
import Categories from '../pages/categories/Categories';
import Dashboard from '../pages/Dashboard';
import ErrorPage404 from '../pages/errors/ErrorPage404';
import AppLayout from '../pages/layouts/AppLayout';
import OrderDetails from '../pages/orders/OrderDetails';
import Orders from '../pages/orders/Orders';
import CreateProduct from '../pages/products/CreateProduct';
import ProductDetails from '../pages/products/ProductDetails';
import Products from '../pages/products/Products';
import UpdateProduct from '../pages/products/UpdateProduct';
import PromotionDetails from '../pages/promotions/PromotionDetails';
import Promotions from '../pages/promotions/Promotions';
import Admins from '../pages/users/Admins';
import CustomerDetails from '../pages/users/CustomerDetails';
import Customers from '../pages/users/Customers';
import ManagerDetails from '../pages/users/ManagerDetails';
import GuestRoutes from './GuestRoutes';
import PrivateRoutes from './PrivateRoutes';

// ?############## GUEST ELEMENTS ###########
const LoginElement = () => <Login />;

// ?############## PROTECTED ELEMENTS ###########
const DashboardElement = () => (
  <AppLayout>
    <Dashboard />
  </AppLayout>
);
const BranhesElement = () => (
  <AppLayout>
    <Branches />
  </AppLayout>
);
const BranchDetailsElement = () => (
  <AppLayout>
    <BranchDetails />
  </AppLayout>
);
const BrandsElement = () => (
  <AppLayout>
    <Brands />
  </AppLayout>
);
const BrandDetailsElement = () => (
  <AppLayout>
    <BrandDetails />
  </AppLayout>
);
const PromotionsElement = () => (
  <AppLayout>
    <Promotions />
  </AppLayout>
);
const PromotionDetailsElement = () => (
  <AppLayout>
    <PromotionDetails />
  </AppLayout>
);
const ProductsElement = () => (
  <AppLayout>
    <Products />
  </AppLayout>
);
const ProductDetailsElement = () => (
  <AppLayout>
    <ProductDetails />
  </AppLayout>
);
const CreateProductElement = () => (
  <AppLayout>
    <CreateProduct />
  </AppLayout>
);
const UpdateProductElement = () => (
  <AppLayout>
    <UpdateProduct />
  </AppLayout>
);
const OrdersElement = () => (
  <AppLayout>
    <Orders />
  </AppLayout>
);
const OrderDetailsElement = () => (
  <AppLayout>
    <OrderDetails />
  </AppLayout>
);
const CategoriesElement = () => (
  <AppLayout>
    <Categories />
  </AppLayout>
);
const CustomersElement = () => (
  <AppLayout>
    <Customers />
  </AppLayout>
);
const CustomerDetailsElement = () => (
  <AppLayout>
    <CustomerDetails />
  </AppLayout>
);
const AdminsElement = () => (
  <AppLayout>
    <Admins />
  </AppLayout>
);
const AdminDetailsElement = () => (
  <AppLayout>
    <ManagerDetails />
  </AppLayout>
);
const Error404Element = () => (
  <AppLayout>
    <ErrorPage404 />
  </AppLayout>
);

// !############## MAIN FUNC ###########
const App = () => {
  return (
    <React.Fragment>
      <Routes>
        {/* ################## GUEST ROUTES ################## */}
        <Route element={<GuestRoutes />}>
          <Route path="/" element={<LoginElement />} />
        </Route>

        {/* ################## PROTECTED ROUTES ################## */}
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<DashboardElement />} />
          <Route path="/branches" element={<BranhesElement />} />
          <Route
            path="/branches/details/:branch_id"
            element={<BranchDetailsElement />}
          />
          <Route path="/promotions" element={<PromotionsElement />} />
          <Route
            path="/promotions/details/:promotion_id"
            element={<PromotionDetailsElement />}
          />
          <Route path="/brands" element={<BrandsElement />} />
          <Route
            path="/brands/details/:brand_id"
            element={<BrandDetailsElement />}
          />
          <Route path="/products" element={<ProductsElement />} />
          <Route
            path="/products/details/:product_id"
            element={<ProductDetailsElement />}
          />
          <Route path="/products/create" element={<CreateProductElement />} />
          <Route
            path="/products/update/:product_id"
            element={<UpdateProductElement />}
          />
          <Route path="/orders" element={<OrdersElement />} />
          <Route
            path="/orders/details/:order_id"
            element={<OrderDetailsElement />}
          />
          <Route path="/categories" element={<CategoriesElement />} />
          <Route path="/customers" element={<CustomersElement />} />
          <Route
            path="/customers/details/:customer_id"
            element={<CustomerDetailsElement />}
          />
          <Route path="/mangers" element={<AdminsElement />} />
          <Route path="/mangers/details" element={<AdminDetailsElement />} />
        </Route>

        {/* ################## ERROR ROUTE ##################### */}
        <Route path="*" element={<Error404Element />} />
      </Routes>
    </React.Fragment>
  );
};

export default App;
