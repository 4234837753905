import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
    Avatar,
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import { Inventory } from '@mui/icons-material';
import { isEmpty, map, size } from 'lodash';
import { colors } from '../../assets/utils/colors';
import { currencyFomater } from '../../helpers/currencyFomater';
import { Link } from 'react-router-dom';

const columns = [
    {
        id: 's_n',
        label: 'S/N',
        align: 'center',
        fontWeight: 'bold',
        width: 10,
    },
    {
        id: 'product',
        label: 'Product',
    },
    {
        id: 'quantity',
        label: 'quantity',
        align: 'center',
    },
    {
        id: 'amount',
        label: (
            <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                Amount <sup style={{ color: 'green' }}>TZS</sup>
            </Typography>
        ),
    },
];

const createData = (s_n, product, quantity, amount) => {
    return { s_n, product, quantity, amount };
};

export default function OrderProductTable({ ordered_products }) {
    let ordered = null;

    if (!isEmpty(ordered_products)) {
        ordered = JSON.parse(ordered_products);
    }

    if (ordered !== null) {
        ordered = map(ordered, (product, index) =>
            createData(
                index + 1,
                <Box>
                    <Link to={`/products/details/${product.product_id}`}>
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 360,
                                bgcolor: 'background.paper',
                                p: 0,
                                bgcolor: 'transparent',
                            }}
                        >
                            <ListItem sx={{ p: 0 }}>
                                <ListItemAvatar sx={{ pr: 1 }}>
                                    <Avatar
                                        src={product.product_banner}
                                        variant="square"
                                        sx={{
                                            img: {
                                                objectFit: 'contain',
                                            },
                                            height: 80,
                                            width: 80,
                                            p: 1,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                        }}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                opacity: 0.8,
                                                color: colors.primary,
                                            }}
                                        >
                                            {product.product_name}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                mt: 1,
                                                color: colors.primary,
                                                fontSize: 13,
                                                opacity: 0.8,
                                            }}
                                        >
                                            <Inventory
                                                sx={{
                                                    color: colors.secondary,
                                                    fontSize: 16,
                                                }}
                                            />{' '}
                                            Kariako branch
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Link>
                </Box>,
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: colors.primary,
                        opacity: 0.8,
                        textAlign: 'center',
                    }}
                >
                    {product.cart_quantity}
                </Typography>,
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: colors.primary,
                        opacity: 0.8,
                    }}
                >
                    {currencyFomater(
                        product.product_price * product.cart_quantity,
                    )}
                </Typography>,
            ),
        );
    }

    if (!ordered) {
        return <h4>No products found</h4>;
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer
                sx={{
                    height: 390,
                    m: 0,
                    borderBottom: `15px solid ${colors.bgColor3}`,
                }}
            >
                <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{
                        '& th': {
                            bgcolor: `${colors.bgColor1} !important`,
                        },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    sx={{
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                        textAlign: column.align,
                                        width: column.width,
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ordered.map((row) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.s_n}
                                >
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                sx={{
                                                    fontWeight:
                                                        column.fontWeight,
                                                }}
                                            >
                                                {column.format &&
                                                typeof value === 'number'
                                                    ? column.format(value)
                                                    : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
