import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    brands: [],
    brandProducts: [],
    brand: {},
    refetchBrands: 0,
};

export const brandSlice = createSlice({
    name: 'brands',
    initialState,
    reducers: {
        setBrands: (state, action) => {
            state.brands = action.payload;
        },
        addBrand: (state, action) => {
            let brand = action.payload;
            state.brands = [brand, ...state.brands];
        },
        setBrand: (state, action) => {
            state.brand = action.payload;
        },
        setBrandProducts: (state, action) => {
            state.brandProducts = action.payload.brandProducts;
        },
        setRefetchBrands: (state, action) => {
            state.refetchBrands = state.refetchBrands + 1;
        },
    },
});

export const {
    setBrands,
    addBrand,
    setBrand,
    setBrandProducts,
    setRefetchBrands,
} = brandSlice.actions;

export default brandSlice.reducer;
