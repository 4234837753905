import { size } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetSingleCustomerQuery } from '../../api/users/userAPI';
import CustomCard2 from '../../components/cards/CustomCard2';
import CustomerDetailsSidebar from '../../components/sidebars/CustomerDetailsSidebar';
import CustomerOrdersTable from '../../components/tables/CustomerOrdersTable';
import { setCustomer } from '../../features/userSlice';
import PageLayout from '../layouts/PageLayout';
import NoDataFeedback from '../../components/feedbacks/NoDataFeedback';
import DataFetchingFeedback from '../../components/feedbacks/DataFetchingFeedback';
import { getRTKResponseData } from '../../helpers/RTKHelpers';
import CountBadge from '../../components/CountBadge';

const CustomerDetails = () => {
    // ################## CONST #####################
    const dispatch = useDispatch();
    const { customer_id } = useParams();

    // ############### Comp state ################
    const [customerOrders, setCustomerOrders] = useState([]);

    // ################## RTK ########################
    const {
        isLoading,
        isSuccess: customerSuccess,
        data: customerData,
    } = useGetSingleCustomerQuery(customer_id);

    // ############### useEffect #############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (customerSuccess) {
                setCustomerOrders(
                    getRTKResponseData(customerData).customer_orders,
                );
                dispatch(setCustomer(getRTKResponseData(customerData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [customerSuccess, customerData, dispatch]);

    return (
        <>
            <PageLayout
                sidebar={<CustomerDetailsSidebar />}
                head="Customer details"
            >
                <CustomCard2
                    title={`Customer orders`}
                    height={640}
                    action={
                        <CountBadge
                            total={size(customerOrders)}
                            item={'Orders'}
                        />
                    }
                >
                    {isLoading ? (
                        <DataFetchingFeedback />
                    ) : size(customerOrders) === 0 ? (
                        <NoDataFeedback message="No order found" />
                    ) : (
                        <CustomerOrdersTable orders={customerOrders} />
                    )}
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default CustomerDetails;
