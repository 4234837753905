import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../assets/utils/colors';
import logo from '../assets/media/images/logo.png';

const AppName = ({ textSize, desktopOpen }) => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    bgcolor: colors.white,
                    px: desktopOpen ? 2 : 1,
                    py: 0.5,
                    borderRadius: 2,
                    alignItems: 'center',
                }}
            >
                <Avatar src={logo} />
                {desktopOpen ? (
                    <>
                        <Typography
                            sx={{
                                fontSize: textSize,
                                mr: 1,
                                fontWeight: 'bold',
                                color: colors.secondary,
                            }}
                        >
                            Caica
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: textSize,
                                fontWeight: 'bold',
                                color: colors.primary,
                            }}
                        >
                            Pharmacy
                        </Typography>
                    </>
                ) : (
                    <></>
                )}
            </Box>
        </>
    );
};

export default AppName;
