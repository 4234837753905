import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { colors } from '../../assets/utils/colors';
import { Slide } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle
            sx={{
                m: 0,
                p: 1.5,
                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
            }}
            {...other}
        >
            <Typography
                sx={{ fontWeight: 'bold', color: colors.primary, fontSize: 14 }}
            >
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        bgcolor: colors.bgColor4,
                    }}
                    size="small"
                >
                    <CloseIcon color="primary" />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

// !################## MAIN FUNC ##################
const CustomDialogy = ({ open, handleCloseDialogy, title, noDropOut, children }) => {
    return (
        <div>
            <BootstrapDialog
                onClose={noDropOut?'':handleCloseDialogy}
                open={open}
                fullWidth
                TransitionComponent={Transition}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: 3,
                        border: `5px solid ${colors.bgColor1}`,
                    },
                }}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleCloseDialogy}
                >
                    {title}
                </BootstrapDialogTitle>
                <DialogContent dividers>{children}</DialogContent>
            </BootstrapDialog>
        </div>
    );
};
export default CustomDialogy;
