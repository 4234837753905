export const colors = {
    primary: '#0A365C',
    secondary: '#82D209',
    warning: '#E65100',
    danger: '#C62828',
    info: '#0288d1',
    success: '#2e7d32',
    white: '#FFFFFF',
    bgColor1: '#F3F5F7',
    bgColor2: '#0a365c33',
    bgColor3: '#0a365c4d',
    bgColor4: 'rgba(130, 210, 9, 0.1)',
    bgColor5: 'rgba(130, 210, 9, 0.2)',
    bgColor6: 'rgba(130, 210, 9, 0.3)',
};
