import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Add,
    Category,
    Delete,
    Edit,
    LabelImportant,
    Remove,
} from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    ImageList,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/colors';
import CustomCard2 from '../../components/cards/CustomCard2';
import ProductDetailInfobox from '../../components/infoboxes/ProductDetailInfobox';
import PageLayout from '../layouts/PageLayout';
import ProductDetailsSidebar from '../../components/sidebars/ProductDetailsSidebar';
import { Link } from 'react-router-dom';
import CustomDialogy from '../../components/dialogys/CustomDialogy';
import AddProductSize from './AddProductSize';
import AddProductImage from './AddProductImage';
import AddProductOnSell from './AddProductOnSell';
import AddProductOnPromotion from './AddProductOnPromotion';
import AddBranchStock from './AddBranchStock';
import ProductSizeWidget from '../../components/widgets/ProductSizeWidget';
import DataFetchingFeedback from '../../components/feedbacks/DataFetchingFeedback';
import { useDispatch, useSelector } from 'react-redux';
import { filter, isEmpty, map, size } from 'lodash';
import moment from 'moment';

// import RTK query and redux tools
import {
    useGetSingleProductQuery,
    useActivateProductMutation,
    useDeleteProductMutation,
} from '../../api/products/productAPI';
import { setProduct } from '../../features/productSlice';
import { numberWithCommas } from '../../utils/helpers';
import ConfirmationDialog from '../../components/dialogys/ConfirmationDialog';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';
import { errorNotify, successNotify } from '../../helpers/notify';
import ProductAdditionImg from '../../components/widgets/ProductAdditionImg';
import InnerDataFetchingError from '../../components/InnerDataFetchingError';
import { productsSelector, promotionsSelector } from '../../features/selectors';
import ProductPromotionWidget from '../../components/widgets/ProductPromotionWidget';

const ProductDetails = () => {
    // ############## CONST #################
    const dispatch = useDispatch();
    const { product_id } = useParams();

    // ################## Comp state#############
    const [openDialogy, setOpenDialogy] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContent, setDialogContent] = useState('');
    const [confirm, setConfirm] = useState(false);
    const [productPromotion, setProductPromotion] = useState({});

    // ################# Redux state #################
    const { product } = useSelector(productsSelector);
    const { promotions } = useSelector(promotionsSelector);

    // ################# RTK #######################
    const {
        data: productData,
        isLoading,
        isSuccess,
        error: fetchProductError,
        refetch,
    } = useGetSingleProductQuery(product_id, {
        refetchOnMountOrArgChange: true,
    });
    const [
        activateProduct,
        {
            isLoading: activateLoading,
            isSuccess: activateSuccess,
            isError: activateIsError,
            error: activateError,
            data: activateData,
        },
    ] = useActivateProductMutation();
    const [
        deleteProduct,
        {
            isLoading: deleteLoading,
            isSuccess: deleteSuccess,
            error: deleteError,
            data: deleteData,
        },
    ] = useDeleteProductMutation();

    if (isSuccess) {
        dispatch(setProduct(getRTKResponseData(productData)));
    }

    // ############# FUNC #######################
    const handleOpenDialogy = (title, content) => {
        setDialogTitle(title);
        setDialogContent(content);
        setOpenDialogy(true);
    };
    const handleCloseDialogy = () => {
        setOpenDialogy(false);
    };
    const handleConfirm = () => {
        setConfirm(true);
    };
    const handleCancelConfirm = () => {
        setConfirm(false);
    };
    // => Get pro promotion
    const getProductPromotion = () => {
        let proPromotion = {};
        if (!isEmpty(product)) {
            proPromotion = filter(
                promotions,
                ({ id }) => id === product.product_details.promotion_id,
            );
            proPromotion = proPromotion[0];
        }
        setProductPromotion(proPromotion);
    };

    // ################# useEffect ####################
    // => Activate product
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (activateSuccess) {
                refetch();
                successNotify(getRTKSuccessMessage(activateData));
            }
            if (activateIsError) {
                errorNotify(getRTKErrorMessage(activateError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [
        activateSuccess,
        activateIsError,
        activateError,
        activateData,
        refetch,
    ]);
    // => Set promotion
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getProductPromotion();
        }
        return () => {
            isSubscribed = false;
        };
    }, [product]);

    return (
        <>
            {/* ############# DIALOG ############### */}
            <CustomDialogy
                title={dialogTitle}
                open={openDialogy}
                handleCloseDialogy={handleCloseDialogy}
            >
                {dialogContent === 'addSize' ? (
                    <AddProductSize
                        product_id={product_id}
                        refetch={refetch}
                        handleCloseDialogy={handleCloseDialogy}
                    />
                ) : dialogContent === 'addImage' ? (
                    <AddProductImage
                        product_id={product_id}
                        refetch={refetch}
                        handleCloseDialogy={handleCloseDialogy}
                    />
                ) : dialogContent === 'addOnSell' ? (
                    <AddProductOnSell
                        product_id={product_id}
                        refetch={refetch}
                        handleCloseDialogy={handleCloseDialogy}
                    />
                ) : dialogContent === 'addOnPromotion' ? (
                    <AddProductOnPromotion
                        refetch={refetch}
                        product_id={product_id}
                        handleCloseDialogy={handleCloseDialogy}
                        getProductPromotion={getProductPromotion}
                    />
                ) : (
                    <AddBranchStock />
                )}
            </CustomDialogy>

            {/* ############# CONFIRM DIALOG ######### */}
            <ConfirmationDialog
                open={confirm}
                cancel={handleCancelConfirm}
                title={'Confirm product delete'}
                loading={deleteLoading}
                success={deleteSuccess}
                error={deleteError}
                data={deleteData}
                navigateURL={'/products'}
                action={() => {
                    deleteProduct(product_id);
                }}
                message={`Please confim deletion of ${product?.product_details?.product_name} product.`}
            />

            <PageLayout
                sidebar={
                    <ProductDetailsSidebar
                        product={product}
                        refetch={refetch}
                    />
                }
                head="Product details"
            >
                {isLoading ? (
                    <CustomCard2 heightClear={665}>
                        <DataFetchingFeedback />
                    </CustomCard2>
                ) : fetchProductError ? (
                    <>
                        <InnerDataFetchingError
                            height={500}
                            handleDataRefetch={refetch}
                        />
                    </>
                ) : (
                    <>
                        <CustomCard2
                            title={product?.product_details?.product_name}
                            heightClear={635}
                            action={
                                <Box
                                    sx={{
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Chip
                                        label={
                                            product?.product_details?.isActive
                                                ? 'Active'
                                                : 'In-active'
                                        }
                                        size="small"
                                        color={
                                            product?.product_details?.isActive
                                                ? 'success'
                                                : 'warning'
                                        }
                                    />
                                    <IconButton
                                        onClick={() => {
                                            activateProduct(product_id);
                                        }}
                                    >
                                        {activateLoading ? (
                                            <CircularProgress
                                                size={20}
                                                sx={{ mx: 1.4, my: 0.25 }}
                                            />
                                        ) : (
                                            <Switch
                                                checked={
                                                    product?.product_details
                                                        ?.isActive
                                                        ? true
                                                        : false
                                                }
                                                size="small"
                                            />
                                        )}
                                    </IconButton>
                                </Box>
                            }
                        >
                            <Grid container spacing={2}>
                                <Grid item sm={7}>
                                    <Box
                                        sx={{
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                            px: 2,
                                            pb: 1,
                                            height: '100%',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                'py': 1.5,
                                                'display': 'flex',
                                                'justifyContent':
                                                    'space-between',
                                                'position': 'relative',
                                                '&::before': {
                                                    content: "''",
                                                    height: 2,
                                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                                                    width: '100%',
                                                    position: 'absolute',
                                                    bottom: 0,
                                                },
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: colors.primary,
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {
                                                    product?.product_details
                                                        ?.product_name
                                                }
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: colors.primary,
                                                }}
                                            >
                                                #
                                                {
                                                    product?.product_details
                                                        ?.product_sku
                                                }
                                            </Typography>
                                        </Box>
                                        <Grid container sx={{ mt: 2 }}>
                                            <Grid item sm={4}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <LabelImportant
                                                        sx={{
                                                            fontSize: 35,
                                                            color:
                                                                colors.primary,
                                                            mr: 1,
                                                        }}
                                                    />
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                color:
                                                                    colors.primary,
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            Product brand
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 12,
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                color:
                                                                    colors.primary,
                                                            }}
                                                        >
                                                            {product
                                                                ?.product_details
                                                                ?.brand_name
                                                                ? product
                                                                      ?.product_details
                                                                      ?.brand_name
                                                                : 'unavailable'}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={4}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Category
                                                        sx={{
                                                            fontSize: 35,
                                                            color:
                                                                colors.primary,
                                                            mr: 1,
                                                        }}
                                                    />
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                color:
                                                                    colors.primary,
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            Product category
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 11,
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                textTransform:
                                                                    'capitalize',
                                                                color:
                                                                    colors.primary,
                                                            }}
                                                        >
                                                            {product
                                                                ?.product_details
                                                                ?.category_name
                                                                ? product
                                                                      ?.product_details
                                                                      ?.category_name
                                                                : 'unavailable'}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={4}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Category
                                                        sx={{
                                                            fontSize: 35,
                                                            color:
                                                                colors.primary,
                                                            mr: 1,
                                                        }}
                                                    />
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                color:
                                                                    colors.primary,
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            Product sub-category
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 11,
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                color:
                                                                    colors.primary,
                                                            }}
                                                        >
                                                            {product
                                                                ?.product_details
                                                                ?.sub_category_name
                                                                ? product
                                                                      ?.product_details
                                                                      ?.sub_category_name
                                                                : 'unavailable'}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{ mt: 1 }}
                                        >
                                            <Grid item sm={8}>
                                                <Grid container>
                                                    <Grid item sm={6}>
                                                        <ProductDetailInfobox
                                                            detail={
                                                                'Product weight'
                                                            }
                                                            value={
                                                                product
                                                                    ?.product_details
                                                                    ?.product_weight
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item sm={6}>
                                                        <ProductDetailInfobox
                                                            color="red"
                                                            detail={
                                                                'Expire date'
                                                            }
                                                            value={moment(
                                                                product
                                                                    ?.product_details
                                                                    ?.expire_date,
                                                            ).format('ll')}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        sm={10}
                                                        sx={{ mt: 2 }}
                                                    >
                                                        <ProductDetailInfobox
                                                            detail={
                                                                'Product size'
                                                            }
                                                            value={
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        overflow:
                                                                            'auto',
                                                                        width: 350,
                                                                    }}
                                                                >
                                                                    {!isEmpty(
                                                                        product.product_sizes,
                                                                    ) &&
                                                                        product?.product_sizes.map(
                                                                            (
                                                                                productSize,
                                                                                index,
                                                                            ) => (
                                                                                <ProductSizeWidget
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    refetch={
                                                                                        refetch
                                                                                    }
                                                                                    product_price={
                                                                                        product
                                                                                            ?.product_details
                                                                                            ?.product_price
                                                                                    }
                                                                                    productSize={
                                                                                        productSize
                                                                                    }
                                                                                />
                                                                            ),
                                                                        )}
                                                                    <Tooltip
                                                                        title="Add size"
                                                                        sx={{
                                                                            position:
                                                                                'absolute',
                                                                            bottom: -40,
                                                                            right: 60,
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            size="small"
                                                                            variant="contained"
                                                                            className="app-btn"
                                                                            onClick={() => {
                                                                                let title =
                                                                                    'Add product size';
                                                                                let content =
                                                                                    'addSize';
                                                                                handleOpenDialogy(
                                                                                    title,
                                                                                    content,
                                                                                );
                                                                            }}
                                                                            startIcon={
                                                                                <Add />
                                                                            }
                                                                        >
                                                                            Add
                                                                            size
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Box>
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={4}>
                                                <Box
                                                    sx={{
                                                        textAlign: 'center',
                                                        p: 2,
                                                        borderRadius: 2,
                                                        border: `1px solid ${colors.bgColor3}`,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 25,
                                                            color:
                                                                colors.primary,
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {numberWithCommas(
                                                            !isEmpty(product) &&
                                                                product
                                                                    ?.product_details
                                                                    ?.product_price,
                                                        )}
                                                        <sup
                                                            style={{
                                                                fontSize: 14,
                                                                color:
                                                                    colors.primary,
                                                            }}
                                                        >
                                                            TZS
                                                        </sup>
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            textAlign: 'center',
                                                            color:
                                                                colors.primary,
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Product price
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ mt: 2 }}>
                                            <Grid item sm={7} sx={{ mt: 4 }}>
                                                <Box
                                                    sx={{
                                                        'py': 1.5,
                                                        'position': 'relative',
                                                        '& ::before': {
                                                            content: "''",
                                                            height: 2,
                                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                                                            width: '100%',
                                                            position:
                                                                'absolute',
                                                            bottom: 5,
                                                        },
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 13,
                                                            fontWeight: 'bold',
                                                            color:
                                                                colors.primary,
                                                        }}
                                                    >
                                                        Product description
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        sx={{
                                                            fontSize: 13,
                                                            p: 1,
                                                        }}
                                                    >
                                                        {
                                                            product
                                                                ?.product_details
                                                                ?.product_description
                                                        }
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={5} sx={{ mt: 4 }}>
                                                <Box
                                                    sx={{
                                                        'py': 1.5,
                                                        'position': 'relative',
                                                        '& ::before': {
                                                            content: "''",
                                                            height: 2,
                                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor1})`,
                                                            width: '100%',
                                                            position:
                                                                'absolute',
                                                            bottom: 5,
                                                        },
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 13,
                                                            fontWeight: 'bold',
                                                            color:
                                                                colors.primary,
                                                        }}
                                                    >
                                                        Product promotion
                                                    </Typography>
                                                </Box>
                                                <ProductPromotionWidget
                                                    promotion={productPromotion}
                                                    product={product}
                                                    handleOpenDialogy={
                                                        handleOpenDialogy
                                                    }
                                                    handleCloseDialogy={
                                                        handleCloseDialogy
                                                    }
                                                    refetch={refetch}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item sm={5}>
                                    <Box
                                        sx={{
                                            'py': 1.5,
                                            'position': 'relative',
                                            '& ::before': {
                                                content: "''",
                                                height: 5,
                                                background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor6})`,
                                                width: '100%',
                                                position: 'absolute',
                                                bottom: 0,
                                            },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                color: colors.primary,
                                                fontSize: 12,
                                            }}
                                        >
                                            Banner image
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                            height: 200,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderBottomLeftRadius: 25,
                                            borderBottomRightRadius: 25,
                                        }}
                                    >
                                        <Avatar
                                            src={
                                                product?.product_details
                                                    ?.product_banner
                                            }
                                            variant="square"
                                            sx={{ height: 150, width: 130 }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            'py': 1.5,
                                            'position': 'relative',
                                            '& ::before': {
                                                content: "''",
                                                height: 5,
                                                background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor6})`,
                                                width: '100%',
                                                position: 'absolute',
                                                bottom: 0,
                                            },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                color: colors.primary,
                                                fontSize: 12,
                                            }}
                                        >
                                            Additional images
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            pb: 2,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            borderBottomLeftRadius: 25,
                                            borderBottomRightRadius: 25,
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                        }}
                                    >
                                        <ImageList
                                            sx={{ px: 2 }}
                                            cols={3}
                                            rowHeight={164}
                                        >
                                            {map(
                                                filter(
                                                    product?.product_images,
                                                    ({ image_id }) =>
                                                        image_id !== 200,
                                                ),
                                                (image, index) => (
                                                    <ProductAdditionImg
                                                        key={index}
                                                        image={image}
                                                        refetch={refetch}
                                                    />
                                                ),
                                            )}
                                        </ImageList>
                                        {size(
                                            filter(
                                                product?.product_images,
                                                ({ image_id }) =>
                                                    image_id !== 200,
                                            ),
                                        ) === 3 ? null : (
                                            <Button
                                                startIcon={<Add />}
                                                className="app-btn"
                                                variant="contained"
                                                sx={{ fontSize: 10 }}
                                                onClick={() => {
                                                    let title =
                                                        'Add product image';
                                                    let content = 'addImage';
                                                    handleOpenDialogy(
                                                        title,
                                                        content,
                                                    );
                                                }}
                                            >
                                                Add image
                                            </Button>
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            mt: 2,
                                            px: 1,
                                        }}
                                    >
                                        {product?.product_details?.isOnSale ? (
                                            <Button
                                                startIcon={<Remove />}
                                                variant="contained"
                                                color="error"
                                                size="small"
                                                className="app-btn"
                                                sx={{ fontSize: 12 }}
                                                onClick={() => {
                                                    let title =
                                                        'Add product on-sell';
                                                    let content = 'addOnSell';
                                                    handleOpenDialogy(
                                                        title,
                                                        content,
                                                    );
                                                }}
                                            >
                                                On-sell
                                            </Button>
                                        ) : (
                                            <Button
                                                startIcon={<Add />}
                                                variant="contained"
                                                color="warning"
                                                size="small"
                                                className="app-btn"
                                                sx={{ fontSize: 12 }}
                                                onClick={() => {
                                                    let title =
                                                        'Add product on-sell';
                                                    let content = 'addOnSell';
                                                    handleOpenDialogy(
                                                        title,
                                                        content,
                                                    );
                                                }}
                                            >
                                                On-sell
                                            </Button>
                                        )}
                                        {product?.product_details?.isActive ? (
                                            <Tooltip title="You can not edit active product. Deactivate first">
                                                <Button
                                                    startIcon={<Edit />}
                                                    variant="contained"
                                                    color="info"
                                                    size="small"
                                                    className="app-btn"
                                                    sx={{ fontSize: 12 }}
                                                >
                                                    Edit
                                                </Button>
                                            </Tooltip>
                                        ) : (
                                            <Link
                                                to={`/products/update/${product_id}`}
                                            >
                                                <Tooltip title="You can not edit active product. Deactivate first">
                                                    <Button
                                                        startIcon={<Edit />}
                                                        variant="contained"
                                                        color="info"
                                                        size="small"
                                                        className="app-btn"
                                                        sx={{ fontSize: 12 }}
                                                    >
                                                        Edit
                                                    </Button>
                                                </Tooltip>
                                            </Link>
                                        )}

                                        <Button
                                            startIcon={<Delete />}
                                            variant="contained"
                                            color="error"
                                            size="small"
                                            className="app-btn"
                                            sx={{ fontSize: 12 }}
                                            onClick={handleConfirm}
                                        >
                                            Delete
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CustomCard2>
                    </>
                )}
            </PageLayout>
        </>
    );
};

export default ProductDetails;
