import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { IconButton, styled, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { colors } from '../assets/utils/colors';

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const NotificationsBar = ({ openNotBar, closeNotDrawer }) => {
    return (
        <React.Fragment key={1}>
            <Drawer
                anchor={'right'}
                open={openNotBar}
                sx={{ position: 'relative' }}
            >
                <DrawerHeader />
                <Box
                    sx={{
                        width: 400,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                        height: '100vh',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`
                        }}
                    >
                        <IconButton
                            onClick={() => closeNotDrawer()}
                            color="error"
                            sx={{ position: 'absolute', left: 10, boxShadow: `1px 1px 10px 4px ${colors.bgColor2}` }}
                        >
                            <Close />
                        </IconButton>
                        <Typography sx={{ fontWeight: 'bold', color: colors.primary }}>
                            Latest Notifications
                        </Typography>
                    </Box>
                </Box>
            </Drawer>
        </React.Fragment>
    );
};
export default NotificationsBar;
