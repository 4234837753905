import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { colors } from '../../assets/utils/colors';
import { Box, Chip, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import CustomDialogy from '../dialogys/CustomDialogy';
import CreateSubcategory from '../../pages/categories/CreateSubcategory';
import { map } from 'lodash';
import MainCategoriesActions from '../../pages/categories/MainCategoriesActions';
import { useDispatch } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import SubCategoryChipWidget from '../widgets/SubCategoryChipWidget';

const columns = [
    {
        id: 's_n',
        label: 'S/N',
        align: 'center',
        fontWeight: 'bold',
        width: 10,
    },
    {
        id: 'name',
        label: 'Category name',
    },
    {
        id: 'sub_category',
        label: 'Sub categories',
    },
    // {
    //     id: 'description',
    //     label: 'Description',
    // },
    {
        id: 'status',
        label: 'Status',
    },
    {
        id: 'action',
        label: 'Actions',
        align: 'center',
    },
];

function createData(s_n, name, sub_category, status, action) {
    return { s_n, name, sub_category, action, status };
}

export default function CategoriesTable({ categories }) {
    // ############### CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [category_id, setCategory_id] = React.useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [openDialogy, setOpenDialogy] = React.useState(false);
    const handleOpenDialogy = () => {
        setOpenDialogy(true);
    };
    const handleCloseDialogy = () => {
        setOpenDialogy(false);
    };

    const rows = map(categories, (category, index) =>
        createData(
            index + 1,
            category.category_name,
            <Box>
                {map(category.sub_categories, ({ sub_category_name, id }) => (
                    <SubCategoryChipWidget
                        lable={sub_category_name}
                        id={id}
                        key={id}
                    />
                ))}
                <IconButton
                    size="small"
                    color="info"
                    onClick={() => {
                        setCategory_id(category.id);
                        handleOpenDialogy();
                    }}
                >
                    <Add />
                </IconButton>
            </Box>,
            // category.description,
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {category.status ? (
                    <Chip
                        label="active"
                        size="small"
                        color="success"
                        sx={{ fontSize: 10 }}
                    />
                ) : (
                    <Chip
                        label="inactive"
                        size="small"
                        color="error"
                        sx={{ fontSize: 10 }}
                    />
                )}
            </Box>,
            <MainCategoriesActions category={category} />,
        ),
    );

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {/* ############### Dialogy ################# */}
            <CustomDialogy
                title={'Add sub category'}
                open={openDialogy}
                handleCloseDialogy={handleCloseDialogy}
            >
                <CreateSubcategory
                    categoryID={category_id}
                    closeDialogy={handleCloseDialogy}
                />
            </CustomDialogy>

            <TableContainer sx={{ height: 485, m: 0 }}>
                <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{
                        '& th': {
                            bgcolor: `${colors.bgColor1} !important`,
                        },
                    }}
                >
                    <TableHead>
                        <TableRow
                            sx={{
                                '& th': {
                                    fontWeight: 'bold !important',
                                    whiteSpace: 'nowrap',
                                },
                            }}
                        >
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    sx={{
                                        color: colors.primary,
                                        textAlign: column.align,
                                        fontWeight: column.fontWeight,
                                        width: column.width,
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage,
                            )
                            .map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.code}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    sx={{
                                                        fontWeight:
                                                            column.fontWeight,
                                                    }}
                                                >
                                                    {column.format &&
                                                    typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                sx={{
                    '&.MuiTablePagination-root': {
                        bgcolor: `${colors.bgColor2} !important`,
                    },
                }}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
