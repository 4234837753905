import React from 'react';
import * as Yup from 'yup';
import { Box, Grid, MenuItem } from '@mui/material';
import { Form, Formik } from 'formik';
import CustomSelectForm from '../../components/forms/CustomSelectForm';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import { promotionsSelector } from '../../features/selectors';
import CustomSubmitButton from '../../components/forms/CustomSubmitButton';
import { useAddProductPromotionMutation } from '../../api/products/productAPI';
import { errorNotify, successNotify } from '../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';
import { useEffect } from 'react';

// ################### validation schema ###############
const addProductOnPromotionSchema = Yup.object({
    promotion_id: Yup.string('Promotion must be a string type').required(),
});

const AddProductOnPromotion = ({
    product_id,
    refetch,
    handleCloseDialogy,
    getProductPromotion,
}) => {
    // ############## Redux state ##################
    const { promotions } = useSelector(promotionsSelector);

    // ############# RTK ######################
    const [
        addProducPromotion,
        {
            isLoading: addLoading,
            isSuccess: addSuccess,
            isError: addIsError,
            error: addError,
            data: addPromotionData,
        },
    ] = useAddProductPromotionMutation();

    // ################ useEffect ################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (addSuccess) {
                refetch();
                handleCloseDialogy();
                getProductPromotion();
                successNotify(getRTKSuccessMessage(addPromotionData));
            }
            if (addIsError) {
                errorNotify(getRTKErrorMessage(addError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [addSuccess, addIsError, addError, addPromotionData]);

    return (
        <>
            <Formik
                initialValues={{
                    promotion_id: '',
                }}
                validationSchema={addProductOnPromotionSchema}
                onSubmit={async (values) => {
                    const payload = {
                        ...values,
                        product_id,
                    };
                    addProducPromotion(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item sm={12}>
                                <CustomSelectForm
                                    name="promotion_id"
                                    formikName="promotion_id"
                                    label="Select promotion"
                                    formik={formik}
                                >
                                    {promotions?.map((promotion, index) => (
                                        <MenuItem
                                            value={promotion.id}
                                            key={promotion.id}
                                        >
                                            {promotion.promotion_name}
                                        </MenuItem>
                                    ))}
                                </CustomSelectForm>
                            </Grid>
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={addLoading}
                                        onClick={formik.handleSubmit}
                                    >
                                        Add product to promotion
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddProductOnPromotion;
