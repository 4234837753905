// => Auth selector
export const authSelector = (state) => state.auth;

// => Products selector
export const productsSelector = (state) => state.products;

// => Orders selector
export const ordersSelector = (state) => state.orders;

// => Branches selector
export const branchesSelector = (state) => state.branches;

// => Brands selector
export const brandsSelector = (state) => state.brands;

// => Categories selector
export const categoriesSelector = (state) => state.categories;

// => Promotion selector
export const promotionsSelector = (state) => state.promotions;

// => Users selector
export const usersSelector = (state) => state.users;
