import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetBranchProductsQuery } from '../../api/branches/branchAPI';
import { getRTKResponseData } from '../../helpers/RTKHelpers';
import NoDataFeedback from '../feedbacks/NoDataFeedback';
import BrandWidget from '../widgets/BrandWidget';
import { Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import { brandsSelector } from '../../features/selectors';
import { setBrands } from '../../features/brandSlice';
import { useGetBrandsQuery } from '../../api/dashboard/dashboardAPI';
import DataFetchingFeedback from '../feedbacks/DataFetchingFeedback';

const DashBrands = () => {
    // ################# CONST ##################
    const dispatch = useDispatch();

    // ############## Redux ###################
    const { brands } = useSelector(brandsSelector);

    // ############## RTK ####################
    const {
        isLoading: brandsLoading,
        isSuccess: brandSuccess,
        data: brandsData,
    } = useGetBrandsQuery();

    // ############## useEffect ###################
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (brandSuccess) {
                dispatch(setBrands(getRTKResponseData(brandsData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [brandSuccess, brandsData]);
    return (
        <>
            {brandsLoading ? (
                <>
                    <DataFetchingFeedback />
                </>
            ) : isEmpty(brands) ? (
                <>
                    <NoDataFeedback message={'No brand added'} />
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        {brands?.map((brand) => (
                            <Grid item sm={4} key={brand.id}>
                                <BrandWidget brand={brand} />
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
        </>
    );
};

export default DashBrands;
