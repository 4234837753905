import React from 'react';
import { CircularProgress, IconButton, Switch, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import { setPromotion } from '../../features/promotionSlice';
import {
    useActivatePromotionMutation,
    useDeactivatePromotionMutation,
} from '../../api/promotions/promotionAPI';

const PromotionActivateDeactivate = ({ promotion, refetch }) => {
    // ############### CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK ###############
    const [
        activatePromotion,
        {
            isLoading: activateLoading,
            isSuccess: activateSuccess,
            isError: activateIsError,
            error: activateError,
            data: activateData,
        },
    ] = useActivatePromotionMutation();
    const [
        deactivatePromotion,
        {
            isLoading: deactivateLoading,
            isSuccess: deactivateSuccess,
            isError: deactivateIsError,
            error: deactivateError,
            data: deactivateData,
        },
    ] = useDeactivatePromotionMutation();
    // ############## Resp ###############
    const rtkActions = (data) => {
        refetch();
        dispatch(setPromotion(data));
    };
    RTK.useRTKResponse(
        activateSuccess,
        activateIsError,
        activateError,
        activateData,
        rtkActions,
    );
    RTK.useRTKResponse(
        deactivateSuccess,
        deactivateIsError,
        deactivateError,
        deactivateData,
        rtkActions,
    );
    return (
        <>
            <Tooltip
                title={promotion.promotion_status ? 'Deactivate' : 'Activate'}
            >
                <IconButton
                    size="small"
                    onClick={() => {
                        promotion.status === 1
                            ? deactivatePromotion(promotion.id)
                            : activatePromotion(promotion.id);
                    }}
                >
                    {activateLoading || deactivateLoading ? (
                        <CircularProgress size={21} sx={{ mx: 1 }} />
                    ) : (
                        <Switch
                            size="small"
                            checked={promotion.status === 1 ? true : false}
                        />
                    )}
                </IconButton>
            </Tooltip>
        </>
    );
};

export default PromotionActivateDeactivate;
