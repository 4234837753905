import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import BranchWidget from '../widgets/BranchWidget';
import { map, size } from 'lodash';
import DataFetchingFeedback from '../feedbacks/DataFetchingFeedback';

const steps = [
    {
        branch: <DataFetchingFeedback />,
    },
];

export default function BranchCarousel({ branches }) {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = size(branches);

    let mappedBranches = map(branches, (branch) => {
        return {
            branch: <BranchWidget branch={branch} />,
        };
    });

    if (!size(branches)) {
        mappedBranches = steps;
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ width: '100%', pb: 1 }}>
                {mappedBranches[activeStep].branch}
            </Box>
            {size(branches) ? (
                <MobileStepper
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ borderRadius: 2, p:0 }}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                            sx={{ fontSize: 10, fontWeight: 'bold' }}
                        >
                            Next
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                            sx={{ fontSize: 10, fontWeight: 'bold' }}
                        >
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            Back
                        </Button>
                    }
                />
            ) : null}
        </Box>
    );
}
