import { Add, RemoveRedEye } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/colors';
import { currencyFomater } from '../../helpers/currencyFomater';

const ProductWidget = ({ product }) => {
    return (
        <>
            <Box
                sx={{
                    'height': 220,
                    'border': `1px solid ${colors.bgColor2}`,
                    'display': 'flex',
                    'flexDirection': 'column',
                    'justifyContent': 'space-between',
                    'p': 1,
                    'borderRadius': 2,
                    'overflow': 'hidden',
                    'transition': '.3s all ease-in-out',
                    'position': 'relative',
                    '& .p-img': {
                        transition: '.3s all ease-in-out',
                    },
                    '& .more-btn': {
                        transition: '.3s all ease-in-out',
                    },
                    '& .price': {
                        transition: '.3s all ease-in-out',
                    },
                    '&:hover': {
                        'boxShadow': `1px 1px 10px 4px ${colors.bgColor1}`,
                        'transition': '.3s all ease-in-out',
                        '& .p-img': {
                            width: 110,
                            height: 120,
                            transition: '.3s all ease-in-out',
                        },
                        '& .more-btn': {
                            mt: 0,
                            transition: '.3s all ease-in-out',
                        },
                        '& .price': {
                            color: colors.secondary,
                            transition: '.3s all ease-in-out',
                        },
                    },
                }}
            >
                <Link to={`/products/details/${product.product_id}`}>
                    <Box
                        sx={{
                            bgcolor: colors.warning,
                            position: 'absolute',
                            right: 0,
                            zIndex: 400,
                            top: 0,
                            px: 1,
                            borderRadius: 1,
                        }}
                    >
                        {product.isOnSale ? (
                            <Typography
                                sx={{
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: colors.bgColor1,
                                }}
                            >
                                On-sell
                            </Typography>
                        ) : null}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Avatar
                            className="p-img"
                            variant="square"
                            src={product.product_banner}
                            sx={{
                                width: 130,
                                height: 140,
                                borderRadius: 4,
                                img: { objectFit: 'contain' },
                            }}
                        />
                    </Box>
                    <Box sx={{ p: 1, color: colors.primary }}>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: 12,
                                fontWeight: 'bold',
                            }}
                            className="price"
                        >
                            {currencyFomater(product.product_price)}{' '}
                            <sup style={{ fontSize: 10 }}>TZS</sup>
                            {product.discounted_price > 0 ? (
                                <strike style={{ fontSize: 10, color: 'red' }}>
                                    {currencyFomater(product.discounted_price)}
                                </strike>
                            ) : null}
                        </Typography>
                        <Typography
                            sx={{ textAlign: 'center', fontSize: 12 }}
                            noWrap
                        >
                            {product.product_name}
                        </Typography>
                    </Box>
                </Link>
                <Box
                    className="more-btn"
                    sx={{
                        height: 50,
                        mt: 5,
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Tooltip title={'Product details'}>
                        <Link to={`/products/details/${product.product_id}`}>
                            <IconButton
                                variant="contained"
                                size="small"
                                className="app-btn"
                                sx={{ fontSize: 9, bgcolor: colors.bgColor2 }}
                                color="info"
                            >
                                <RemoveRedEye sx={{ fontSize: 16 }} />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title={'Set product on-sell'}>
                        <Button
                            variant="contained"
                            size="small"
                            className="app-btn"
                            sx={{ fontSize: 9, height: 30 }}
                            startIcon={<Add sx={{ fontSize: 10 }} />}
                            color="warning"
                        >
                            On-sell
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
        </>
    );
};

export default ProductWidget;
