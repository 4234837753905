import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import BrandDetailsSidebar from '../../components/sidebars/BrandDetailsSidebar';
import ProductWidget from '../../components/widgets/ProductWidget';
import NoDataFeedback from '../../components/feedbacks/NoDataFeedback';
import PageLayout from '../layouts/PageLayout';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, size } from 'lodash';
import { useGetSingleBrandQuery } from '../../api/brands/brandAPI';
import { setBrand } from '../../features/brandSlice';
import { getRTKResponseData } from '../../helpers/RTKHelpers';
import { brandsSelector } from '../../features/selectors';
import CountBadge from '../../components/CountBadge';
import DataFetchingFeedback from '../../components/feedbacks/DataFetchingFeedback';

// !############### MAIN FUNC ####################
const BrandDetails = () => {
    // ################ CONST #################
    const dispatch = useDispatch();
    const { brand_id } = useParams();

    // ############## Redux state ###############
    const { brand } = useSelector(brandsSelector);
    const { brand_products } = brand;

    // ############### RTK ###################
    const {
        isLoading,
        isSuccess,
        data: brandData,
        refetch,
    } = useGetSingleBrandQuery(brand_id);

    // ############### useEffect ################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            refetch();
            if (isSuccess) {
                dispatch(setBrand(getRTKResponseData(brandData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, brandData, refetch, dispatch]);

    return (
        <>
            <PageLayout
                sidebar={
                    !isLoading && (
                        <BrandDetailsSidebar
                            brand={brand}
                            refetchBrandDetails={refetch}
                        />
                    )
                }
                head="Brands details"
            >
                <CustomCard2
                    title={`Brand products`}
                    heightClear={640}
                    action={
                        <CountBadge
                            total={size(brand_products)}
                            item={'Products'}
                        />
                    }
                >
                    {isLoading ? (
                        <>
                            <DataFetchingFeedback />
                        </>
                    ) : (
                        <Grid container spacing={3} padding={2}>
                            {isEmpty(brand_products) ? (
                                <NoDataFeedback message="No product found" />
                            ) : (
                                brand_products.map((product, index) => (
                                    <Grid item sm={3} key={index}>
                                        <ProductWidget
                                            product={product.product_details}
                                        />
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    )}
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default BrandDetails;
