import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomTextField from '../../components/forms/CustomTextField';
import { useDispatch } from 'react-redux';

// RTK query and redux actions imports
import { useAddBranchMutation } from '../../api/branches/branchAPI';
import { setBranchesRefetch } from '../../features/branchSlice';
import { errorNotify, successNotify } from '../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';
import CustomSubmitButton from '../../components/forms/CustomSubmitButton';

// ############ Validation ####################
const createBranchValidationSchema = Yup.object({
    branch_name: Yup.string().required('Branch name is required').max(20),
    branch_location: Yup.string()
        .required('Branch location is required')
        .max(30),
    open_hours: Yup.string().required('Branch location is required').max(30),
    close_hours: Yup.string().required('Branch location is required').max(30),
    email: Yup.string().required('Branch emailis required'),
    phone: Yup.string().required('Branch phone  is required'),
});

const CreateBranch = ({ handleCloseDialogy, branchesRefetch }) => {
    // ############ CONST ###############
    const dispatch = useDispatch();

    // ############# RTK ###################
    const [
        addBranch,
        { isLoading, isSuccess, isError, error, data: branchData },
    ] = useAddBranchMutation();

    // ############## useEffect ##############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                branchesRefetch();
                handleCloseDialogy();
                dispatch(setBranchesRefetch());
                successNotify(getRTKSuccessMessage(branchData));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }

        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, error, branchData, branchesRefetch, dispatch]);
    return (
        <>
            <Formik
                initialValues={{
                    branch_name: '',
                    branch_location: '',
                    open_hours: '',
                    close_hours: '',
                    email: '',
                    phone: '',
                }}
                validationSchema={createBranchValidationSchema}
                onSubmit={async (values, actions) => {
                    const payload = {
                        ...values,
                        contact_info: [
                            {
                                phone_number: values.phone,
                                email: values.email,
                            },
                        ],
                    };
                    await addBranch(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item sm={6}>
                                <CustomTextField
                                    name="branch_name"
                                    label={'Branch name'}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    name="branch_location"
                                    label={'Branch location'}
                                    color="primary"
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    name="phone"
                                    label={'Branch phone'}
                                    color="primary"
                                    type={'text'}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    name="email"
                                    label={'Branch email'}
                                    color="primary"
                                    type={'email'}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    name="open_hours"
                                    label={'Open hours'}
                                    color="primary"
                                    type={'time'}
                                    shrink
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    name="close_hours"
                                    label={'Close hours'}
                                    color="primary"
                                    type={'time'}
                                    shrink
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        onClick={formik.handleSubmit}
                                    >
                                        Create branch
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CreateBranch;
