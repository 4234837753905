import { Box, Grid } from '@mui/material';
import React from 'react';
import Pageheader from '../../components/Pageheader';

const PageLayout = ({ sidebar, children, head }) => {
    return (
        <>
            <Grid container spacing={2} padding={2}>
                <Grid item sm={12}>
                    <Pageheader head={head} />
                </Grid>
                <Grid item sm={12}>
                    <Grid container spacing={2}>
                        <Grid item sm={8.5}>
                            <Box>{children}</Box>
                        </Grid>
                        <Grid item sm={3.5}>
                            {sidebar}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PageLayout;
