import { Box, Button, CircularProgress } from '@mui/material';
import React from 'react';

const CustomSubmitButton = ({
    loading,
    startIcon,
    onClick,
    btnColor,
    children,
    loadingColor,
}) => {
    return (
        <>
            <Box>
                <Button
                    variant="contained"
                    onClick={onClick}
                    {...(loading ? '' : '')}
                    disabled={loading ? true : false}
                    startIcon={startIcon}
                    color={btnColor}
                    className="app-btn"
                    fullWidth
                    sx={{ position: 'relative' }}
                >
                    {children}
                    {loading && (
                        <Box
                            sx={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 10,
                            }}
                        >
                            <CircularProgress
                                size={20}
                                color={loadingColor ? loadingColor : 'primary'}
                            />
                        </Box>
                    )}
                </Button>
            </Box>
        </>
    );
};

export default CustomSubmitButton;
