import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    branches: [],
    branch_products: [],
    branch: {},
    branchAddedSuccess: false,
    branchAddedFail: false,
    productsFound: false,
    refetchBranches: 0,
};

export const branchSlice = createSlice({
    name: 'branches',
    initialState,
    reducers: {
        setBranches: (state, action) => {
            state.branches = action.payload;
        },
        setBranch: (state, action) => {
            state.branch = action.payload;
        },
        setBranchProducts: (state, action) => {
            state.branch_products = action.payload;
            state.productsFound = action.payload.productsFound;
        },
        setBranchAdded: (state, action) => {
            state.branchAddedSuccess = action.payload.branchAddedSuccess;
        },
        setBranchFail: (state, action) => {
            state.branchAddedFail = action.payload.branchAddedFail;
        },
        setProductsFound: (state, action) => {
            state.productsFound = action.payload.productsFound;
            state.branch_products = state.payload.branch_products;
        },
        setBranchesRefetch: (state, action) => {
            state.refetchBranches = state.refetchBranches + 1;
        },
    },
});

export const {
    setBranches,
    setBranch,
    setBranchAdded,
    setBranchFail,
    setBranchProducts,
    setProductsFound,
    setBranchesRefetch,
} = branchSlice.actions;

export default branchSlice.reducer;
