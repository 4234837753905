import React from 'react';
import CustomSubmitButton from '../../components/forms/CustomSubmitButton';
import useRTK from '../../hooks/useRTK';
import { useDispatch } from 'react-redux';
import { useDeliverOrderMutation } from '../../api/orders/orderAPI';
import { setOrder } from '../../features/orderSlice';

const DeliverOrder = ({ orderID, refetchOrder }) => {
    // ############### CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK ###############
    const [
        deliverOrder,
        { isLoading, isSuccess, isError, error, data: orderData },
    ] = useDeliverOrderMutation();

    // ############## Resp ###############
    const rtkActions = (data) => {
        refetchOrder();
        dispatch(setOrder(data));
    };
    RTK.useRTKResponse(
        isSuccess,
        isError,
        error,
        orderData,
        rtkActions,
    );

    return (
        <>
            <CustomSubmitButton
                loading={isLoading}
                onClick={() => {
                    deliverOrder(orderID);
                }}
            >
                Deliver order
            </CustomSubmitButton>
        </>
    );
};

export default DeliverOrder;
