import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { branchesSelector } from '../../features/selectors';
import { getRTKResponseData } from '../../helpers/RTKHelpers';
import { setBranches } from '../../features/branchSlice';
import NoDataFeedback from '../feedbacks/NoDataFeedback';
import BranchCarousel from '../carousel/BranchCarousel';
import DataFetchingFeedback from '../feedbacks/DataFetchingFeedback';
import { isEmpty } from 'lodash';
import { useGetBranchesQuery } from '../../api/dashboard/dashboardAPI';

const DashBranches = () => {
    // ################# CONST ##################
    const dispatch = useDispatch();
    const { branches } = useSelector(branchesSelector);

    // ################# RTK ####################
    const {
        isLoading: branchesLoading,
        isSuccess: branchSuccess,
        data: branchesData,
        refetch: branchesRefetch,
    } = useGetBranchesQuery();

    // ################ MAIN dispatch ####################
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            branchesRefetch();
            if (branchSuccess) {
                dispatch(setBranches(getRTKResponseData(branchesData)));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [branchSuccess, branchesData, branchesRefetch]);
    return (
        <>
            {branchesLoading ? (
                <>
                    <DataFetchingFeedback />
                </>
            ) : isEmpty(branches) ? (
                <>
                    <NoDataFeedback message={'No branch added'} />
                </>
            ) : (
                <>
                    <BranchCarousel branches={branches} />
                </>
            )}
        </>
    );
};

export default DashBranches;
