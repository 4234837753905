import React from 'react';
import { Grid } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import AdminsSidebar from '../../components/sidebars/AdminsSidebar';
import PageLayout from '../layouts/PageLayout';
import ManagerWidget from '../../components/widgets/ManagerWidget';
import { useSelector } from 'react-redux';
import { map, size } from 'lodash';
import CountBadge from '../../components/CountBadge';
import { usersSelector } from '../../features/selectors';

// !############### MAIN FUNC ####################
const Admins = () => {
    // ################# CONST #################
    const { managers } = useSelector(usersSelector);

    return (
        <>
            <PageLayout sidebar={<AdminsSidebar />} head="Managers managements">
                <CustomCard2
                    title={`CAICA Managers`}
                    heightClear={640}
                    action={<CountBadge total={size(managers)} item={'Admins'} />}
                >
                    <Grid container spacing={3} padding={2}>
                        {map(managers, (admin, index) => (
                            <Grid item sm={3} key={index}>
                                <ManagerWidget manager={admin} />
                            </Grid>
                        ))}
                    </Grid>
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default Admins;
